import React from 'react'
import { connect } from 'react-redux'
import DisplaysAddVirtualDeviceMethods from './displays__addVirtualDevice.local.methods'
import { SelectMapDialog } from 'features/map'
import Dropdown from 'blocks.simple/dropdown/dropdown'
import EditText from 'blocks.simple/editText/editText'
import { Radio } from 'atoms/Radio'
import Button from 'blocks.simple/button/button'
import { Scrollbars } from 'react-custom-scrollbars'
import translate from 'core/translate'
import helpersDisplays from 'core/helpers/_digitalSignages'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import { cn } from 'ethcss'
import styles from './displays__addVirtualDevice.jcss'
import { colors, grid, item } from 'theme'
import { text as textStyles } from 'theme'
import { Typography } from 'atoms/Typography'
import { emitError } from 'features/appNotifications/AppNotifications.state'
import { displaysActions } from '../displays.state'
import Icon from 'blocks.simple/icon/icon'
import { IState } from '../__add/displays__add.local.methods'
import { isVacantCountNull, noEmoji } from 'core/utils/displayUtil'
import Link from 'blocks.simple/link/link'
import { OpenStreetMap } from '../../../features/map/molecules/OpenStreetMap/OpenStreetMap-view'

class DisplaysAddVirtualDevice extends DisplaysAddVirtualDeviceMethods {
    renderMap() {
        const { active } = this.state

        const mapType = window?.spconfig?.map?.type

        return mapType !== 'yandex' && mapType !== 'google' ? (
            <OpenStreetMap
                onClose={this.onMapClose}
                isOpen={active}
                location={this.getLocation()}
                onChangeLocation={this.onChangeLocation}
                onChangePlacemarkGeometry={this.onChangePlacemarkGeometry}
            />
        ) : (
            <SelectMapDialog
                onClose={this.onMapClose}
                isOpen={active}
                location={this.getLocation()}
                center={this.getCenter()}
                onChangeLocation={this.onChangeLocation}
                onChangeAddress={this.onChangeAddress}
                onChangePlacemarkGeometry={this.onChangePlacemarkGeometry}
            />
        )

        // return (
        //     <SelectMapDialog
        //         onClose={this.onMapClose}
        //         isOpen={active}
        //         location={this.getLocation()}
        //         center={this.getCenter()}
        //         onChangeLocation={this.onChangeLocation}
        //         onChangeAddress={this.onChangeAddress}
        //         onChangePlacemarkGeometry={this.onChangePlacemarkGeometry}
        //     />
        // )
    }

    checkEmptyLicences = (licences: any) => {
        return licences.some((licence: any) => licence.vacantCount)
    }

    render() {
        const p_ = this.props
        const s_ = this.state
        const disabled = !helpersDisplays.isCheckLicenseQ()
            ? false
            : !helpersDisplays.checkActualLicense(s_.currentLicense?.id, s_.licensesData)

        return (
            <Scrollbars style={{ width: '100%', height: '100%' }}>
                <form
                    className={styles.wrapper}
                    //onSubmit={this.submit}
                >
                    {isVacantCountNull(s_.licensesData) ? (
                        <>
                            <Typography type="title" className={cn(styles.title, textStyles['mdPlus'])}>
                                {translate('virtualDeviceSettings')}
                            </Typography>
                            <div className={styles.content}>
                                <div className={styles.formWrapper}>
                                    <div className={styles.form}>
                                        <div className={styles.leftColumnContent}>
                                            <div className={cn(grid.mb_md, { [styles.disabled]: disabled })}>
                                                <EditText
                                                    validationBorder={!!s_.data.error}
                                                    label={
                                                        <Typography
                                                            type="title"
                                                            className={cn(
                                                                textStyles.up,
                                                                textStyles.centerBlock,
                                                                textStyles['normalPlus'],
                                                                grid.mb_micro
                                                            )}
                                                        >
                                                            {translate('name')}
                                                        </Typography>
                                                    }
                                                    mod="withBorder"
                                                    id="name"
                                                    type="text"
                                                    className={cn(textStyles.center, styles.noBoxShadow)}
                                                    value={s_.data.name}
                                                    onChange={(name: string) => {
                                                        this.setData({ name })
                                                        this.inputNullCheck(name, 'name')
                                                    }}
                                                    disabled={disabled}
                                                />
                                                <div
                                                    className={cn(
                                                        grid.mt_micro,
                                                        textStyles.centerBlock,
                                                        s_.data.error ? '' : item.hidden,
                                                        styles.errorInputText
                                                    )}
                                                >
                                                    {translate('fieldIsRequired')}
                                                </div>
                                            </div>
                                            <div
                                                className={cn(grid.mb_md, { [styles.disabled]: disabled })}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                }}
                                            >
                                                <EditText
                                                    label={
                                                        <Typography
                                                            type="title"
                                                            className={cn(
                                                                textStyles.up,
                                                                textStyles.centerBlock,
                                                                textStyles['normalPlus'],
                                                                grid.mb_micro
                                                            )}
                                                        >
                                                            {translate('address')}
                                                        </Typography>
                                                    }
                                                    mod="withBorder"
                                                    id="address"
                                                    type="text"
                                                    icon="location"
                                                    className={cn(textStyles.center, styles.noBoxShadow)}
                                                    onIconClick={() => this.setState({ active: true })}
                                                    value={this.getAddress()}
                                                    onChange={(address: string) => {
                                                        this.setData({ address })
                                                    }}
                                                    disabled={disabled}
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.descriptionWrapper}>
                                            <div className={cn(grid.mb_md, { [styles.disabled]: disabled })}>
                                                <EditText
                                                    label={
                                                        <Typography
                                                            type="title"
                                                            className={cn(
                                                                textStyles.up,
                                                                textStyles.centerBlock,
                                                                textStyles['normalPlus'],
                                                                grid.mb_micro
                                                            )}
                                                        >
                                                            {translate('description')}
                                                        </Typography>
                                                    }
                                                    mod="withBorder"
                                                    id="description"
                                                    className={cn(styles.noBoxShadow)}
                                                    type="area"
                                                    rows="6"
                                                    value={s_.data.description}
                                                    onChange={(description: string) => {
                                                        this.setData({ description })
                                                    }}
                                                    disabled={disabled}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.leftColumnContent}>
                                        <div className={cn(grid.mb_md, item.rel, { [styles.disabled]: disabled })}>
                                            {p_.groups && (
                                                <Dropdown
                                                    id="group"
                                                    label={
                                                        <Typography
                                                            type="title"
                                                            className={cn(
                                                                textStyles.up,
                                                                textStyles.centerBlock,
                                                                textStyles['normalPlus'],
                                                                grid.mb_micro
                                                            )}
                                                        >
                                                            {translate('group')}
                                                        </Typography>
                                                    }
                                                    value={s_.data.groupId}
                                                    options={p_.groups}
                                                    centered
                                                    searchable
                                                    type="tree"
                                                    onChange={(selected) => {
                                                        this.setData({ groupId: selected.id })
                                                    }}
                                                    disabled={disabled}
                                                />
                                            )}
                                        </div>
                                        <div className={cn(grid.mb_md, { [styles.disabled]: disabled })}>
                                            <Typography
                                                type="title"
                                                className={cn(
                                                    textStyles.up,
                                                    textStyles.centerBlock,
                                                    textStyles['normalPlus'],
                                                    grid.mb_mini
                                                )}
                                            >
                                                {translate('timezones')}
                                            </Typography>
                                            <Checkbox
                                                className={cn(grid.p_micro)}
                                                label={
                                                    <div className={cn(grid.pl_mini, grid.pr_mini, textStyles.normal)}>
                                                        <Typography type="title">
                                                            {translate('useGroupTimezone')}
                                                        </Typography>
                                                    </div>
                                                }
                                                checked={s_.groupTimezone}
                                                onClick={(checked: boolean) => {
                                                    this.setState({ groupTimezone: checked })
                                                }}
                                                disabled={disabled}
                                            />
                                        </div>
                                        <div className={cn(grid.mb_md, { [styles.disabled]: disabled })}>
                                            <Dropdown
                                                id={'timezones'}
                                                validationBorder={!!s_.data.nullTimezoneError}
                                                placeholder={translate('notSelected')}
                                                value={
                                                    s_.groupTimezone
                                                        ? s_.parentTimezone
                                                            ? s_.parentTimezone
                                                            : s_.data.timezone
                                                        : s_.data.timezone
                                                }
                                                options={p_.timezones ? p_.timezones : []}
                                                searchable
                                                centered
                                                onChange={(selected) => {
                                                    this.setData({ timezone: selected.id })
                                                    this.inputNullCheck(selected, 'timezone')
                                                }}
                                                labelClassName={cn(item.ellipsis)}
                                                disabled={s_.groupTimezone || disabled}
                                            />
                                            <div
                                                className={cn(
                                                    grid.mt_micro,
                                                    textStyles.centerBlock,
                                                    s_.data.nullTimezoneError ? '' : item.hidden,
                                                    styles.errorInputText
                                                )}
                                            >
                                                {translate('fieldIsRequired')}
                                            </div>
                                        </div>
                                        <div className={cn(grid.mb_md, { [styles.disabled]: disabled })}>
                                            <Dropdown
                                                searchable
                                                value={s_.data.orientation}
                                                label={
                                                    <Typography
                                                        type="title"
                                                        className={cn(
                                                            textStyles.up,
                                                            textStyles.centerBlock,
                                                            textStyles['normalPlus'],
                                                            grid.mb_micro
                                                        )}
                                                    >
                                                        {translate('orientation')}
                                                    </Typography>
                                                }
                                                centered
                                                options={helpersDisplays.getOrientations()}
                                                onChange={(value) => {
                                                    this.setData({ orientation: value.id })
                                                }}
                                                disabled={disabled}
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.rightColumnContent}>
                                        <div
                                            className={cn(styles.displaysAddVirtualDevice__inputWrapper_type_tags, {
                                                [styles.disabled]: disabled,
                                            })}
                                        >
                                            <Dropdown
                                                list={s_.data.tags}
                                                hiddenOverflow
                                                options={p_.tagsList ? p_.tagsList : []}
                                                label={
                                                    <Typography
                                                        type="title"
                                                        className={cn(
                                                            textStyles.up,
                                                            textStyles.centerBlock,
                                                            textStyles['normalPlus'],
                                                            grid.mb_micro
                                                        )}
                                                    >
                                                        {translate('tag')}
                                                    </Typography>
                                                }
                                                searchable={true}
                                                multiselect={true}
                                                onChange={(tags) => {
                                                    tags = tags.map(
                                                        (tag: { id: number; name: string; label: string }) => {
                                                            if (!tag.id && tag.name.match(/#/g)) {
                                                                tag.name = tag.label = tag.name.replace(/#/g, '')
                                                            }
                                                            tag = {
                                                                ...tag,
                                                                name: noEmoji(tag.name),
                                                                label: noEmoji(tag.label),
                                                            }
                                                            return tag
                                                        }
                                                    )
                                                    this.setData({ tags })
                                                }}
                                                disabled={disabled}
                                                onValidateSymbolNumber={(checked) =>
                                                    this.onValidateSymbolNumber(checked)
                                                }
                                            />
                                        </div>
                                        <div className={grid.mt_mini}>
                                            <Typography className={cn(textStyles.centerBlock)} type="text">
                                                {translate('forAddTagUseEnter')}
                                            </Typography>
                                            {s_.validateSymbolNumber && (
                                                <Typography
                                                    className={cn(styles.centerBlock, grid.mt_mini)}
                                                    type="error"
                                                >
                                                    {translate('minTagNumber')}
                                                </Typography>
                                            )}
                                        </div>

                                        {helpersDisplays.isCheckLicenseQ() && (
                                            <div className={cn(grid.mb_md, grid.mt_big, item.rel)}>
                                                <Dropdown
                                                    searchable
                                                    validationBorder={!!s_.data.licensesTypeError}
                                                    id="licensesDataVirtual"
                                                    label={
                                                        <Typography
                                                            type="title"
                                                            className={cn(
                                                                textStyles.up,
                                                                textStyles.centerBlock,
                                                                textStyles['normalPlus'],
                                                                grid.mb_mini
                                                            )}
                                                        >
                                                            {translate('licensesType')}
                                                        </Typography>
                                                    }
                                                    value={s_.currentLicense?.id}
                                                    options={s_.licensesData}
                                                    centered
                                                    onChange={(selected) => {
                                                        this.setState((prevState: IState) => ({
                                                            ...prevState,
                                                            currentLicense: selected,
                                                            data: {
                                                                ...prevState.data,
                                                                licenseId: selected.id,
                                                                licensesTypeError: false,
                                                            },
                                                        }))
                                                    }}
                                                />
                                                <div
                                                    className={cn(
                                                        grid.mt_micro,
                                                        textStyles.centerBlock,
                                                        s_.data.licensesTypeError ? '' : item.hidden,
                                                        styles.errorInputText
                                                    )}
                                                >
                                                    {translate('fieldIsRequired')}
                                                </div>
                                            </div>
                                        )}
                                        {s_.currentLicense?.vacantCount ? (
                                            <div className={styles.license__info}>
                                                <Typography type="text">
                                                    {translate('licensesBalance')} {s_.currentLicense.vacantCount}{' '}
                                                    {translate('licensesLeft')}
                                                </Typography>
                                            </div>
                                        ) : s_.currentLicense?.vacantCount === 0 &&
                                          this.checkEmptyLicences(s_.licensesData) ? (
                                            <div className={styles.noLicensesLeft}>
                                                <Icon type={'info'} color={colors.notificationRed} />
                                                <span>{translate('noLicencesInTariff')}</span>
                                            </div>
                                        ) : null}
                                        {!this.checkEmptyLicences(s_.licensesData) && (
                                            <div className={styles.noLicensesLeft}>
                                                <Icon type={'info'} color={colors.notificationRed} />
                                                <span>{translate('noLicencesInTariffs')}</span>
                                            </div>
                                        )}
                                        {p_.currentGroup && p_.currentGroup.id !== s_.data.groupId && (
                                            <div className={cn(grid.mt_mdPlus, { [styles.disabled]: disabled })}>
                                                <Typography
                                                    className={cn(
                                                        textStyles.centerBlock,
                                                        textStyles.up,
                                                        textStyles['normalPlust']
                                                    )}
                                                    type="title"
                                                >
                                                    {translate('scheduleForDevice')}
                                                </Typography>
                                                <Radio
                                                    className={cn(grid.mt_md)}
                                                    label={
                                                        <Typography type="title">
                                                            {translate('changeCurrentSchedule')}
                                                        </Typography>
                                                    }
                                                    selected={s_.data.groupScheduleMode === 'apply'}
                                                    onClick={() => {
                                                        this.setData({ groupScheduleMode: 'apply' })
                                                    }}
                                                    disabled={disabled}
                                                />
                                                <Radio
                                                    className={cn(grid.mt_mini)}
                                                    label={
                                                        <Typography type="title">
                                                            {translate('remainCurrentSchedule')}
                                                        </Typography>
                                                    }
                                                    selected={s_.data.groupScheduleMode === 'ignore'}
                                                    onClick={() => {
                                                        this.setData({ groupScheduleMode: 'ignore' })
                                                    }}
                                                    disabled={disabled}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>{' '}
                        </>
                    ) : (
                        <div className={styles.content}>
                            <div className={cn(styles.formWrapper, styles.w100)}>
                                <div className={cn(grid.center)}>
                                    <div className={cn(grid.mb_md, grid.rowCenter)}>
                                        <Icon type="sadSmile" size={75} />
                                    </div>
                                    <div className={cn(grid.mb_md)}>
                                        <Typography
                                            type="title"
                                            className={cn(
                                                textStyles.centerBlock,
                                                textStyles['normalPlus'],
                                                grid.mb_mini
                                            )}
                                        >
                                            {translate('cantAddlicensesLeft')}
                                        </Typography>
                                    </div>
                                    <div className={cn(grid.mb_md)}>
                                        <Typography
                                            type="title"
                                            className={cn(
                                                textStyles.centerBlock,
                                                textStyles['normalPlus'],
                                                grid.mb_mini
                                            )}
                                        >
                                            <Link to={'/licenses'}>{translate('addLicensesSection')}</Link>
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className={styles.footer}>
                        <Button className={grid.mr_mini} onClick={this.closeModal} mod="withBorder">
                            {translate('close')}
                        </Button>
                        <Button
                            mod="fill"
                            onClick={this.onSave}
                            disabled={
                                !helpersDisplays.isCheckLicenseQ()
                                    ? false
                                    : s_.data.error || s_.data.nullTimezoneError || s_.data.licensesTypeError
                                    ? true
                                    : false
                            }
                        >
                            {translate('save')}
                        </Button>
                    </div>
                    {this.renderMap()}
                </form>
            </Scrollbars>
        )
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        emitError: (err: string) => dispatch(emitError(err)),
        closeModal: () => dispatch(displaysActions.closeModal()),
    }
}

export default connect(
    (state: { displays: { tags: string[] }; app: { timezones: string[] }; user: { data: { companyId: string } } }) => ({
        tagsList: state.displays.tags,
        timezones: state.app.timezones,
        user: state.user.data.companyId,
    }),
    mapDispatchToProps
)(DisplaysAddVirtualDevice)

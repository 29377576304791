export const defaultFilesIconsTheme = {
    folder: `images/helpers/_files/folder.svg`,
    smartFolder: `images/helpers/_files/smartFolder.svg`,
    audio: `images/helpers/_files/audio.png`,
    table: `images/helpers/_files/table.png`,
    link: `images/helpers/_files/link.png`,
    webApp: `images/helpers/_files/web_app.png`,
    office: `images/helpers/_files/office.png`,
    corruptFile: `images/helpers/corrupt_file.png`,
    empty: `images/helpers/_files/empty.png`,
    transparent: `images/helpers/_files/transparent.png`,

    previewAudio: `images/helpers/audio.png`,
    previewEmpty: `images/helpers/empty.png`,
    previewOffice: `images/helpers/office.png`,

    noBroadcast: `images/displays/no-broadcast.png`,

    windows: `images/displays/platforms/windows.png`,
    windows_active: `images/displays/platforms/windows_active.png`,
    webos: `images/displays/platforms/webos.png`,
    webos_active: `images/displays/platforms/webos_active.png`,
    webos_old: `images/displays/platforms/webos_old.png`,
    webos_old_active: `images/displays/platforms/webos_old_active.png`,
    webos_new: `images/displays/platforms/webos_new.png`,
    webos_new_active: `images/displays/platforms/webos_new_active.png`,
    raspberry: `images/displays/platforms/raspberry.png`,
    raspberry_active: `images/displays/platforms/raspberry_active.png`,
    tizen: `images/displays/platforms/tizen.png`,
    tizen_active: `images/displays/platforms/tizen_active.png`,
    linux: `images/displays/platforms/linux.png`,
    linux_active: `images/displays/platforms/linux_active.png`,
    android: `images/displays/platforms/android.png`,
    android_active: `images/displays/platforms/android_active.png`,
    sssp: `images/displays/platforms/sssp.png`,
    sssp_active: `images/displays/platforms/sssp_active.png`,
    sssp_new: `images/displays/platforms/sssp_new.png`,
    sssp_new_active: `images/displays/platforms/sssp_new_active.png`,
    sssp_old: `images/displays/platforms/sssp_old.png`,
    sssp_old_active: `images/displays/platforms/sssp_old_active.png`,
    tizen_7: `images/displays/platforms/tizen_7.png`,
    tizen_7_active: `images/displays/platforms/tizen_7_active.png`,
    brightsign: `images/displays/platforms/brightsign.png`,
    brightsign_active: `images/displays/platforms/brightsign_active.png`,

    getPlatformIcon(platformWithStatusName: string) {
        const self: any = this
        return self[platformWithStatusName]
    },
}

import React from 'react'

import helpers from 'core/helpers'
import { editor } from '../editor.local.methods'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from 'blocks.simple/icon/icon.theme'

class EditorScreenMethods extends React.Component {
    constructor(p_) {
        super(p_)

        this.state = {
            openPreview: false,
            areas: this.getAreas(p_),
            maxWidth: {},
            maxHeight: {},
            lineWidth: 0,
            lineHeight: 0,
            addHLine: false,
            addVLine: false,
            mouseIn: false,
            hLines: [],
            vLines: [],
            scrollLeft: 0,
            scrollTop: 0,
            scale: 1,
            time: 0,
            playing: false,
        }
        this.screenWidth = this.getScreenWidth(p_)
        this.screenHeight = this.getScreenHeight(p_)
        this.seekTo = false
        this.dragging = false
        this.clientX = 0
        this.clientY = 0
    }
    viewIcons = {
        x: 'listSq',
        y: 'listCol',
        list: 'sortable_handle',
    }
    viewOptions = [
        {
            id: 'x',
            icon: 'listSq',
            iconColor: getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable,
            iconActiveColor: 'white',
        },
        {
            id: 'y',
            icon: 'listCol',
            iconColor: getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable,
            iconActiveColor: 'white',
        },
        {
            id: 'list',
            icon: 'sortable_handle',
            iconColor: getThemeStyleValue('ui', 'icon', 'disable') || defaultThemeStyles.disable,
            iconActiveColor: 'white',
        },
    ]

    // Получить относительную ширину холста
    getScreenWidth(p_) {
        return (p_.resolutionWidth / p_.screenSize.width) * 100
    }

    // Получить относительную высоту холста
    getScreenHeight(p_) {
        return (p_.resolutionHeight / p_.screenSize.height) * 100
    }

    // Добавить горизонтальную направляющую линию
    addHLine = (pos) => {
        const s_ = this.state

        s_.addHLine = false
        s_.hLines.push({ top: pos.top })
        setTimeout(() => {
            this.setState(s_)
        }, 0)

        return true
    }

    // Добавить вертикальную направляющую линию
    addVLine = (pos) => {
        const s_ = this.state

        s_.addVLine = false
        s_.vLines.push({ left: pos.left })
        setTimeout(() => {
            this.setState(s_)
        }, 0)

        return true
    }

    // Обновить позицию направляющией линии
    updateLinePos = (pos, type, index) => {
        const s_ = this.state

        if (type === 'h') {
            s_.hLines[index].top = pos.top
        }
        if (type === 'v') {
            s_.vLines[index].left = pos.left
        }

        this.setState(s_)
        return true
    }

    // Перевести время в таймлинии
    setTime = (time, playing, seekTo = false) => {
        this.seekTo = seekTo
        if (typeof playing !== 'undefined') {
            this.setState({ time, playing })
        } else {
            this.setState({ time })
        }
    }

    componentWillReceiveProps(p_) {
        this.screenWidth = this.getScreenWidth(p_)
        this.screenHeight = this.getScreenHeight(p_)
        this.setState({
            areas: this.getAreas(p_),
        })

        if (this.props.zoom !== p_.zoom && p_.zoom === 1) {
            this.setState({ scrollLeft: 0, scrollTop: 0 })
        }
    }

    componentDidUpdate(prevProps) {
        this.setScrollbarPositionOnZoom(prevProps)
    }

    setScrollbarPositionOnZoom = (prevProps) => {
        const p_ = this.props

        if (!this.scrollbar) {
            return
        }

        if (prevProps.zoom !== p_.zoom) {
            const position = this.scrollbar.getValues()
            const scrollLeft = (position.scrollWidth - position.clientWidth) / 2
            const scrollTop = (position.scrollHeight - position.clientHeight) / 2

            this.setState({ scrollLeft, scrollTop })

            this.scrollbar.scrollTop(scrollTop)
            this.scrollbar.scrollLeft(scrollLeft)
        }
    }

    // Формирует структуру списка зон. Также возвращает зону с индексом -1, необходимую для саундтрека всей трансляции
    getAreas(p_) {
        const areas = p_.areas.map((area, index) => {
            const content = this.sortContent(area.content, p_.sources)

            return {
                area,
                content,
            }
        })

        areas[-1] = {
            area: p_.areas[-1],
            content: this.sortContent(p_.areas[-1].content, p_.sources),
        }

        return areas
    }

    // Формирует структуру, необходимую для UI отображениея контента. Раньше ещё было поле "audio" в sorted, поэтому метод был назван "sortContent"
    sortContent(content, sources) {
        const sorted = {
            files: [],
        }

        content.forEach((contentItem, index) => {
            if (sources[contentItem.sourceId]) {
                const fileItem = sources[contentItem.sourceId]
                const sortedItem = {
                    id: fileItem.id,
                    name: fileItem.name,
                    fileType: fileItem.fileType,
                    type: fileItem.type,
                    thumbnail: fileItem.thumbnail,
                    src: fileItem.src,
                    style: contentItem.style || fileItem.style,
                    data: fileItem.data,
                    iFrameAvailable: fileItem.iFrameAvailable,
                    width: contentItem.position.width,
                    height: contentItem.position.height,
                    x: contentItem.position.left,
                    y: contentItem.position.top,
                    zIndex: contentItem.position.zIndex,
                    fillMode: contentItem.position.fillMode,
                    startTime: contentItem.startTime,
                    duration: contentItem.duration,
                    layersCount: contentItem.layersCount,
                    animation: contentItem.animation,
                    volume: contentItem.volume,
                    index,
                }

                sorted.files.push(sortedItem)
            }
        })

        return sorted
    }

    // Событие на ресайз зоны. Проверка, есть ли после ресайза пересечения с остальными зонами.
    onResize = (pos, dir, areaIndex) => {
        const p_ = this.props
        const currentArea = p_.areas[areaIndex]
        let isIntersect = false
        let maxParam

        p_.areas.forEach((area, index) => {
            if (areaIndex !== index) {
                if (editor.intersectAreas(pos, area)) {
                    isIntersect = true

                    switch (dir) {
                        case 'left':
                            maxParam = currentArea.width + currentArea.left - (area.left + area.width)
                            if (maxParam > 0) {
                                this.setState({
                                    maxWidth: {
                                        [areaIndex]: maxParam,
                                    },
                                })
                            }
                            break
                        case 'right':
                            maxParam = currentArea.width + area.left - (currentArea.left + currentArea.width)

                            if (maxParam > 0) {
                                this.setState({
                                    maxWidth: {
                                        [areaIndex]: maxParam,
                                    },
                                })
                            }
                            break
                        case 'top':
                            maxParam = currentArea.height + currentArea.top - (area.height + area.top)
                            if (maxParam > 0) {
                                this.setState({
                                    maxHeight: {
                                        [areaIndex]: maxParam,
                                    },
                                })
                            }
                            break
                        case 'bottom':
                            maxParam = currentArea.height + area.top - (currentArea.height + currentArea.top)

                            if (maxParam > 0) {
                                this.setState({
                                    maxHeight: {
                                        [areaIndex]: maxParam,
                                    },
                                })
                            }
                            break
                        default:
                            break
                    }
                }
            }
        })

        return !isIntersect
    }

    //Отслеживание при изменении размеров холста именно в html (При изменении окна браузера или при скрытии левого тулбара)
    onChangeScreenSize = (dimensions) => {
        const p_ = this.props
        const initLineSize = 1

        this.setState({
            lineWidth: initLineSize / dimensions.width,
            lineHeight: initLineSize / dimensions.height,
            scale: dimensions.width / p_.resolutionWidth,
        })
    }

    // Событие на скролл по экрану. Скролл появляется при зуме
    onScroll = (e) => {
        const scrollLeft = e.target.scrollLeft
        const scrollTop = e.target.scrollTop

        this.setState({ scrollLeft, scrollTop })
    }

    canDragScroll = () => {
        const p_ = this.props

        return this.scrollbar && p_.zoom > 1 && !p_.isSimple
    }

    onMouseDown = (e) => {
        if (!this.canDragScroll()) {
            return
        }

        if (!this.dragging) {
            this.dragging = true
        }

        this.clientX = e.clientX
        this.clientY = e.clientY
    }

    onMouseMove = (e) => {
        if (this.dragging && this.canDragScroll()) {
            helpers.debounce(
                () => {
                    const position = this.scrollbar.getValues()
                    const scrollLeft = position.scrollLeft - (-this.clientX + (this.clientX = e.clientX))
                    const scrollTop = position.scrollTop - (-this.clientY + (this.clientY = e.clientY))

                    this.scrollbar.scrollLeft(scrollLeft)
                    this.scrollbar.scrollTop(scrollTop)
                },
                { id: 'onMouseMoveScreen', limit: 5 }
            )
        }
    }

    onMouseUp = () => {
        if (this.dragging && this.canDragScroll()) {
            this.dragging = false
        }
    }

    onMouseLeave = () => {
        if (this.dragging && this.canDragScroll()) {
            this.dragging = false
        }
    }

    handleDelete = (inPageContentId) => {
        const p_ = this.props
        editor.deleteContent(p_.selectedArea, p_.selectedContent, inPageContentId)
    }

    handleChangeContent = (inPageContentId, source) => {
        const p_ = this.props
        editor.changeContent(p_.selectedArea, p_.selectedContent, inPageContentId, source)
    }

    handleEdit = () => {
        const p_ = this.props
        editor.editContent(p_.selectedArea, p_.selectedContent)
    }

    togglePreview = (isOpen) => {
        this.setState({ openPreview: isOpen })
    }

    handleMouseEnter = () => {
        this.setState({ mouseIn: true })
    }

    handleMouseLeave = () => {
        this.setState({ mouseIn: false })
    }

    handleClearSelectArea = () => {
        editor.selectArea(null)
    }
}

export default EditorScreenMethods

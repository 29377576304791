import { addStyles } from 'ethcss'
import item from 'blocks.simple/item/item.jcss'
import { indents, mediaCSS, textSizes } from 'theme/media'
import { colors, grid, IStyles } from '../../../theme'

const dashboardMemoryStyles: IStyles = {
    wrapper: {
        ...grid.col,
        ...grid.space,
        ...grid.justify,
        //...item.rel,
        height: '100%',
    },
    memoryInfo: {
        ...grid.row,
        ...grid.noWrap,
        // alignItems: 'flex-end',
        alignItems: 'baseline',
        [mediaCSS.tabMini]: {
            ...grid.center,
            ...grid.col,
        },
        paddingBottom: '15px',
    },
    title: {
        textTransform: 'uppercase',
    },
    chart: {
        ...grid.mr_md,
        '& .donutchart-arcs-path': {
            stroke: 'transparent!important',
        },
        '& .donutchart-innertext-label': {
            fontWeight: 'bold',
        },
        '& .selected.donutchart-arcs-path': {
            opacity: '1!important',
        },
        width: '45%',
    },
    chartWrapper: {
        border: `1px solid ${colors.blueAccent}`,
        borderRadius: '5px',
        overflow: 'hidden',
        fontSize: 0,
    },
    chartInfoLocal: {
        display: 'flex',
        flexWrap: 'nowrap',
    },
    chartInfo: {
        width: '55%',
    },
    chartCol: {
        ...grid.col,
    },
    mark: {
        display: 'inline-block',
        width: 15,
        height: 15,
        ...grid.mr_micro,
        borderRadius: '3px',
    },
    legend: {
        ...grid.row,
        ...grid.mt_md,
        wordBreak: 'break-all',
        lineHeight: 1,
    },
    legendOther: {
        ...grid.row,
        marginTop: '8px',
        wordBreak: 'break-all',
        lineHeight: 1,
    },
    link: {
        ...grid.mt_micro,
        fontWeight: 'bold',
        textDecoration: 'underline',
        color: colors.blueAccent,
        '&>span': {
            fontSize: '12px',
            ...item.pointer,
        },
    },
    info: {
        fontSize: textSizes.mini,
    },
    memoryTotal: {
        ...grid.row,
        ...grid.noWrap,
        ...grid.justify,
        marginBottom: '20px',
    },
    memoryBlock: {},
    memoryLabel: {
        marginBottom: '4px',
    },
    memoryValue: {
        fontSize: '20px',
        fontWeight: 700,
    },
    accentColor: {
        color: colors.blueAccent,
    },
    removeContentBlock: {
        // position: 'absolute',
        // right: '6px',
        // bottom: '8px',
        // textAlign: 'right',
    },
    mediaInfo: {
        textTransform: 'uppercase',
        paddingTop: '20px',
        color: colors.grey,
    },
    accentBorder: {
        border: `1px solid ${colors.blueAccent}`,
    },
    shortInfo: {
        display: 'block !important',

        '& >div': {
            width: '100%',
        },
    },
    gap: {
        marginTop: '20px',
    },
    spaceInfoText: {
        width: 'calc(100% - 40px)',
        overflowWrap: 'normal',
        wordWrap: 'normal',
        wordBreak: 'normal',
        lineBreak: 'auto',
    },
    detailInfo: {
        borderTop: '1px solid #E4E4E4',
    },
    detailInfoList: {
        marginTop: '30px',
    },
    detailInfoItem: {
        marginTop: '8px',
    },
}

addStyles(dashboardMemoryStyles)
export default dashboardMemoryStyles

import React from 'react'
import Link from 'blocks.simple/link/link'
import Icon from 'blocks.simple/icon/icon'
import Button from 'blocks.simple/button/button'
import TextEditor from 'blocks.app/textEditor/textEditor'
import TableEditor from 'blocks.app/tableEditor/tableEditor'
import LinkEditor from 'blocks.app/linkEditor/linkEditor'
import grid from 'blocks.simple/grid/grid.jcss'
import translate from 'core/translate'
import styles from './catalog__filter_files.jcss'
import helpersLegacy from 'core/helpers'

import helpers from 'core/helpers'
import { cn } from 'ethcss'
import { text as textStyles } from 'theme'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from 'blocks.simple/icon/icon.theme'
import { getURLSearchParamsByLocation, getURLSearchParamsString } from 'features/routes/utils'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import {
    closeAddLink,
    closeAddTable,
    closeAddText,
    filesActions,
    saveLink,
    saveTable,
    saveText,
} from 'pages/files/files.state'

function isActive(item, type, fileType) {
    if (type && fileType && item.type === type && item.fileType === fileType) {
        return true
    }

    if (type && !fileType && item.type === type) {
        return true
    }

    if (!type && !fileType && !item.type && !item.fileType) {
        return true
    }
}

const isEditContent = (query) => {
    return query.textId || query.tableId || query.linkId
}

class FilesFilter extends React.Component {
    constructor(p_) {
        super(p_)

        this.state = {
            editorHtml: '',
        }
    }

    getFilterItems = () => {
        return [
            { name: translate('all') },
            { name: translate('images'), fileType: 'image', type: 'fs' },
            { name: translate('video'), fileType: 'video', type: 'fs' },
            { name: translate('music'), fileType: 'audio', type: 'fs' },
            { name: translate('office'), fileType: 'office', type: 'fs' },
            { name: translate('links'), type: 'link', fileType: 'web_page', iconName: 'link' },
            { name: translate('streamMedia'), type: 'link', fileType: 'stream', iconName: 'stream' },
            { name: translate('text'), type: 'text' },
            { name: translate('tables'), type: 'table' },
            { name: translate('web_apps'), fileType: 'web_app', type: 'fs' },
        ]
    }

    render() {
        const p_ = this.props
        const items = p_.filterItems || this.getFilterItems()
        const query = { ...p_.query, fileType: undefined, type: undefined }
        const isCreatableContent = p_.folderId && helpers.isAvailable({ key: 'files', action: 'create' })
        const locationQuery = getURLSearchParamsByLocation(p_.location)
        const pathname = p_.location.pathname

        return (
            <React.Fragment>
                <div className={styles.wrapper}>
                    <div className={styles.content}>
                        {items.map((item, index) => {
                            if (
                                item.type &&
                                !helpersLegacy.isAvailableFileType({
                                    key: 'files',
                                    name: item.fileType ? item.fileType : item.type,
                                    action: 'read',
                                })
                            ) {
                                return null
                            }
                            return (
                                <Link
                                    key={`files__filter_${index}`}
                                    to={{
                                        pathname: pathname,
                                        search: getURLSearchParamsString({
                                            ...query,
                                            [helpersLegacy.prefixQuery({ name: p_.type, field: 'query' })]: query.query,
                                            [helpersLegacy.prefixQuery({
                                                name: p_.type,
                                                field: 'fileType',
                                            })]: item.fileType,
                                            [helpersLegacy.prefixQuery({ name: p_.type, field: 'type' })]: item.type,
                                            query: undefined,
                                        }),
                                    }}
                                    className={cn(styles.item, textStyles.low, {
                                        [styles.itemActive]: isActive(item, p_.query.type, p_.query.fileType),
                                    })}
                                >
                                    <Icon
                                        type={`${item.fileType || item.type || 'fs'}`}
                                        color={
                                            isActive(item, p_.query.type, p_.query.fileType)
                                                ? getThemeStyleValue('ui', 'icon', 'active') ||
                                                  defaultThemeStyles.active
                                                : getThemeStyleValue('ui', 'icon', 'main') || defaultThemeStyles.main
                                        }
                                        containerClass={styles.icon}
                                    />
                                    {!p_.small && <span>{item.name}</span>}
                                </Link>
                            )
                        })}
                    </div>
                    {isCreatableContent && (
                        <React.Fragment>
                            {p_.query.type === 'text' && !p_.toggleControls && (
                                <div className={styles.activateEditorButton}>
                                    <Button
                                        mod="fill"
                                        onClick={function () {
                                            p_.activateAddText()
                                        }}
                                    >
                                        {translate('addNewText')}
                                    </Button>
                                </div>
                            )}
                            {p_.query.type === 'table' && !p_.toggleControls && (
                                <div className={styles.activateEditorButton}>
                                    <Button
                                        mod="fill"
                                        onClick={function () {
                                            p_.activateAddTable()
                                        }}
                                    >
                                        {translate('addTable')}
                                    </Button>
                                </div>
                            )}
                            {p_.query.type === 'link' && !p_.toggleControls && (
                                <div className={styles.activateEditorButton}>
                                    <Button
                                        mod="fill"
                                        onClick={function () {
                                            p_.activateAddLink()
                                        }}
                                    >
                                        {translate('addLink')}
                                    </Button>
                                </div>
                            )}
                        </React.Fragment>
                    )}
                    {(p_.query.type === 'text' || p_.query.type === 'table' || p_.query.type === 'link') &&
                        p_.toggleControls && (
                            <div className={styles.activateEditorButton}>
                                <Button
                                    onClick={function () {
                                        switch (p_.query.type) {
                                            case 'text':
                                                p_.closeAddText()
                                                break
                                            case 'table':
                                                p_.closeAddTable()
                                                break
                                            case 'link':
                                                p_.closeAddLink()
                                                break
                                            default:
                                                break
                                        }
                                    }}
                                    className={cn(grid.mr_mini)}
                                >
                                    {translate('cancel')}
                                </Button>
                                <Button
                                    mod="fill"
                                    onClick={() => {
                                        switch (p_.query.type) {
                                            case 'text':
                                                const data = this.textEditor.saveMethod()
                                                p_.saveText(data, locationQuery)
                                                break
                                            case 'table':
                                                const tableData = this.tableEditor.saveMethod()
                                                p_.saveTable(tableData, locationQuery)
                                                break
                                            case 'link':
                                                const linkData = this.linkEditor.getData()

                                                if (linkData.error) {
                                                    return
                                                }

                                                p_.saveLink(linkData, locationQuery)
                                                break
                                            default:
                                                break
                                        }
                                    }}
                                >
                                    {isEditContent(locationQuery) ? translate('update') : translate('save')}
                                </Button>
                            </div>
                        )}
                </div>
                {p_.query.type === 'text' && p_.toggleControls && (
                    <div className={cn('picker-container', styles.editors)}>
                        <TextEditor
                            ref={(textEditor) => (this.textEditor = textEditor)}
                            query={locationQuery}
                            small={p_.small}
                        />
                    </div>
                )}
                {p_.query.type === 'table' && p_.toggleControls && (
                    <div className={cn('picker-container', styles.editors)}>
                        <TableEditor
                            ref={(tableEditor) => (this.tableEditor = tableEditor)}
                            location={p_.location}
                            small={p_.small}
                        />
                    </div>
                )}
                {p_.query.type === 'link' && p_.toggleControls && (
                    <div className={cn('picker-container', styles.editors)}>
                        <LinkEditor
                            ref={(linkEditor) => (this.linkEditor = linkEditor)}
                            location={p_.location}
                            small={p_.small}
                        />
                    </div>
                )}
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        activateAddText: () => dispatch(filesActions.activateAddText()),
        activateAddTable: () => dispatch(filesActions.activateAddTable()),
        activateAddLink: () => dispatch(filesActions.activateAddLink()),
        closeAddText: () => dispatch(closeAddText()),
        closeAddTable: () => dispatch(closeAddTable()),
        closeAddLink: () => dispatch(closeAddLink()),
        saveText: (saveFileData) => dispatch(saveText(saveFileData)),
        saveLink: (saveFileData) => dispatch(saveLink(saveFileData)),
        saveTable: (saveFileData) => dispatch(saveTable(saveFileData)),
    }
}

export default connect(null, mapDispatchToProps)(withRouter(FilesFilter))

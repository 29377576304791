import { addStyles } from 'ethcss'
import { grid, item, IStyles, colors } from 'theme'
import { indents, layers } from 'blocks.app/config'
import { mediaCSS } from 'theme'
import { defaultThemeStyles } from 'blocks.app/sidebar/Sidebar-theme'
import 'blocks.simple/animation/animation.css'

const filesStyles: IStyles = {
    filterWrapper: {
        marginRight: -grid.mr_md['margin-right'],
    },
    filter: {
        ...item.white,
    },
    buttonClose: {
        zIndex: layers.drop,
        ...grid.flex,
        ...grid.justifyEnd,
        ...grid.pb_miniPlus,
    },
    link: {
        textDecoration: 'underline',
        cursor: 'pointer',
        color: colors.blueAccent,
    },
    menuItem: {
        ...grid.p_mini,
        cursor: 'pointer',
        ...item.transitionErase,
        textAlign: 'center',
        color: `var(--var-module-sidebar-component-sidebar-style-menuTextHover, ${defaultThemeStyles.menuTextHover})`,
        display: 'block',
        textDecoration: 'none',
        '&:hover': {
            color: `var(--var-module-sidebar-component-sidebar-style-menuText, ${defaultThemeStyles.menuText})`,
        },
    },
    breadcrumbs: {
        ...grid.pl_mdPlus,
        marginLeft: -grid.m_mdPlus['margin'],
        marginTop: '10px',
        width: `calc(100% + ${2 * grid.m_mdPlus['margin']}px)`,
        [mediaCSS.tabMini]: {
            marginTop: -grid.m_mini['margin'],
        },
    },
    breadcrumbs_x: {
        paddingLeft: '0px',
    },
    button: {
        position: 'absolute',
        bottom: indents.md,
        left: 'calc(50% - 55px)',
    },
    modalWrapper: {
        ...grid.full,
        ...grid.rowCenter,
    },
    modalInner: {
        ...grid.w30,
        [mediaCSS.tab]: {
            ...grid.w60,
        },
        [mediaCSS.tabMini]: {
            ...grid.w90,
        },
        backgroundColor: `var(--var-module-sidebar-component-sidebar-style-background, ${defaultThemeStyles.background})`,
        boxShadow: '0 19px 38px rgb(0 0 0 / 30%), 0 15px 12px rgb(0 0 0 / 22%)',
        ...item.rounded_full_mini,
        overflow: 'hidden',
        position: 'relative',
        minHeight: 300,
    },
    overflow: {
        overflowY: 'auto',
    },
    maxHeight: {
        maxHeight: '80vh',
    },
    aiWrapper: {
        background: colors.greyLight,
        width: '30vw',
        height: '60vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [mediaCSS.tab]: {
            width: '80vw',
        },
    },
    catalogWrapper: {
        width: '30vw',
        height: '60vh',
        display: 'flex',
        justifyContent: 'center',
    },
    grey: {
        color: colors.grey,
    },
    loader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: `30px solid ${colors.white}`,
        borderTop: `30px solid ${colors.blueAccent}`,
        borderRadius: '50%',
        width: '300px',
        height: '300px',
        animation: 'spin 1s linear infinite',
    },
    loaderText: {
        position: 'absolute',
    },
    aiContentImg: {
        maxWidth: '100%',
        maxHeight: '45vh',
    },
    hidden: {
        visibility: 'hidden',
    },
    errorInputText: {
        color: 'red',
        fontSize: '12px',
    },
}

addStyles(filesStyles)
export default filesStyles

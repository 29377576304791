import React from 'react'
import { DefaultNodeModel, DefaultPortModel } from 'storm-react-diagrams'
import FlowDiagramPortModel from 'blocks.app/flowDiagram/__port/flowDiagram__port_model'
import { colorsDiagram } from './config'

import translate from 'core/translate'

class FlowDiagramsLocalMethods extends React.PureComponent {
    indexIsPlayOnEvent = 0
    indexNotPlayOnEvent = 0

    createNodes = (pages) => {
        return pages.map((page) => this.drawNode(page))
    }
    getTitleByPage = (page) => {
        return `${translate('page')} ${page.order + 1} (${page.duration / 1000} ${translate('sec')})`
    }
    getColorByPage = (page, color) => {
        if (color) {
            return color
        }

        let newColor = colorsDiagram.regular

        if (page.order === 0) {
            newColor = colorsDiagram.start
        }

        if (page.playOnEvent) {
            newColor = colorsDiagram.onEvent
        }
        if (page.order === page.nextPageOrder) {
            newColor = colorsDiagram.looped
        }
        return newColor
    }
    drawNode = (page, color) => {
        let title = this.getTitleByPage(page)
        color = this.getColorByPage(page, color)

        const node = new DefaultNodeModel(title, color)
        node.setPosition(...this.getPosition(page))
        this.setPort(page, node)
        node.page = page
        return node
    }
    getPosition = (page) => {
        const startLeftOffset = 450
        const offsetFromFirstPage = 250
        const leftOffset = 230
        const verticalOffset = 120
        const verticalOffsetOnEvents = 200

        if (!page.playOnEvent) {
            if (page.order === 0) {
                // для первой страницы меньше отступ слева
                return [startLeftOffset, ++this.indexNotPlayOnEvent * verticalOffset]
            }
            if (page.nextPageOrder === 0) {
                //если страница указывает на Стартовую
                return [startLeftOffset - 200, ++this.indexNotPlayOnEvent * verticalOffset]
            }
            return [
                ++this.indexNotPlayOnEvent * leftOffset + offsetFromFirstPage,
                this.indexNotPlayOnEvent * verticalOffset,
            ]
        } else {
            if (this.indexIsPlayOnEvent === 0) {
                return [20, ++this.indexIsPlayOnEvent * verticalOffset]
            }
            return [20, ++this.indexIsPlayOnEvent * verticalOffsetOnEvents]
        }
    }
    setPort = (page, node) => {
        if (page.playOnEvent) {
            node.addPort(new FlowDiagramPortModel(false, translate('end')))
            page.playOnEvent.events.forEach((event) => {
                const { key, comparisonType, value } = event.eventCondition
                // node.addPort(new DefaultPortModel(true, translate(event.eventType))); //правильный вариант, но нужны типы событий и переводы
                node.addPort(new DefaultPortModel(true, `${key} ${comparisonType} ${value}`))
            })
        } else {
            if (page.order !== page.nextPageOrder) {
                node.addPort(new FlowDiagramPortModel(false, translate('end')))
                node.addPort(new DefaultPortModel(true, translate('start')))
            } else {
                node.addPort(new DefaultPortModel(true, translate('loop')))
            }
        }
    }

    createLinks = (pages, nodes) => {
        const links = []
        pages.forEach((currentPage) => {
            const currentNode = nodes.find((node) => node.page.order === currentPage.order)
            const targetNode = nodes.find((node) => node.page.order === currentPage.nextPageOrder)
            if (currentNode && currentNode.ports && targetNode && targetNode.ports) {
                const outPort = this.portsToArray(currentNode.ports).find((port) => !port.in)
                const inPort = this.portsToArray(targetNode.ports).find((port) => port.in)

                if (currentPage.order !== currentPage.nextPageOrder) {
                    links.push(this.connectNodes(outPort, inPort))
                }
            }
        })

        return links
    }
    portsToArray = (ports) => {
        const array = []
        for (let key in ports) {
            if (ports.hasOwnProperty(key)) {
                array.push(ports[key])
            }
        }
        return array
    }
    connectNodes = (portOut, portIn) => {
        let link = portOut.link(portIn)
        link.width = 4
        return link
    }
}

export default FlowDiagramsLocalMethods

import React, { useEffect, useState } from 'react'
import EditText from 'blocks.simple/editText/editText'
import { colors, grid, item } from 'theme'
import { cn } from 'ethcss'
import styles from './../files.jcss'
import translate from 'core/translate'
import Button from 'blocks.simple/button/button'
import { api } from 'core/api/ConnectionManager'
import { Typography } from 'atoms/Typography'
import Catalog from 'blocks.app/catalog/catalog'
import Modal from 'blocks.simple/modal/modal'
import { isEmpty } from 'lodash'

interface ISelectedInfo {
    isDirectory: number
    fileType: string
    name: string
    id: number
}
const OpenAi = (p_: any) => {
    const [selectedInfo, setSelectedInfo] = useState<ISelectedInfo | null>(null)
    const [requestData, setRequestData] = useState<{
        width: number
        height: number
        query: string
    }>({
        width: 524,
        height: 524,
        query: '',
    })
    const [aiContent, setAiContent] = useState<any>()
    const [queryInputTextError, setQueryInputTextError] = useState<boolean>(false)
    const [widthInputTextError, setWidthInputTextError] = useState<string>('')
    const [heightInputTextError, setHeightInputTextError] = useState<string>('')
    const [isAiContent, setIsAiContent] = useState<boolean>(false)
    const [waitingMode, setWaitingMode] = useState<boolean>(false)
    const [saveMode, setSaveMode] = useState<boolean>(false)

    const createAiContent = () => {
        inputNullCheck(requestData.query, 'text')
        inputNullCheck(requestData.width, 'width')
        inputNullCheck(requestData.height, 'height')
        setWaitingMode(true)
        api.send('createAiContent', {
            width: requestData.width,
            height: requestData.height,
            query: requestData.query,
            generationMethod: 'fusionbrain',
        })
            .then((res: any) => {
                if (res) {
                    setIsAiContent(!isAiContent)
                } else {
                    setWaitingMode(false)
                    return
                }
            })
            .catch(() => setWaitingMode(false))
    }

    const cancelAiContent = () => {
        api.send('cancelAiContent', {}).then(() => {
            p_.closeAiFileModal()
            setAiContent(null)
            setWaitingMode(false)
        })
    }

    const saveAiContent = () => {
        api.send('saveAiContent', {
            fileName: requestData.query + '.png',
            folderId: selectedInfo && selectedInfo.isDirectory ? selectedInfo.id : p_.query.folderId,
        })
            .then(() => {
                setSaveMode(false)
                cancelAiContent()
                return
            })
            .catch(() => {})
    }

    const inputNullCheck = (value: string | number, type: string) => {
        if (type === 'text') {
            value ? setQueryInputTextError(false) : setQueryInputTextError(true)
        } else if (type === 'width') {
            value
                ? value > 1024
                    ? setWidthInputTextError('1024')
                    : setWidthInputTextError('')
                : setWidthInputTextError('true')
        } else {
            value
                ? value > 1024
                    ? setHeightInputTextError('1024')
                    : setHeightInputTextError('')
                : setHeightInputTextError('true')
        }
    }

    useEffect(() => {
        if (p_.open) {
            let interval = setInterval(() => {
                api.send('getAiContent', {}, { showEmitError: false })
                    .then((res: any) => {
                        if (res) {
                            setAiContent(res)
                        }
                        if ((res && res.status === 'finished') || isEmpty(res)) {
                            setWaitingMode(false)
                            return clearInterval(interval)
                        }
                    })
                    .catch(() => {
                        clearInterval(interval)
                        setWaitingMode(false)
                        return
                    })
            }, 10000)

            return () => {
                clearInterval(interval)
            }
        }
    }, [isAiContent])

    useEffect(() => {
        aiContent && aiContent.image && setWaitingMode(false)
    }, [aiContent])

    return (
        <Modal open={p_.open} showCloseIcon={false} mod={'clear'} transitionName={'fade'} onClose={cancelAiContent}>
            <div className={styles.modalWrapper}>
                <div className={cn(grid.full, grid.rowCenter, styles.overflow)}>
                    <div className={cn(item.propertyCard, item.shadowModal, item.rounded_full_mini)}>
                        <div className={cn(grid.col, item.propertyWidthCard, styles.maxHeight)}>
                            {saveMode ? (
                                <div>
                                    <div className={styles.catalogWrapper}>
                                        <Catalog
                                            type={'files'}
                                            searchbarMod={'withShadow'}
                                            small={true}
                                            hideHeader={false}
                                            showHeaderButton={false}
                                            disposition={'tile'}
                                            selectedInfo={selectedInfo}
                                            onSelectInfo={(selectedInfo: any) => setSelectedInfo(selectedInfo)}
                                            filterItems={[{ name: translate('all') }]}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div className={cn(grid.mb_micro, styles.grey)}>{translate('requestText')}</div>
                                    <EditText
                                        mod={'withBorder'}
                                        className={cn(grid.mb_micro, styles.input)}
                                        value={requestData.query}
                                        validationBorder={queryInputTextError}
                                        onChange={(e: string) => {
                                            setRequestData({ ...requestData, query: e })
                                        }}
                                    />
                                    <div
                                        className={cn(
                                            grid.mb_mini,
                                            queryInputTextError ? '' : styles.hidden,
                                            styles.errorInputText
                                        )}
                                    >
                                        {translate('fieldIsRequired')}
                                    </div>
                                    <div className={cn(grid.mb_micro, styles.grey)}>{translate('width') + ' (px)'}</div>
                                    <EditText
                                        tabindex={'1'}
                                        type={'number'}
                                        mod={'withBorder'}
                                        validationBorder={widthInputTextError !== ''}
                                        className={cn(grid.mb_micro, styles.input)}
                                        value={requestData.width}
                                        onChange={(e: number) => {
                                            setRequestData({ ...requestData, width: e })
                                        }}
                                    />
                                    <div
                                        className={cn(
                                            grid.mb_mini,
                                            widthInputTextError !== '' ? '' : styles.hidden,
                                            styles.errorInputText
                                        )}
                                    >
                                        {widthInputTextError === '1024'
                                            ? translate('less1025')
                                            : translate('fieldIsRequired')}
                                    </div>
                                    <div className={cn(grid.mb_micro, styles.grey)}>
                                        {translate('height') + ' (px)'}
                                    </div>
                                    <EditText
                                        tabindex={'1'}
                                        type={'number'}
                                        mod={'withBorder'}
                                        validationBorder={heightInputTextError !== ''}
                                        className={cn(grid.mb_micro, styles.input)}
                                        value={requestData.height}
                                        onChange={(e: number) => {
                                            setRequestData({ ...requestData, height: e })
                                        }}
                                    />
                                    <div
                                        className={cn(
                                            grid.mb_md,
                                            heightInputTextError !== '' ? '' : styles.hidden,
                                            styles.errorInputText
                                        )}
                                    >
                                        {heightInputTextError === '1024'
                                            ? translate('less1025')
                                            : translate('fieldIsRequired')}
                                    </div>
                                    <div className={styles.aiWrapper}>
                                        {waitingMode ? (
                                            <>
                                                <div className={styles.loader} />
                                                <Typography
                                                    className={styles.loaderText}
                                                    textSize={'md'}
                                                    textColor={colors.blueAccent}
                                                    type="title"
                                                >
                                                    {translate('generating')}
                                                </Typography>
                                            </>
                                        ) : (
                                            aiContent &&
                                            aiContent.image && (
                                                <img
                                                    className={styles.aiContentImg}
                                                    src={`data:image/png;base64,${aiContent.image}`}
                                                />
                                            )
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                        <div className={cn(grid.row, grid.justify, grid.mt_md)}>
                            <div />
                            <Button className={grid.mr_md} mod="fill" onClick={cancelAiContent}>
                                {translate('cancel')}
                            </Button>
                            {!waitingMode && !saveMode && (
                                <Button className={grid.mr_md} mod="fill" onClick={createAiContent}>
                                    {aiContent && aiContent.image ? translate('repeatVerb') : translate('generate')}
                                </Button>
                            )}
                            {!waitingMode && aiContent && aiContent.image && !saveMode && (
                                <Button mod="fill" onClick={() => setSaveMode(true)}>
                                    {translate('save')}
                                </Button>
                            )}
                            {!waitingMode && aiContent && aiContent.image && saveMode && (
                                <Button mod="fill" onClick={saveAiContent}>
                                    {translate('save')}
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default OpenAi

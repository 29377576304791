import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { api } from 'core/api/ConnectionManager'
import helpers from 'core/helpers'
import { createRequestHeaders } from 'core/helpers/user'
import { IFile, IFont } from 'core/models/Files'
import { TagShortModel } from 'core/models/Tags'
import { emitError } from 'features/appNotifications/AppNotifications.state'
import { loaderActions } from 'features/loader/Loader.state'
import { changeFilter } from 'features/routes'
import helpersFiles from 'core/helpers/_files'
import { IUserState } from 'blocks.app/user/user.state'

export interface SaveFileData {
    data: any
    options: any
    callback: (data: any) => void
}

export interface ClearFilesState {
    skip: string[]
}

export interface ParseCsvPayload {
    formData: FormData
    cb: (data: any) => void
}

export interface FontsUploadPayload {
    files: File[]
    supportedFontTypes: string[]
    cb: (data: IFont[]) => void
}

export interface UploadMediaPayload {
    formData: FormData
    cb: () => void
}

export interface UploadVideoEditorPayload {
    file: File
    onComplete: (data: any) => void
    onProgress: (percent: number) => void
}

export interface IFilesState {
    breadcrumbs: any[]
    toolbar: string
    folders: IFile[]
    files: IFile[]
    tags: TagShortModel[]
    isOpenAddFile: boolean
    isOpenAiFile: boolean
    isAddText: boolean
    isAddTable: boolean
    isAddLink: boolean
    isPreviewOpen: boolean
    isTagsEditModalOpen: boolean
}

const initialState: IFilesState = {
    breadcrumbs: [],
    toolbar: 'left',
    folders: [],
    files: [],
    tags: [],
    isOpenAddFile: false,
    isOpenAiFile: false,
    isAddText: false,
    isAddTable: false,
    isAddLink: false,
    isPreviewOpen: false,
    isTagsEditModalOpen: false,
}

export const rotateVideo = createAsyncThunk(
    'files/rotateVideo',
    async (source: { sourceId: number }, { getState, dispatch, rejectWithValue }) => {
        try {
            await api.send('retryRotateVideo', source)
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)

export const deleteFolder = createAsyncThunk(
    'files/deleteFolder',
    async (id: number, { getState, dispatch, rejectWithValue }) => {
        try {
            await api.send('deleteFolder', { id })
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)

export const deleteFile = createAsyncThunk(
    'files/deleteFile',
    async (id: number, { getState, dispatch, rejectWithValue }) => {
        try {
            await api.send('deleteFile', { id })
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)

export const updateFolder = createAsyncThunk(
    'files/updateFolder',
    async (folder: IFile, { getState, dispatch, rejectWithValue }) => {
        try {
            await api.send('updateFolder', folder)
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)

export const updateFile = createAsyncThunk(
    'files/updateFile',
    async (file: IFile, { getState, dispatch, rejectWithValue }) => {
        try {
            await api.send('updateFile', file)
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)

export const getFileSourceTags = createAsyncThunk(
    'files/getFileSourceTags',
    async (data, { getState, dispatch, rejectWithValue }) => {
        try {
            return await api.send<object, TagShortModel[]>('getSourceTags', {})
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)

export const createFilesFolder = createAsyncThunk(
    'files/createFilesFolder',
    async (folder: Partial<IFile>, { getState, dispatch, rejectWithValue }) => {
        try {
            await api.send('createFolder', folder)
        } catch (err) {
            return rejectWithValue(err)
        }
    }
)

export const activateEditText = createAsyncThunk(
    'files/activateEditText',
    async (textId: number, { getState, dispatch, rejectWithValue }) => {
        const prefix = helpers.prefixQuery({ name: 'files', field: 'type' })
        changeFilter({ [prefix]: 'text', textId })
        return textId
    }
)

export const activateEditTable = createAsyncThunk(
    'files/activateEditTable',
    async (tableId: number, { getState, dispatch, rejectWithValue }) => {
        const prefix = helpers.prefixQuery({ name: 'files', field: 'type' })
        changeFilter({ [prefix]: 'table', tableId })
        return tableId
    }
)

export const activateEditLink = createAsyncThunk(
    'files/activateEditLink',
    async (linkId: number, { getState, dispatch, rejectWithValue }) => {
        const prefix = helpers.prefixQuery({ name: 'files', field: 'type' })
        changeFilter({ [prefix]: 'link', linkId })
        return linkId
    }
)

export const closeAddText = createAsyncThunk(
    'files/closeAddText',
    async (data, { getState, dispatch, rejectWithValue }) => {
        changeFilter({ textId: undefined })
        return true
    }
)

export const closeAddTable = createAsyncThunk(
    'files/closeAddTable',
    async (data, { getState, dispatch, rejectWithValue }) => {
        changeFilter({ tableId: undefined })
        return true
    }
)

export const closeAddLink = createAsyncThunk(
    'files/closeAddLink',
    async (data, { getState, dispatch, rejectWithValue }) => {
        changeFilter({ linkId: undefined })
        return true
    }
)

export const saveText = createAsyncThunk(
    'files/saveText',
    async (saveFileData: SaveFileData, { getState, dispatch, rejectWithValue }) => {
        const { data, callback, options } = saveFileData
        const { textId, folderId } = options

        const method = textId ? 'updateFile' : 'createSource'

        if (!data) return

        if (textId) {
            data.id = textId
        }

        if (folderId) {
            data.folderId = folderId
        }

        try {
            await api.send(method, data)
            dispatch(closeAddText())
        } catch (err) {
            rejectWithValue(err)
        }
    }
)

export const saveTable = createAsyncThunk(
    'files/saveTable',
    async (saveFileData: SaveFileData, { getState, dispatch, rejectWithValue }) => {
        const { data, callback, options } = saveFileData
        const { tableId, folderId } = options

        const method = tableId ? 'updateFile' : 'createSource'

        if (!data) return

        if (tableId) {
            data.id = tableId
        }

        if (folderId) {
            data.folderId = folderId
        }

        try {
            const tableData = await api.send(method, data)

            if (callback) {
                callback(tableData)
            } else {
                dispatch(closeAddTable())
            }
        } catch (err) {
            rejectWithValue(err)
        }
    }
)

export const saveLink = createAsyncThunk(
    'files/saveLink',
    async (saveFileData: SaveFileData, { getState, dispatch, rejectWithValue }) => {
        const { data, options } = saveFileData
        const { linkId } = options

        const method = linkId ? 'updateFile' : 'createSource'

        if (!data) return

        try {
            await api.send(method, data)
            dispatch(closeAddLink())
        } catch (err) {
            rejectWithValue(err)
        }
    }
)

export const parseCsv = createAsyncThunk(
    'files/parseCsv',
    async (data: ParseCsvPayload, { getState, dispatch, rejectWithValue }) => {
        const { formData, cb } = data
        const state = getState() as { user: IUserState }
        const { data: user, mainUser, tokens } = state.user

        if (!mainUser || !user || !tokens) return

        try {
            const res = await api.post('parseCsv', formData, createRequestHeaders(mainUser, user, tokens))
            const result = res.body

            if (result.error) {
                dispatch(emitError('errorLoadingFile'))
                cb(null)
                return rejectWithValue(null)
            }

            cb(result.data)
        } catch (err) {
            dispatch(emitError('errorLoadingFile'))
            cb(null)
        }
    }
)

export const uploadFonts = createAsyncThunk(
    'files/uploadFonts',
    async (data: FontsUploadPayload, { getState, dispatch }) => {
        const { files, cb, supportedFontTypes } = data
        const state = getState() as { user: IUserState }
        const { data: user, mainUser, tokens } = state.user

        if (!user || !mainUser || !tokens) return

        try {
            const headers = createRequestHeaders(mainUser, user, tokens)
            dispatch(loaderActions.startLoading)

            const uploadFilesPromises = files.map((file) => {
                const formData = new FormData()
                const name = helpersFiles.getFileNameWithoutExt(file.name, supportedFontTypes)
                formData.append('file', file)
                formData.append('name', name)

                return api.post('uploadFont', formData, headers)
            })

            const responses = await Promise.all(uploadFilesPromises)

            const successResponses = responses.filter((res) => !res.body.error).map((res) => res.body.data)

            cb(successResponses)
            dispatch(loaderActions.endLoading())
        } catch (err) {
            cb([])
            dispatch(loaderActions.endLoading())
        }
    }
)

export const uploadDigitalSignageAndGroupMedia = createAsyncThunk(
    'files/uploadDigitalSignageAndGroupMedia',
    async (data: UploadMediaPayload, { getState, dispatch, rejectWithValue }) => {
        const { formData, cb } = data
        const state = getState() as { user: IUserState }
        const { data: user, mainUser, tokens } = state.user

        if (!user || !mainUser || !tokens) return

        try {
            const headers = createRequestHeaders(mainUser, user, tokens)
            await api.post('uploadDigitalSignageAndGroupMedia', formData, headers)
            cb()
        } catch (err) {
            cb()
            return rejectWithValue(null)
        }
    }
)

export const uploadVideoEditor = createAsyncThunk(
    'files/uploadVideoEditor',
    async (data: UploadVideoEditorPayload, { getState, dispatch }) => {
        const { file, onComplete, onProgress } = data
        const state = getState() as { user: IUserState }
        const { data: user, mainUser, tokens } = state.user

        if (!user || !mainUser || !tokens) return

        const headers = createRequestHeaders(mainUser, user, tokens)

        const formData = new FormData()
        // formData.append('file', file)
        file.type === 'video/x-matroska'
            ? formData.append(
                  'file',
                  new Blob([file], {
                      type: 'video/x-matroska',
                  })
              )
            : file.type === 'video/x-msvideo'
            ? formData.append(
                  'file',
                  new Blob([file], {
                      type: 'video/x-msvideo',
                  })
              )
            : file.type === 'video/quicktime'
            ? formData.append(
                  'file',
                  new Blob([file], {
                      type: 'video/quicktime',
                  })
              )
            : file.type === 'audio/ogg'
            ? formData.append(
                  'file',
                  new Blob([file], {
                      type: 'video/ogg',
                  })
              )
            : formData.append('file', file)

        api.post('uploadVideoEditor', formData, headers)
            .on('progress', (e) => {
                if (!e.percent) return

                onProgress(Math.round(e.percent))
            })
            .then((res) => {
                const result = res.body.data || null
                onComplete(result)
            })
            .catch((err) => {
                onComplete(null)
            })
    }
)

const filesSlice = createSlice({
    name: 'files',
    initialState,
    reducers: {
        openAddFileModal(state) {
            state.isOpenAddFile = true
        },
        openAiFileModal(state) {
            state.isOpenAiFile = true
        },
        closeAiFileModal(state) {
            state.isOpenAiFile = false
        },
        closeAddFileModal(state) {
            state.isOpenAddFile = false
        },
        openTagsEditModalOpen(state) {
            state.isTagsEditModalOpen = true
        },
        closeTagsEditModalOpen(state) {
            state.isTagsEditModalOpen = false
        },
        closePreview(state) {
            state.isPreviewOpen = false
        },
        openPreview(state) {
            state.isPreviewOpen = true
        },
        setFiles(state, action: PayloadAction<IFile[]>) {
            state.files = action.payload.filter((listItem) => !listItem.isDirectory)
        },
        activateAddText(state) {
            state.isAddText = true
        },
        activateAddTable(state) {
            state.isAddTable = true
        },
        activateAddLink(state) {
            state.isAddLink = true
        },
        closeAddText(state) {
            state.isAddText = false
        },
        closeAddTable(state) {
            state.isAddTable = false
        },
        closeAddLink(state) {
            state.isAddLink = false
        },
        setToolbar(state, action: PayloadAction<string>) {
            state.toolbar = action.payload
        },
        setTags(state, action: PayloadAction<TagShortModel[]>) {
            state.tags = action.payload
        },
        setFolders(state, action: PayloadAction<IFile[]>) {
            state.folders = action.payload
        },
        clearState(state, action: PayloadAction<ClearFilesState>) {
            Array.from(Object.keys(initialState)).forEach((key) => {
                const stateKey = key as never
                state[stateKey] = initialState[stateKey]
            })
        },
    },
    extraReducers: (builder) =>
        builder
            .addCase(createFilesFolder.rejected, (state, { payload }) => {})
            .addCase(getFileSourceTags.fulfilled, (state, { payload }) => {
                state.tags = payload
            })
            .addCase(updateFile.rejected, (state, { payload }) => {})
            .addCase(updateFolder.rejected, (state, { payload }) => {})
            .addCase(deleteFile.rejected, (state, { payload }) => {})
            .addCase(deleteFolder.rejected, (state, { payload }) => {})
            .addCase(rotateVideo.rejected, (state, { payload }) => {})
            .addCase(activateEditText.fulfilled, (state, { payload }) => {
                state.isAddText = true
            })
            .addCase(activateEditLink.fulfilled, (state, { payload }) => {
                state.isAddLink = true
            })
            .addCase(activateEditTable.fulfilled, (state, { payload }) => {
                state.isAddTable = true
            })
            .addCase(closeAddText.fulfilled, (state, { payload }) => {
                state.isAddText = false
            })
            .addCase(closeAddLink.fulfilled, (state, { payload }) => {
                state.isAddLink = false
            })
            .addCase(closeAddTable.fulfilled, (state, { payload }) => {
                state.isAddTable = false
            }),
})

const { reducer: filesReducer, actions: filesActions } = filesSlice
export { filesReducer, filesActions }

import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { mediaCSS } from 'blocks.app/config'
import { IStyles } from '../../../theme'

const catalogHeaderStyles: IStyles = {
    mapWrapper: {
        position: 'absolute',
        top: grid.p_md['padding'],
        left: grid.p_mdPlus['padding'],
        right: grid.p_mdPlus['padding'],
        ...grid.z2,
    },
    mapWrapperMob: {
        [mediaCSS.tab]: {
            top: grid.p_big['padding'],
        },
    },
    wrapperWithButton: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.justify,
    },
    advancedSearch: {
        marginTop: '30px',
    },
    marginLeft: {
        marginLeft: '-36px',
        [mediaCSS.tab]: {
            marginLeft: 0,
        },
    },
}

addStyles(catalogHeaderStyles)
export default catalogHeaderStyles

import React from 'react'
import { transitions } from 'blocks.app/config'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from './editText.theme'
import { noEmoji } from 'core/utils/displayUtil'
class EditTextMethods extends React.Component {
    constructor(p_) {
        super(p_)

        this.onClick = this.onClick.bind(this)
        this.onIconClick = this.onIconClick.bind(this)
        this.onChange = this.onChange.bind(this)
        this.onChangeScreen = this.onChangeScreen.bind(this)
        this.onFocus = this.onFocus.bind(this)
        this.onBlur = this.onBlur.bind(this)
        this.onWheel = this.onWheel.bind(this)
        this.timeout = null
    }
    state = {
        minValueError: false,
        maxValueError: false,
    }

    componentDidMount() {
        const p_ = this.props

        if (p_.defaultFocus) {
            this.inputFocus.focus()
        }
        if (p_.inputRef) {
            p_.inputRef(this.inputFocus)
        }
    }
    validNumberKey = ['-']
    onIconClick(e) {
        const p_ = this.props

        if (p_.error) {
            if (p_.type === 'date') {
                p_.onChange(null)
            } else {
                p_.onChange('')
                this.inputFocus.focus()
            }
        } else if (p_.type === 'date' && !p_.onIconClick) {
            this.datePicker.setOpen(true)
        } else if (p_.onIconClick) {
            p_.onIconClick(e, this.inputFocus)
        }
    }
    onClick(e) {
        const p_ = this.props
        if (p_.onClick) {
            if (p_.type === 'date') {
                p_.onClick(e)
            } else {
                p_.onClick(e.target.value)
            }
        }
    }
    onChangeScreen(e) {
        const p_ = this.props
        const value = e && e.target ? e.target.value : null
        if (p_.onChange) {
            if (p_.type === 'date') {
                p_.onChange(e)
            } else {
                if (value > 9 && value <= p_.max) {
                    this.setState({ maxValueError: false })
                    this.setState({ minValueError: false })
                    p_.onChange(value, e, p_.max)
                } else {
                    this.setState({ maxValueError: true })
                    this.setState({ minValueError: true })
                    p_.onChange(value, e, false)
                }
            }
        }
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
            p_.onEndTyping(value)
        }, transitions.normal * 2)
    }
    onChange(e) {
        const p_ = this.props
        const value = e && e.target ? e.target.value : null
        if (p_.onChange) {
            if (p_.type === 'date') {
                p_.onChange(e)
            } else {
                p_.onChange(!p_.enableEmoji ? noEmoji(value) : value, e)
            }
        }
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
            p_.onEndTyping(value)
        }, transitions.normal * 2)
    }
    onFocus(e) {
        const p_ = this.props
        if (p_.onFocus) {
            if (p_.type === 'date') {
                p_.onFocus(e)
            } else {
                p_.onFocus(e.target.value)
            }
        }
    }
    onBlur(e) {
        const p_ = this.props
        if (p_.onBlur) {
            if (p_.type === 'date') {
                p_.onBlur(e)
            } else {
                p_.onBlur(e.target.value, e)
            }
        }
    }
    onWheel(e) {
        if (this.inputFocus !== document.activeElement) {
            return
        }
        e.preventDefault()

        if (e.deltaY < 0) {
            e.target.value--
            this.onChange(e)

            return
        }
        if (e.deltaY > 0) {
            e.target.value++
            this.onChange(e)

            return
        }
    }
    getIconColor(iconColor, mod) {
        if (iconColor) {
            return iconColor
        } else {
            return getThemeStyleValue('ui', 'editText', `${mod}-icon`) || defaultThemeStyles[`${mod}-icon`]
        }
    }
    preventMinus = (e) => {
        if (this.validNumberKey.includes(e.key)) {
            e.preventDefault()
        }
    }
}

export default EditTextMethods

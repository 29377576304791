import React from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { Content } from 'organisms/Content'
import Catalog from 'organisms/Catalog'
import { ProfileDialog } from 'organisms/ProfileDialog'
import { Dialog } from 'molecules/Dialog'
import Sidebar from 'blocks.app/sidebar/sidebar'
import Icon from 'blocks.simple/icon/icon'
import { useModal, useSelected } from 'core/hooks'
import { CircleButton } from 'atoms/CircleButton'
import helpers from 'core/helpers'
import translate from 'core/translate'
import styles from './roles/styles'
import { api } from 'core/api/ConnectionManager'
import { userActions } from 'blocks.app/user/user.state'
import { getSelectedItemsId, isActiveDirectory } from 'core/helpers/user'
import { checkAvailableModule } from '../../core/helpers/routes/routes'
import { cn } from 'ethcss'
import { colors, grid } from '../../theme'
import { isHorizontalMenu } from 'core/helpers/menu'
import Button from 'blocks.simple/button/button'
import { IAppState } from '../../blocks.app/app/app.state'
import { IUser } from '../../core/models/Users'

const Users = () => {
    const dispatch = useDispatch()
    const horizontalMenu = isHorizontalMenu()
    const state: any = useSelector<RootStateOrAny>((state) => state)
    const user = state.user
    const app: IAppState = state.app
    const userId = user.data.id
    const _selectedInfo = user.selectedInfo
    const isOpenProfileModal = user.isOpenProfileModal
    const _isActiveDirectory = user.isActiveDirectory
    const showMobToolbar = app.showMobToolbar

    const { selectedInfo, selectedItems, onSetSelectedInfo, onSetSelectedItems } = useSelected()
    const agreeForDeleteModal = useModal()

    const handleOnEditUser = (selectedInfo: IUser) => {
        if (helpers.isAvailable({ key: 'user', action: 'update' }) && selectedInfo) {
            dispatch(
                userActions.openUserModal({
                    user: selectedInfo,
                    isActiveDirectory: isActiveDirectory(selectedInfo.authType),
                })
            )
        }
    }

    const onCloseProfileModal = () => {
        dispatch(userActions.closeUserModal())
    }

    const onAccept = () => {
        const id = getSelectedItemsId({ selectedInfo, selectedItems })

        if (id) {
            api.send('deleteUser', { id })
        }

        agreeForDeleteModal.closeModal()
    }

    const onReject = () => {
        agreeForDeleteModal.closeModal()
    }

    const onCloseInfo = () => {
        if (selectedItems && selectedItems.length) {
            onSetSelectedItems([])

            return
        }

        onSetSelectedInfo(null)
    }

    const userList = (selectedItems: any) => {
        return (
            <div className={styles.listItemBlock}>
                {selectedItems.map((item: IUser) => {
                    return (
                        <div
                            key={item.id}
                            className={cn(grid.p_md, grid.rowCenter, grid.noWrap, styles.sidebarTopListItemWrapper)}
                        >
                            <div className={grid.pr_mini}>
                                <Icon size={'19'} type={'users'} color={colors.blackLight} />
                            </div>
                            <div className={styles.sidebarTopListItem}>
                                <div className={item.ellipsis}>{item.name}</div>
                            </div>
                            <div className={grid.pl_nano}>
                                <CircleButton
                                    onClick={() => {
                                        onSetSelectedItems(
                                            selectedItems.filter((deleteElement: IUser) => deleteElement.id !== item.id)
                                        )
                                    }}
                                    type={'delete'}
                                />
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    const renderToolbarContent = (selectedInfo: any) => {
        if (!selectedInfo && selectedItems && !selectedItems.length) return null

        return (
            <div className={styles.toolbarInner}>
                <Icon size={'23'} type={'info'} color={colors.blackLight} />

                {selectedItems && selectedItems.length ? (
                    <>
                        {userList(selectedItems)}
                        <div
                            onClick={() => {
                                agreeForDeleteModal.openModal()
                            }}
                            className={cn(styles.menuItem, styles.divider)}
                        >
                            {translate('delete')}
                        </div>
                        <div className={styles.space} />
                    </>
                ) : (
                    <>
                        <div className={styles.toolbarUserName}>{selectedInfo.name}</div>
                        <div className={styles.toolbarUserAvatar}>
                            {!selectedInfo.photo ? (
                                <Icon type="users" color={'white'} size="105" containerClass={styles.noUserPhoto} />
                            ) : (
                                <div
                                    className={styles.toolbarPhotoWrapper}
                                    style={{ backgroundImage: `url(${selectedInfo.photo})`, backgroundSize: 'cover' }}
                                />
                            )}
                        </div>
                        <div onClick={() => handleOnEditUser(selectedInfo)} className={styles.menuItem}>
                            {translate('edit')}
                        </div>
                        <div
                            onClick={() => {
                                agreeForDeleteModal.openModal()
                            }}
                            className={styles.menuItem}
                        >
                            {translate('delete')}
                        </div>
                    </>
                )}
                <div className={cn(grid.p_md, grid.rowCenter, horizontalMenu ? styles.closeBtn_x : styles.closeBtn)}>
                    <Button mod={'withBorder'} onClick={onCloseInfo} indentSize={'mini'} rounded={'full_normal'}>
                        {translate('close')}
                    </Button>
                </div>
            </div>
        )
    }

    return (
        <Content title={translate('users')} withToolbar contentClassName={styles.contentStyles}>
            <Catalog
                type="users"
                onSelectInfo={onSetSelectedInfo}
                selectedInfo={selectedInfo}
                selectedItems={selectedItems}
                onDoubleClickObject={handleOnEditUser}
                onSelect={onSetSelectedItems}
                disableMultiSelect={(user: IUser) => user.id === userId}
                treeView={false}
                classNameContent={styles.catalogStyles}
                showMobToolbar={true}
                contentClassName={styles.contentStyles}
            />
            <div
                className={cn(
                    styles.toolbar,
                    selectedInfo ? styles.showToolbar : '',
                    horizontalMenu && styles.toolbarMargin
                )}
            >
                <Sidebar
                    selectedSide={'right'}
                    customContent={{
                        right: renderToolbarContent(selectedInfo),
                    }}
                    onSelectInfo={onSetSelectedInfo}
                    selectedInfo={selectedInfo}
                    isEmpty={{
                        right: !selectedInfo,
                    }}
                    emptyText={{
                        right:
                            selectedItems && !selectedItems.length
                                ? {
                                      title: translate('noSelectedUsers'),
                                      description: translate('selectUserForAction'),
                                  }
                                : null,
                    }}
                    side={'right'}
                    isShowActions={true}
                    showAlwaysCustomContent={true}
                />
            </div>
            <ProfileDialog
                open={isOpenProfileModal}
                user={_selectedInfo}
                onClose={onCloseProfileModal}
                isActiveDirectory={_isActiveDirectory}
            />
            <Dialog
                open={agreeForDeleteModal.value}
                onReject={onReject}
                onAccept={onAccept}
                rejectLabel={translate('cancel')}
                acceptLabel={translate('delete')}
            >
                <div className={styles.rolesAcceptDialogText}>{`${translate('doYouWantToDeleteUser')}?`}</div>
            </Dialog>
        </Content>
    )
}

const exportUsers = checkAvailableModule('users') ? Users : null

export default exportUsers

import PropTypes, { string } from 'prop-types'
import React, { memo, useEffect, useRef, useState } from 'react'
import Rectangle from 'blocks.simple/rectangle/rectangle'
import EditorListItem from '../__listItem/editor__listItem'
import Tippy from 'blocks.simple/tippy/tippy'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import Draggable from 'blocks.simple/draggable/draggable'
import Icon from 'blocks.simple/icon/icon'
import Animation from 'blocks.simple/animation/animation'
import DndDroppable from 'blocks.app/dnd/_droppable/dnd_droppable'
import EditorSoundtrackSettings from '../__soundtrackSettings/editor__soundtrackSettings'
import { cn } from 'ethcss'
import styles, { iconSize } from './editor__footer.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { text as textStyles } from 'theme'
import helpers from 'core/helpers'
import { editor } from '../editor.local.methods'
import translate from 'core/translate'
import { isExist } from 'core/utils/coreUtil'
import LoaderLazy from 'blocks.app/loader/_lazy/loader__lazy'
import { List } from 'react-virtualized'
import { withWindowResize } from 'core/hocs'
import CatalogSelectContent from '../../catalog/_select/_content/catalog_select_content'
import Button from '../../../blocks.simple/button/button'

const step = 100
const SORTABLE_ITEM_HEIGHT = 60

const SimpleSortableList = ({
    children,
    selected,
    selectedContent,
    selectedArea,
    areaViewType,
    inPageContentId,
    onChangeContent,
    selectContentOpen,
    onCloseSelection,
}) => {
    const clonedItems = React.useMemo(() => {
        return React.Children.map(children, (child, index) => ({
            id: index,
            content: child,
        }))
    }, [children])

    const [items, setItems] = useState(clonedItems)
    const [oldIndex, setOldIndex] = useState(0)

    useEffect(() => {
        setItems(
            React.Children.map(children, (child, index) => ({
                id: index,
                content: child,
            }))
        )
    }, [selected, selectedContent, areaViewType])

    const [draggingIndex, setDraggingIndex] = useState(-1)
    const dragNode = React.useRef()

    const handleDragStart = (e, index) => {
        setOldIndex(index)
        const { target } = e
        setDraggingIndex(index)

        dragNode.current = target
        e.dataTransfer.effectAllowed = 'move'
        e.dataTransfer.setData('text/html', target)
    }

    const handleDragOver = (e, index) => {
        e.preventDefault()
        if (dragNode.current !== e.target) {
            let newItems = [...items]
            newItems.splice(index, 0, newItems.splice(draggingIndex, 1)[0])
            setDraggingIndex(index)
            setItems(newItems)
        }
    }

    const handleDragEnd = (e, index) => {
        e.preventDefault()
        editor.sortContent(oldIndex, index, selectedArea)
    }

    return (
        <>
            <div>
                {items.map((item, index) => (
                    <div
                        key={item.id}
                        draggable="true"
                        onDragStart={(e) => handleDragStart(e, index)}
                        onDragOver={(e) => handleDragOver(e, index)}
                        onDragEnd={(e) => handleDragEnd(e, index)}
                    >
                        {item.content}
                    </div>
                ))}
            </div>
            {inPageContentId && (
                <CatalogSelectContent
                    type="all"
                    onSelect={onChangeContent}
                    onClose={onCloseSelection}
                    selectCatalogContentRef={selectContentOpen}
                    isHideButtonAndPreview={true}
                    isHideSidebar={true}
                    disableAutoselect={true}
                />
            )}
        </>
    )
}

const SortableItem = SortableElement((p_) => {
    return <EditorListItem {...p_} />
})

const SortableVirtualizedListComponent = memo((p_) => {
    if (!p_.content.length) {
        return null
    }

    const _getPageContentIdByContent = (content) => {
        const AREA_FIRST_INDEX = -1
        let id = undefined

        for (let i = AREA_FIRST_INDEX; i < p_.areas.length; i++) {
            const area = p_.areas[i]
            if (area && area.content && area.content.length) {
                const contentWithListId = _findContentInArea(content, area)
                if (contentWithListId) {
                    id = contentWithListId.inPageContentId
                    break
                }
            }
        }
        return id
    }

    const _findContentInArea = (content, area) => {
        return area.content.find((areaContent) => areaContent.startTime === content.startTime)
    }

    const renderRow = (props) => {
        const { key, index, style } = props
        const contentItem = p_.content[index]
        const fileItem = p_.sources[contentItem.sourceId]
        const inPageContentId = _getPageContentIdByContent(contentItem)

        return (
            <SortableItem
                key={key}
                virtualScrollStyle={style}
                index={index}
                contentIndex={index}
                selectedArea={p_.currentArea}
                areaViewType={p_.areaViewType}
                showAllAreas={p_.showAllAreas}
                duration={p_.duration}
                fileItem={fileItem}
                contentItem={contentItem}
                inPageContentId={inPageContentId}
                selected={p_.currentArea === p_.selectedArea && p_.selectedContent === index}
                selectedContent={p_.selected}
                minimized={p_.minimized}
                onDelete={p_.onDelete}
                onEdit={p_.onEdit}
                isSorting={p_.isSorting}
                autoPos={p_.autoPos}
                deleteByContentId={p_.deleteByContentId}
                hideSortHandle={p_.hideSortHandle}
                isMobile={p_.isMobile}
                isSoundtrack={p_.isSoundtrack}
            />
        )
    }

    return (
        <div className={cn(styles.itemsWrapper_type_autoSize, { [item.white]: p_.areaViewType === 'list' })}>
            <List
                width={p_.listWidth}
                height={p_.listHeight}
                rowHeight={p_.rowHeight}
                rowRenderer={renderRow}
                rowCount={p_.content.length}
            />
        </div>
    )
})

const SortableListComponent = memo((p_) => {
    const [inPageContentId, setInPageContentId] = useState(null)

    if (!p_.content.length) {
        return null
    }

    const _getPageContentIdByContent = (content) => {
        const AREA_FIRST_INDEX = -1
        let id = undefined

        for (let i = AREA_FIRST_INDEX; i < p_.areas.length; i++) {
            const area = p_.areas[i]
            if (area && area.content && area.content.length) {
                const contentWithListId = _findContentInArea(content, area)
                if (contentWithListId) {
                    id = contentWithListId.inPageContentId
                    break
                }
            }
        }
        return id
    }

    const _findContentInArea = (content, area) => {
        return area.content.find(
            (areaContent) => areaContent.startTime === content.startTime && areaContent.sourceId === content.sourceId
        )
    }

    const onSetChangeContent = (inPageContentId) => {
        setInPageContentId(inPageContentId)
    }

    const selectContentOpen = (data) => {
        if (data && data.openModal) {
            data.openModal()
        }
    }

    const onChangeContent = (source) => {
        if (source && inPageContentId) {
            p_.onChange(inPageContentId, source)
            onCloseSelection()
        }
    }

    const onCloseSelection = () => {
        setInPageContentId(null)
    }

    return (
        <div className={cn(styles.itemsWrapper, { [item.white]: p_.areaViewType === 'list' })}>
            {p_.content.map((contentItem, index) => {
                const fileItem = p_.sources[contentItem.sourceId]
                const inPageContentId = _getPageContentIdByContent(contentItem)

                return (
                    <SortableItem
                        isMobile={p_.isMobile}
                        key={`ListItem_${index}`}
                        index={index}
                        contentIndex={index}
                        selectedArea={p_.currentArea}
                        areaViewType={p_.areaViewType}
                        showAllAreas={p_.showAllAreas}
                        duration={p_.duration}
                        fileItem={fileItem}
                        contentItem={contentItem}
                        inPageContentId={inPageContentId}
                        selected={p_.currentArea === p_.selectedArea && p_.selectedContent === index}
                        selectedContent={p_.selected}
                        minimized={p_.minimized}
                        onDelete={p_.onDelete}
                        onChange={onSetChangeContent}
                        onEdit={p_.onEdit}
                        isSorting={p_.isSorting}
                        autoPos={p_.autoPos}
                    />
                )
            })}
            {inPageContentId && (
                <CatalogSelectContent
                    type="all"
                    onSelect={onChangeContent}
                    onClose={onCloseSelection}
                    selectCatalogContentRef={selectContentOpen}
                    isHideButtonAndPreview={true}
                    isHideSidebar={true}
                    disableAutoselect={true}
                />
            )}
        </div>
    )
})

const SortableList = SortableContainer(SortableListComponent)
const SortableVirtualizedList = SortableContainer(SortableVirtualizedListComponent)

// Подсчёт количества шагов в периоде, при режиме зацикливания
const getLoopSteps = (p_) => {
    if (!p_.loop) {
        return []
    }
    const content = editor.getContent()

    if (!content) {
        return []
    }

    let maxRightBorder = 0
    content.forEach((contentItem) => {
        const rightBorder = contentItem.startTime + contentItem.duration

        if (rightBorder > maxRightBorder) {
            maxRightBorder = rightBorder
        }
    })

    return helpers.calcStepsByDuration(p_.duration, maxRightBorder)
}

export let editorFooter = {}

export class EditorFooter extends React.Component {
    constructor(p_) {
        super(p_)

        const duration = editor.msDurationToS(p_.duration)
        this.duration = duration
        this.state = {
            steps: helpers.calcScaleSteps(duration),
            loopSteps: getLoopSteps(p_),
            duration,
            relTime: 0,
            time: 0,
            playing: false,
            dragTime: false,
            hoverTime: false,
            dropWrapperActive: false,
            isSoundtrackSettingsOpen: false,
            searchSoundtracksByTagName: '',
            inPageContentId: null,
        }
        this.isSorting = false

        editorFooter = this
    }
    axisByViewType = {
        x: 'x',
        y: 'y',
        list: 'y',
    }

    static getDerivedStateFromProps(p_) {
        return {
            loopSteps: getLoopSteps(p_),
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    componentDidUpdate(prevProps) {
        const p_ = this.props

        this.soundtrackSettingsModalStatus(p_)

        if (p_.duration !== prevProps.duration) {
            const duration = editor.msDurationToS(p_.duration)
            this.duration = duration

            // Подсчёт количества шагов
            this.setState({
                steps: helpers.calcScaleSteps(duration),
                duration,
                loopSteps: getLoopSteps(p_),
            })
        }
    }

    getContent(selectedArea, isSoundtrackSettings) {
        const s_ = this.state
        const { areas } = this.props
        const area = areas[selectedArea]

        if (!area) return

        const content = []

        area.content.forEach((contentItem) => {
            if (isSoundtrackSettings) {
                const tagValue = s_.searchSoundtracksByTagName

                if (tagValue) {
                    if (contentItem.source.tags.some((tag) => tag.name.includes(tagValue))) {
                        content.push({
                            sourceId: contentItem.sourceId,
                            duration: contentItem.duration,
                            startTime: contentItem.startTime,
                            leftIndent: contentItem.leftIndent,
                            rightIndent: contentItem.rightIndent,
                        })
                    }

                    return
                }

                content.push({
                    sourceId: contentItem.sourceId,
                    duration: contentItem.duration,
                    startTime: contentItem.startTime,
                    leftIndent: contentItem.leftIndent,
                    rightIndent: contentItem.rightIndent,
                })
            } else {
                content.push({
                    sourceId: contentItem.sourceId,
                    duration: contentItem.duration,
                    startTime: contentItem.startTime,
                    leftIndent: contentItem.leftIndent,
                    rightIndent: contentItem.rightIndent,
                })
            }
        })

        return content
    }

    onChangeTagName = (value) => {
        this.setState({ searchSoundtracksByTagName: value })
    }

    // Получить вертикальное отображение таймлинии (упрощенная версия)
    getSimpleSortableList = (options = {}) => {
        const p_ = this.props
        const s_ = this.state
        const axis = this.axisByViewType[options.areaViewType]

        const handleSortStart = () => {
            this.isSorting = true
        }

        const handleSortEnd = ({ oldIndex, newIndex }) => {
            this.isSorting = false
            editor.sortContent(oldIndex, newIndex, options.currentArea)
        }

        const content = this.getContent(options.currentArea)

        const _getPageContentIdByContent = (content) => {
            const AREA_FIRST_INDEX = -1
            let id = undefined

            for (let i = AREA_FIRST_INDEX; i < p_.areas.length; i++) {
                const area = p_.areas[i]
                if (area && area.content && area.content.length) {
                    const contentWithListId = _findContentInArea(content, area)
                    if (contentWithListId) {
                        id = contentWithListId.inPageContentId
                        break
                    }
                }
            }
            return id
        }

        const _findContentInArea = (content, area) => {
            return area.content.find((areaContent) => areaContent.startTime === content.startTime)
        }

        const onSetChangeContent = (_inPageContentId) => {
            this.setState({ inPageContentId: _inPageContentId })
        }

        const selectContentOpen = (data) => {
            if (data && data.openModal) {
                data.openModal()
            }
        }

        const onChangeContent = (source) => {
            if (source && s_.inPageContentId) {
                p_.onChange(s_.inPageContentId, source)
                onCloseSelection()
            }
        }

        const onCloseSelection = () => {
            this.setState({ inPageContentId: null })
        }

        return (
            <SimpleSortableList
                selected={p_.selected}
                selectedContent={p_.selectedContent}
                selectedArea={p_.selectedArea}
                areaViewType={p_.areaViewType}
                selectContentOpen={selectContentOpen}
                onChangeContent={onChangeContent}
                inPageContentId={s_.inPageContentId}
            >
                {content.map((contentItem, index) => {
                    const inPageContentId = _getPageContentIdByContent(contentItem)

                    return (
                        <EditorListItem
                            {...p_}
                            contentItem={contentItem}
                            isSorting={this.isSorting}
                            useDragHandle={true}
                            axis={axis}
                            lockAxis={axis}
                            onSortStart={handleSortStart}
                            onSortEnd={handleSortEnd}
                            helperClass={cn(styles.active)}
                            fileItem={p_.sources[contentItem.sourceId]}
                            isMobile={p_.isMobile}
                            key={`ListItem_${index}`}
                            index={index}
                            contentIndex={index}
                            areaViewType={p_.areaViewType}
                            showAllAreas={p_.showAllAreas}
                            duration={p_.duration}
                            inPageContentId={inPageContentId}
                            selected={p_.selectedContent === index}
                            selectedContent={p_.selected}
                            minimized={p_.minimized}
                            onDelete={p_.onDelete}
                            onEdit={p_.onEdit}
                            autoPos={p_.autoPos}
                            onChange={onSetChangeContent}
                            {...options}
                        />
                    )
                })}
            </SimpleSortableList>
        )
    }

    // Получить вертикальное отображение таймлинии
    getSortableList = (options = {}) => {
        const p_ = this.props
        const axis = this.axisByViewType[options.areaViewType]

        const handleSortStart = () => {
            this.isSorting = true
        }

        const handleSortEnd = ({ oldIndex, newIndex }) => {
            this.isSorting = false
            editor.sortContent(oldIndex, newIndex, options.currentArea)
        }

        return (
            <SortableList
                {...p_}
                isSorting={this.isSorting}
                useDragHandle={true}
                content={this.getContent(options.currentArea)}
                axis={axis}
                lockAxis={axis}
                onSortStart={handleSortStart}
                onSortEnd={handleSortEnd}
                helperClass={cn(styles.active)}
                {...options}
            />
        )
    }

    soundtrackSettingsModalStatus = (p_) => {
        const s_ = this.state

        if (s_.isSoundtrackSettingsOpen && !p_.areas[-1].content.length) {
            this.setState({ isSoundtrackSettingsOpen: false })
        }
    }

    getListView() {
        const p_ = this.props
        const HEADER_HEIGHT = 92
        const CONTENT_WIDTH_PERCENTAGE = 0.7
        const soundtrackListWidth = Math.floor(p_.windowWidth * CONTENT_WIDTH_PERCENTAGE)
        const soundtrackListHeight = Math.floor(p_.windowHeight - HEADER_HEIGHT)

        return (
            <SortableVirtualizedList
                {...p_}
                isSorting={this.isSorting}
                useDragHandle={true}
                content={this.getContent(p_.selectedArea)}
                axis={this.axisByViewType[p_.areaViewType]}
                lockAxis={this.axisByViewType[p_.areaViewType]}
                onSortStart={this.handleListSortStart}
                onSortEnd={this.handleListSortEnd}
                helperClass={cn(styles.active)}
                autoPos={p_.areas[p_.selectedArea].autoPos}
                areaViewType={p_.areaViewType}
                currentArea={p_.selectedArea}
                listHeight={soundtrackListHeight}
                listWidth={soundtrackListWidth}
                rowHeight={SORTABLE_ITEM_HEIGHT}
            />
        )
    }

    deleteByContentId = (contentId) => {
        editor.onDeleteByContentId(contentId)
    }

    getSoundtrackSettings() {
        const s_ = this.state
        const p_ = this.props
        const HEADER_HEIGHT = 92
        const CONTENT_WIDTH_PERCENTAGE = p_.isMobile ? 1 : 0.7
        const soundtrackListWidth = Math.floor(p_.windowWidth * CONTENT_WIDTH_PERCENTAGE)
        const soundtrackListHeight = Math.floor(p_.windowHeight - HEADER_HEIGHT)

        return (
            <div onMouseDown={this.handleMouseEvent} className={styles.audioWrapper}>
                <EditorSoundtrackSettings
                    open={s_.isSoundtrackSettingsOpen}
                    onClose={this.handleCloseSoundtrackSettings}
                    selected={p_.selected}
                    selectedContent={p_.selectedContent}
                    content={p_.areas[-1].content}
                    isSimple={p_.isSimple}
                    soundOrderMode={p_.soundOrderMode}
                    onSetSoundOrderMode={editor.setSoundOrderMode}
                    changeTagName={this.onChangeTagName}
                    tagNameValue={s_.searchSoundtracksByTagName}
                    isMobile={p_.isMobile}
                >
                    {
                        <SortableVirtualizedList
                            {...p_}
                            isSorting={this.isSorting}
                            useDragHandle={true}
                            content={this.getContent(-1, true)}
                            axis={this.axisByViewType['list']}
                            lockAxis={this.axisByViewType['list']}
                            onSortStart={this.handleSoundtrackSettingsSortStart}
                            onSortEnd={this.handleSoundtrackSettingsSortEnd}
                            helperClass={cn(styles.active)}
                            areaViewType={'list'}
                            currentArea={-1}
                            listHeight={soundtrackListHeight}
                            listWidth={soundtrackListWidth}
                            rowHeight={p_.isMobile ? 40 : SORTABLE_ITEM_HEIGHT}
                            tagNameValue={s_.searchSoundtracksByTagName}
                            deleteByContentId={this.deleteByContentId}
                            hideSortHandle={s_.searchSoundtracksByTagName.length}
                            isMobile={p_.isMobile}
                            isSoundtrack={true}
                        />
                    }
                </EditorSoundtrackSettings>
            </div>
        )
    }

    getSoundtrackRow = () => {
        const s_ = this.state

        return (
            <div onMouseDown={this.handleMouseEvent} className={styles.audioWrapper}>
                <Rectangle width="15" height="1" className={styles.soundtrackContentMobile}>
                    <DndDroppable
                        className={cn(styles.dropWrapper, { [styles.dropWrapperActive]: s_.dropWrapperActive })}
                        onDrop={this.onDrop}
                        onDragOver={this.onDragOver}
                        onDragLeave={this.onDragLeave}
                    >
                        {this.getSountrackSortableRow()}
                    </DndDroppable>
                </Rectangle>
            </div>
        )
    }

    getSoundtrackDurationAndSettings = () => {
        const content = editor.getContent(-1)
        const duration = content.reduce((acc, item) => {
            acc += item.duration
            return acc
        }, 0)

        return (
            <div className={cn(grid.w100, grid.rowCenter, textStyles.mini)}>
                <div className={(grid.rowCenter, textStyles.white)}>{helpers.msToHMS(duration)}</div>
                <Icon
                    containerClass={cn(grid.p_micro, styles.soundtrackSettingsMobile)}
                    type="audio_settings"
                    color="white"
                    tooltip={{
                        html: translate('openSoundtrackSettings'),
                    }}
                    disabled={!content.length}
                    onClick={() => {
                        editor.resetSelectedSources()
                        editor.selectContent(-1, 0)
                        this.setState({ isSoundtrackSettingsOpen: true })
                    }}
                />
            </div>
        )
    }

    getSoundtrackRowContent = () => {
        const { areas, isMobile } = this.props
        const soundtrackContentSize = areas[-1].content.length
        const SOUNDTRACK_MAX_SIZE_SHOW = 50

        if (soundtrackContentSize === 0) {
            return (
                <div className={styles.emptySoundtrack}>
                    {translate(isMobile ? 'tapAudioFileForAddIntoSoundtrack' : 'dropAudioFileForAddIntoSoundtrack')}
                    {isMobile && (
                        <>
                            <div> </div>
                            <Icon type="addSoundTrack" color="white" size={17} />
                        </>
                    )}
                </div>
            )
        }

        if (soundtrackContentSize > 0 && soundtrackContentSize < SOUNDTRACK_MAX_SIZE_SHOW) {
            return (
                <React.Fragment>
                    {this.getSortableList({
                        areaViewType: 'x',
                        currentArea: -1,
                    })}
                </React.Fragment>
            )
        }

        return (
            <div className={styles.emptySoundtrack}>
                {translate('soundtrackListMaxSize')} {soundtrackContentSize}
            </div>
        )
    }

    getSountrackSortableRow = () => {
        const { isSoundtrackLoading } = this.props

        return (
            <Rectangle width="15" height="1" key={`sortableRow_${-1}`}>
                <div
                    className={cn(
                        styles.xWrapper,
                        styles.xWrapperMobile,
                        editor.state.data.selectedArea === -1 ? styles.highLight : ''
                    )}
                    onClick={() => editor.selectArea(-1, false)}
                >
                    <div className={cn(grid.w10, grid.rowCenter, item.pointer, styles.soundtrackMobile)}>
                        {this.getSoundtrackDurationAndSettings()}
                    </div>
                    <div className={styles.time} style={{ width: '90%' }}>
                        {isSoundtrackLoading ? (
                            <div className={styles.emptySoundtrack}>
                                <LoaderLazy key="editor__footer_audio_loader" active={isSoundtrackLoading} />
                            </div>
                        ) : (
                            this.getSoundtrackRowContent()
                        )}
                    </div>
                </div>
            </Rectangle>
        )
    }

    getSortableRow = (area, options = {}) => {
        const p_ = this.props

        return (
            <Rectangle width="15" height="1" key={`sortableRow_${options.currentArea}`}>
                <div className={styles.xWrapper}>
                    <div
                        className={cn(grid.w5, grid.rowCenter, item.pointer, styles.areaCountMobile)}
                        onClick={() => editor.selectArea(options.currentArea, true)}
                    >
                        <div className={cn(grid.spaceWidth, grid.rowCenter)}>
                            <span className={item.ellipsis}>{options.currentArea + 1}.</span>
                        </div>
                        {!area.xViewAvailable && (
                            <Icon
                                type={'attention'}
                                size={14}
                                color={'white'}
                                tooltip={{
                                    title: translate('xViewNotAvailable'),
                                }}
                            />
                        )}
                    </div>
                    <div className={styles.time}>
                        {p_.isSoundtrackLoading && (
                            <div className={styles.emptySoundtrack}>
                                <LoaderLazy key="editor__footer_audio_loader" active={p_.isSoundtrackLoading} />
                            </div>
                        )}
                        {this.getSortableList({
                            autoPos: area.autoPos,
                            areaViewType: options.areaViewType,
                            currentArea: options.currentArea,
                        })}
                        {!area.xViewAvailable && (
                            <Tippy className={cn(styles.disabled)} title={translate('xViewNotAvailable')} />
                        )}
                    </div>
                </div>
            </Rectangle>
        )
    }

    // Подсчёт относительной продолжительности страницы
    setRelTime = (relTime, save = false) => {
        const s_ = this.state
        const p_ = this.props
        const time = Math.round(relTime * p_.duration)

        this.setState({ relTime, time })
        if (save) {
            this.setState({ dragTime: false })
            editor.screen.setTime(time, s_.playing, true)
        }

        return true
    }

    isDynamicDurationContentExist = (areas) => {
        let isDynamicDurationContentExist = false
        if (!isExist(areas)) return isDynamicDurationContentExist

        areas
            .filter((area) => isExist(area))
            .filter((area) => isExist(area.content) && area.content.length > 0)
            .forEach((area) => {
                area.content
                    .filter((content) => isExist(content))
                    .filter((content) => isExist(content.source) && content.source.fileType === 'web_app')
                    .filter((content) => isExist(content.source.data) && isExist(content.source.data.widgetInfo))
                    .forEach((content) => {
                        if (content.source.data.widgetInfo.dynamicDurationQ) {
                            isDynamicDurationContentExist = true
                        }
                    })
            })

        return isDynamicDurationContentExist
    }

    // Проигрывание всей трансляции
    play = () => {
        const s_ = this.state

        if (s_.playing) {
            this.stop()
            return
        }
        this.interval = setInterval(() => {
            const time = this.state.time + step

            if (time > this.props.duration) {
                //незацикленный режим
                // this.stop(this.props.duration)
                //зацикленный режим
                this.setState({ time: 0 })
                editor.screen.setTime({ time: 0 })
                return
            }
            this.setState({
                time,
                relTime: time / this.props.duration,
            })
            editor.screen.setTime(time)
        }, step)
        const time = this.state.time === this.props.duration ? 0 : this.state.time
        const playing = true
        this.setState({
            time,
            relTime: time / this.props.duration,
            playing,
        })
        editor.screen.setTime(time, playing)
    }

    // Остановить проигрывание
    stop = (initTime) => {
        clearInterval(this.interval)
        let time = initTime

        if (time === undefined) {
            time = this.state.time
        }
        const playing = false
        this.setState({ time, playing })
        editor.screen.setTime(time, playing)
    }

    // Добавление файлов в саундтрек
    onDrop = (data, e) => {
        const { emitError } = this.props
        e.stopPropagation()

        const file = JSON.parse(data.dnd_item)

        this.setState((state) => ({
            ...state,
            dropWrapperActive: false,
        }))

        if (file.isDirectory) {
            editor.onDropFolderToSoundtrack(file)
            return
        }

        if (file.fileType === 'audio') {
            editor.dropSoundtrackAudio(file)
        } else {
            emitError('onlyAudioError')
        }
    }

    // Подсвечивание флага при наведении файла
    onDragOver = (e) => {
        const s_ = this.state

        if (!s_.dropWrapperActive) {
            this.setState({ dropWrapperActive: true })
        }
    }

    // Скрывание подсвечивания флага при ведение draggable файла за границы
    onDragLeave = (e) => {
        const s_ = this.state

        if (s_.dropWrapperActive) {
            this.setState({ dropWrapperActive: false })
        }
    }

    handleSoundtrackSettingsSortStart = () => {
        this.isSorting = true
    }

    handleSoundtrackSettingsSortEnd = ({ oldIndex, newIndex }) => {
        this.isSorting = false
        editor.sortContent(oldIndex, newIndex, -1)
    }

    handleListSortStart = () => {
        this.isSorting = true
    }

    handleListSortEnd = ({ oldIndex, newIndex }) => {
        const p_ = this.props
        this.isSorting = false
        editor.sortContent(oldIndex, newIndex, p_.selectArea)
    }

    handleCloseSoundtrackSettings = () => {
        editor.resetSelectedSources()
        this.setState({ isSoundtrackSettingsOpen: false })
    }

    handleMouseEvent = (e) => {
        e.stopPropagation()
    }

    render() {
        const s_ = this.state
        const p_ = this.props
        const draggableOptions = {
            left: s_.relTime,
            top: 0,
            height: 1,
            width: 0,
            disableResize: true,
            noBorder: true,
            zIndex: 100,
            onSelect: () => this.setState({ dragTime: true }),
            onDrag: (pos) => this.setRelTime(pos.left, false),
            onDragEnd: (pos) => this.setRelTime(pos.left, true),
        }
        const isListView = p_.areaViewType === 'list'
        const isYView = p_.areaViewType === 'y'
        const isXView = p_.areaViewType === 'x'
        const isDynamicDurationContentExist = this.isDynamicDurationContentExist(p_.areas)
        const contentInArea = p_.areas[p_.selectedArea] ? p_.areas[p_.selectedArea].content : []
        const sortableMethodName =
            contentInArea.length > 40 && p_.areaViewType !== 'y' ? 'getSimpleSortableList' : 'getSortableList'
        const isSelectedAllContent = contentInArea.length === p_.selected.length

        if (helpers.isMobileDevice()) {
            draggableOptions.isDraggable = true
            draggableOptions.target = 'timeWrapper'
        }

        return (
            <React.Fragment>
                {s_.isSoundtrackSettingsOpen ? (
                    this.getSoundtrackSettings()
                ) : (
                    <React.Fragment>
                        {this.getSoundtrackRow()}
                        <div
                            onMouseDown={this.handleMouseEvent}
                            className={cn(styles.footerWrapper, { [styles.listWrapper]: isListView })}
                        >
                            {typeof p_.selectedArea === 'number' && contentInArea.length ? (
                                <div className={styles.multiSelectActionsPanel}>
                                    <div className={styles.controlsWrapper}>
                                        <Icon
                                            containerClass={styles.control}
                                            type={isSelectedAllContent ? 'close_tiny' : 'checked'}
                                            color={'white'}
                                            size={13}
                                            tooltip={{
                                                title: isSelectedAllContent
                                                    ? translate('deselect')
                                                    : translate('selectAll'),
                                            }}
                                            onClick={function () {
                                                editor.onSelectAllContentInArea()
                                            }}
                                        />
                                        <Icon
                                            color={'white'}
                                            type="delete"
                                            containerClass={styles.control}
                                            tooltip={{ title: translate('delete') }}
                                            disabled={!p_.selected.length}
                                            size={13}
                                            onClick={function () {
                                                editor.onRemoveSelectedSources()
                                            }}
                                        />
                                    </div>
                                    {editor.state.data.selected.length > 1 ? (
                                        <div
                                            className={styles.autoPosSelected}
                                            onClick={function (e) {
                                                editor.autoPosSelectedContent('withoutSpaces', p_.selectedArea)
                                            }}
                                        >
                                            {translate('setSelectedWithoutSpaces')}
                                        </div>
                                    ) : null}
                                </div>
                            ) : null}
                            <div
                                className={cn(styles.scale)}
                                style={isListView && p_.show && !p_.isMobile ? { paddingBottom: 0 } : null}
                            >
                                <div
                                    className={styles.scaleTitle}
                                    style={{
                                        opacity: (p_.isMobile && isYView) || (p_.isMobile && isXView) ? 0 : 1,
                                        pointerEvents:
                                            (p_.isMobile && isYView) || (p_.isMobile && isXView) ? 'none' : 'auto',
                                        display: p_.isMobile && isListView ? 'none' : 'block',
                                    }}
                                >
                                    <Icon
                                        size={16}
                                        onClick={this.play}
                                        type={s_.playing ? 'pause' : 'play'}
                                        color={'white'}
                                    />
                                </div>
                                <div className={styles.pointsWrapper}>
                                    {isListView && <span>{helpers.msToHMS(s_.time)}</span>}
                                    {!isListView && (
                                        <div className={styles.points}>
                                            <div className={styles.timeWrapper} id="timeWrapper">
                                                <Draggable {...draggableOptions}>
                                                    <Animation>
                                                        {(s_.dragTime || s_.hoverTime) && (
                                                            <div className={styles.timeNumber}>
                                                                {helpers.msToHMS(s_.time)}
                                                            </div>
                                                        )}
                                                    </Animation>

                                                    <div
                                                        className={cn(grid.fullHeight, grid.col)}
                                                        onMouseEnter={() => this.setState({ hoverTime: true })}
                                                        onMouseLeave={() => this.setState({ hoverTime: false })}
                                                    >
                                                        <div className={styles.timePoint}>
                                                            <Icon
                                                                className={item.cursorMove}
                                                                size={iconSize}
                                                                color={'white'}
                                                                type={'arrowhead_down'}
                                                            />
                                                        </div>
                                                        <div className={styles.timeLine} />
                                                    </div>
                                                </Draggable>

                                                {s_.loopSteps.map((step, index) => {
                                                    return (
                                                        <div
                                                            key={`editorScale_LoopPoint_${index}`}
                                                            className={styles.loopLine}
                                                            style={{ left: `${step.left}%` }}
                                                        />
                                                    )
                                                })}
                                            </div>
                                            {s_.steps.map((step, index) => {
                                                if (index < s_.steps.length - 1) {
                                                    return (
                                                        <div
                                                            key={`editorScale_point_${index}`}
                                                            style={{ width: `${step.width}%` }}
                                                        >
                                                            {step.number}
                                                        </div>
                                                    )
                                                } else {
                                                    return (
                                                        <div
                                                            key={`editorScale_point_${index}`}
                                                            style={{ width: `${step.width}%` }}
                                                            className={cn(grid.row, grid.justify)}
                                                        >
                                                            <div>{step.number}</div>
                                                            {isDynamicDurationContentExist ? (
                                                                <Icon
                                                                    type={'duration_dynamic'}
                                                                    color={'white'}
                                                                    size={16}
                                                                />
                                                            ) : (
                                                                <div>{Math.round(s_.duration)}</div>
                                                            )}
                                                        </div>
                                                    )
                                                }
                                            })}
                                        </div>
                                    )}
                                </div>
                                {isListView && p_.show && (
                                    <div className={styles.contentTitle}>
                                        <div style={{ width: '10%' }}></div>
                                        <div className={cn(grid.w90, grid.row, textStyles.center)}>
                                            <div className={grid.w10}>{translate('content')}</div>
                                            <div className={grid.w40}>{translate('name')}</div>
                                            <div className={grid.w25}>{translate('startTime')}</div>
                                            <div className={grid.w25}>{translate('duration')}</div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {p_.show && (
                                <div>
                                    {(isYView || isListView) &&
                                        this[sortableMethodName]({
                                            autoPos: p_.areas[p_.selectedArea].autoPos,
                                            areaViewType: p_.areaViewType,
                                            currentArea: p_.selectedArea,
                                        })}
                                    {p_.areaViewType === 'x' &&
                                        this.getSortableRow(p_.areas[p_.selectedArea], {
                                            areaViewType: p_.areaViewType,
                                            currentArea: p_.selectedArea,
                                        })}
                                </div>
                            )}
                            {p_.showAllAreas &&
                                p_.areas.map((area, index) =>
                                    this.getSortableRow(p_.areas[index], { areaViewType: 'x', currentArea: index })
                                )}
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

EditorFooter.defaultProps = {
    duration: 0,
    sources: {},
    isSoundtrackLoading: false,
}

EditorFooter.propTypes = {
    sources: PropTypes.object,
    duration: PropTypes.number,
    areas: PropTypes.array,
    selectedArea: PropTypes.number,
    selectedContent: PropTypes.number,
    minimized: PropTypes.bool,
    showAllAreas: PropTypes.bool,
    onDelete: PropTypes.func,
    areaViewType: PropTypes.string,
    isSoundtrackLoading: PropTypes.bool,
}

export default withWindowResize(EditorFooter)

import React, { useState, useEffect } from 'react'
import helpers from 'core/helpers'
import helpersFiles from 'core/helpers/_files'
import translate from 'core/translate'
import { useDispatch } from 'react-redux'
import { updateFile } from '../files.state'

type File = {
    id: number
    folderId: number
    name: string
    tags: { label: string }[]
    data: any
    description: string | null
}

type ModelState = {
    id: number | null
    folderId: number | null
    name: string
    categoryId?: number | null
    tags?: { label: string }[]
    data?: any
    description: string | null
}

const initialModelState = {
    id: null,
    folderId: null,
    name: '',
    categoryId: null,
    description: null,
}

const FilesEditMethods = (p_: any) => {
    const dispatch = useDispatch()
    const [model, setModel] = useState<ModelState>(initialModelState)
    const [isSpacesError, seIsSpacesError] = useState<boolean>(false)
    const [error, setError] = useState<string>('')
    const [validateSymbolNumber, setValidateSymbolNumber] = useState<boolean>(false)

    useEffect(() => {
        getFileModel(p_.selectedInfo)
        spacesCheck(model.name)
    }, [p_.selectedInfo, p_.open])

    function getFileModel(file: File) {
        const model: ModelState = {
            id: file.id,
            folderId: file.folderId,
            name: file.name,
            tags: file.tags,
            description: file.description,
        }

        if (model.tags) {
            model.tags = helpersFiles.getTagsList(model.tags)
        }

        if (file.hasOwnProperty('data')) {
            model.data = file.data
        }

        setError('')
        setModel(model)
    }

    function onSave(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()

        const validData: any = validateAndFormatting()

        if (validData) {
            dispatch(updateFile(validData))
            p_.onClose()
        }
    }

    function validateAndFormatting() {
        const { selectedInfo } = p_
        const validModel: any = helpers.deepCopy(model)

        if (selectedInfo.type === 'link') {
            const validate: {
                isValid: boolean
                type?: string
                error?: string
            } = helpersFiles.validateLink(validModel.data.url)

            if (!validate.isValid) {
                if (validate.error) {
                    setError(translate(validate.error))
                }

                return false
            }
            validModel.data.type = validate.type
        }

        if (validModel.tags) {
            validModel.tags = validModel.tags.map((tag: { label: string }) => tag.label)
        }

        return validModel
    }

    function onChangeData(key: string, value: string) {
        const newModel = {
            ...model,
            data: {
                ...model.data,
                [key]: value,
            },
        }

        if (key === 'url') {
            setError('')
        }

        setModel(newModel)
    }

    function spacesCheck(value: string) {
        if (!value || /^\s*$/.test(value)) {
            seIsSpacesError(true)
        } else {
            seIsSpacesError(false)
        }
    }

    function onChangeTags(tags: { id: string; label: string; name: string }[]) {
        tags = tags.map((tag) => {
            if (!tag.id && tag.name.match(/#/g)) {
                tag.name = tag.label = tag.name.replace(/#/g, '')
            }
            return tag
        })

        const newModel: any = {
            ...model,
            tags,
        }

        setModel(newModel)
    }

    function onChangeFolder(selected: { id: number }) {
        const newModel = {
            ...model,
            folderId: selected.id,
        }

        setModel(newModel)
    }

    function onChangeDescription(value: string) {
        const newModel = {
            ...model,
            description: value,
        }

        setModel(newModel)
    }

    function onValidateSymbolNumber(isValidate: boolean) {
        setValidateSymbolNumber(isValidate)
    }

    return {
        s_: {
            model,
            error,
            validateSymbolNumber,
            isSpacesError,
        },
        methods: {
            onSave,
            onChangeData,
            onChangeTags,
            onChangeFolder,
            onChangeDescription,
            onValidateSymbolNumber,
            spacesCheck,
        },
    }
}

export default FilesEditMethods

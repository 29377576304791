import React, { FC, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import EditText from 'blocks.simple/editText/editText'
import Button from 'blocks.simple/button/button'
import translate from 'core/translate'
import { styles } from './AddDeviceByCode-styles'
import { Typography } from 'atoms/Typography'
import { AddDeviceByCodeProps, AddDeviceCurrentLicense, AddDeviceLicensesData } from './AddDeviceByCode-types'
import displaysHelpers from 'core/helpers/_digitalSignages'
import { useLocation } from 'react-router'
import { getURLSearchParamsByLocation } from 'features/routes/utils'
import { approve, displaysActions, IDisplaysState } from 'pages/displays/displays.state'
import { useTypedAuthenticatedSelector, useTypedSelector } from 'core/store/hooks'
import Dropdown from 'blocks.simple/dropdown/dropdown'
import { colors, grid, item, text } from 'theme'
import { cn } from 'ethcss'
import { api } from 'core/api/ConnectionManager'
import { IAuthenticatedUserState } from 'blocks.app/user/user.state'
import Icon from 'blocks.simple/icon/icon'
import { text as textStyles } from 'theme'
import { isVacantCountNull, licenseType, rateTypeRename } from 'core/utils/displayUtil'
import helpersDisplays from 'core/helpers/_digitalSignages'
import Link from 'blocks.simple/link/link'

export const AddDeviceByCode: FC<AddDeviceByCodeProps> = () => {
    const dispatch = useDispatch()
    const { approveToken, error } = useTypedSelector<IDisplaysState>((state) => state.displays)
    const location = useLocation()
    const query = getURLSearchParamsByLocation(location)
    const groupId = query.groupId
    const submitRef = useRef<null | HTMLFormElement>(null)
    const [licensesData, setLicensesData] = useState<AddDeviceLicensesData[]>([])
    const [currentLicense, setCurrentLicense] = useState<AddDeviceCurrentLicense>({ id: null })
    const { data } = useTypedAuthenticatedSelector<IAuthenticatedUserState>((state) => state.user)

    const checkAvailableDevices = () => {
        if (!licensesData) return false
        const selectedLicense = licensesData.find((license: any) => license.id === currentLicense.id)
        if (!selectedLicense) return false
        return !!selectedLicense.vacantCount
    }

    useEffect(() => {
        if (!groupId) return
        if (!approveToken) return
        if (!displaysHelpers.isValidApproveTokenWithMask(approveToken)) return

        dispatch(approve({ licenseId: currentLicense.id }))
    }, [groupId])

    const [isLicenseNull, setIsLicenseNull] = useState<boolean>(false)

    const changeApproveToken = (approveToken: string) =>
        !isLicenseNull && dispatch(displaysActions.addApproveToken(approveToken))
    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        if (currentLicense.id === '') {
            return setIsLicenseNull(true)
        } else {
            setIsLicenseNull(false)
        }
        dispatch(approve({ licenseId: currentLicense.id }))
    }
    const onCloseModal = (e: React.MouseEvent) => dispatch(displaysActions.closeModal())

    const renderLabel = () => <div className={styles.addDeviceByCode__label}>{translate('enterDisplayCode')}</div>

    const getLicenses = (companyId: number) => {
        api.send('getLicenseByFilters', {
            sort: [['licenseId', 'ASC']],
            limit: 1000,
            companyId: companyId,
        }).then((res: any) => {
            let optionFormat = res.data
                .filter((data: any) => data.activeQ)
                .map((opt: any) => ({
                    id: opt.licenseId,
                    name: licenseType(opt) + ' ' + rateTypeRename(opt.rateType),
                    vacantCount: opt.vacantCount,
                    expiredAt: opt.expiredAt,
                    paidQ: opt.paidQ,
                }))
            setLicensesData(optionFormat)
        })
    }

    const checkEmptyLicences = (licences: any) => {
        return licences.some((licence: any) => licence.vacantCount)
    }

    useEffect(() => {
        getLicenses(data.companyId)
        if (!approveToken) return

        const tokenHasEmptyFields = approveToken ? approveToken.includes('_') : false

        if (!tokenHasEmptyFields) {
            const query = getURLSearchParamsByLocation(location)

            if (query.add_display_code) {
                setTimeout(() => {
                    dispatch(approve({ licenseId: currentLicense.id }))
                }, 2500)

                return
            }

            dispatch(approve({ licenseId: currentLicense.id }))
        }
    }, [approveToken])

    return (
        <>
            {isVacantCountNull(licensesData) ? (
                <form onSubmit={onSubmit} className={styles.addDeviceByCode} ref={submitRef}>
                    <Typography type="title" className={styles.addDeviceByCode__title}>
                        {translate('addDisplay')}
                    </Typography>
                    <div className={styles.addDeviceByCode__content}>
                        <div className={styles.addDeviceByCode__field}>
                            <EditText
                                label={renderLabel()}
                                mod="withBorder"
                                value={approveToken}
                                onChange={changeApproveToken}
                                className={styles.addDeviceByCode__input}
                                mask="999-999-999"
                                type="tel"
                                placeholder="___-___-___"
                                disabled={helpersDisplays.isCheckLicenseQ() ? !checkAvailableDevices() : false}
                            />
                        </div>
                        {error && <Typography type="error">{translate(error)}</Typography>}
                        <div className={styles.addDeviceByCode__info}>
                            <Typography type="title">{translate('displayCodeTitle')}</Typography>
                            <Typography type="title">{translate('displayCodeDescription')}</Typography>
                        </div>

                        {helpersDisplays.isCheckLicenseQ() && (
                            <div className={styles.addDeviceByCode__field}>
                                <div className={cn(grid.mt_big, item.rel)}>
                                    <Dropdown
                                        label={
                                            <Typography
                                                type="text"
                                                className={cn(styles.license__label, grid.mb_micro)}
                                            >
                                                {translate('licensesType')}
                                            </Typography>
                                        }
                                        id="licensesType"
                                        value={currentLicense.id && currentLicense.id}
                                        options={licensesData}
                                        onChange={(selected) => {
                                            setIsLicenseNull(false)
                                            setCurrentLicense(selected)
                                        }}
                                    />
                                </div>
                                {currentLicense?.vacantCount ? (
                                    <div className={styles.license__info}>
                                        <Typography type="text">
                                            {translate('licensesBalance')} {currentLicense.vacantCount}{' '}
                                            {translate('licensesLeft')}
                                        </Typography>
                                    </div>
                                ) : currentLicense?.vacantCount === 0 && checkEmptyLicences(licensesData) ? (
                                    <div className={styles.noLicensesLeft}>
                                        <Icon type={'info'} color={colors.notificationRed} />
                                        <span>{translate('noLicencesInTariff')}</span>
                                    </div>
                                ) : null}
                                {!checkEmptyLicences(licensesData) && (
                                    <div className={styles.noLicensesLeft}>
                                        <Icon type={'info'} color={colors.notificationRed} />
                                        <span>{translate('noLicencesInTariffs')}</span>
                                    </div>
                                )}
                                {isLicenseNull && (
                                    <div className={cn(text.center, grid.mt_md)}>
                                        <Typography type="title">{translate('fieldIsRequired')}</Typography>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    <div className={styles.addDeviceByCode__footer}>
                        <Button className={styles.addDeviceByCode__button} onClick={onCloseModal} mod="withBorder">
                            {translate('close')}
                        </Button>
                        <Button mod="fill" type="submit">
                            {translate('save')}
                        </Button>
                    </div>
                </form>
            ) : (
                <div className={cn(styles.addDeviceByCode, styles.addDeviceByCode_noVacant)}>
                    <div className={styles.content}>
                        <div>
                            <div className={cn(grid.center)}>
                                <div className={cn(grid.row, grid.mb_md, grid.center)}>
                                    <Icon type="sadSmile" size={75} />
                                </div>
                                <div className={cn(grid.mb_md)}>
                                    <Typography
                                        type="title"
                                        className={cn(textStyles.centerBlock, textStyles['normalPlus'], grid.mb_mini)}
                                    >
                                        {translate('cantAddlicensesLeft')}
                                    </Typography>
                                </div>
                                <div className={cn(grid.mb_md)}>
                                    <Typography
                                        type="title"
                                        className={cn(textStyles.centerBlock, textStyles['normalPlus'], grid.mb_mini)}
                                    >
                                        <Link to={'/licenses'}>{translate('addLicensesSection')}</Link>
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

import React, { FC, useEffect } from 'react'
import { AppPageProps, PageProps } from './Page-types'
import { styles } from './Page-styles'
import { UserInactivityService } from 'core/services/UserInactivityService'
import { DownloadService } from 'core/services/DownloadService'
import UserWindow from 'organisms/UserWindow'
import { ProfileDialog } from 'organisms/ProfileDialog'
import { IAuthenticatedUserState, logout, userActions } from 'blocks.app/user/user.state'
import AppHeader from 'blocks.app/app/__header/app__header'
import AppFeatures from 'blocks.app/app/__features/app__features'
import { Loader } from 'features/loader'
import { AppMenu } from 'blocks.app/app/__menu'
import { IAppState } from 'blocks.app/app/app.state'
import { VideoChatModal } from 'pages/videoChat/_blocks/VideoChatModal'
import { getURLSearchParamsByLocation } from 'features/routes/utils'
import { useLocation } from 'react-router'
import AppNotifications, {
    showInactivityNotification,
    hideInactivityNotification,
} from 'blocks.app/app/__notifications/app__notifications'
import helpers from 'core/helpers'
import { isExist } from 'core/utils/coreUtil'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { constants } from 'core/constants'
import { LogService } from '../../../../core/services/LogService/LogService'
import { CreateDirectoryModal } from '../../../../blocks.simple/createDirectoryModal/CreateDirectoryModal'
import { useWindowResize } from '../../../../core/hooks'
import { useTypedAuthenticatedSelector, useTypedSelector } from 'core/store/hooks'
import { media } from '../../../../theme'
import { appActions } from 'blocks.app/app/app.state'
import { RemoteSupportPanel } from '../../../../blocks.simple/remoteSupportPanel/RemoteSupportPanel'

const AppPage = React.memo(
    ({ children }: AppPageProps) => children,
    (prevProps, nextProps) => nextProps.pathname === prevProps.pathname
)

export const Page: FC<PageProps> = ({ children }) => {
    const location = useLocation()
    const dispatch = useDispatch()
    const { isMobile, isActiveRemoteSupport }: any = useSelector<RootStateOrAny>((state) => state.app)
    const size = useWindowResize({ width: getScreenInitialWidth() })

    function getScreenInitialWidth() {
        return window.innerWidth
    }

    const {
        showMobToolbar,
        showMobMenu,
        minimizeMenu,
        menu,
        activeMenuItem,
        monitorWindow,
        createDirectoryWindow,
    } = useTypedSelector<IAppState>((state) => state.app)

    const { isUserPanelOpen, isUserEditPanelOpen, data: user, tokens, mainUser } = useTypedAuthenticatedSelector<
        IAuthenticatedUserState
    >((state) => state.user)

    const { settings } = user
    const { locale: lang, inactivityTimeoutS } = settings

    const query = getURLSearchParamsByLocation(location)
    const pathname = helpers.getPathname(location.pathname)

    function getInactivityTimeout() {
        return inactivityTimeoutS || constants.MAX_INACTIVITY_TIMEOUT_S
    }

    function isInactivityTimeoutExist() {
        return isExist(inactivityTimeoutS)
    }

    function handleShowInactivityNotification(timeout: number) {
        showInactivityNotification('inactivityNotification', timeout)
    }

    function handleHideInactivityNotification() {
        hideInactivityNotification('inactivityNotification')
    }

    function handleInactivityTimeout() {
        dispatch(logout({ isSwitchAccount: false }))
    }

    const renderHeader = () => {
        return <AppHeader showMobMenu={showMobMenu} showMobToolbar={showMobToolbar} />
    }

    const renderPageFeatures = () => {
        return (
            <>
                <AppFeatures lang={lang} newFeatures={user.newFeatures} />
                <Loader />
            </>
        )
    }

    const onClose = () => dispatch(userActions.closeUserPanel())

    const onEditAccount = () => dispatch(userActions.openUserEditPanel())

    const onProfileClose = () => dispatch(userActions.closeUserEditPanel())

    useEffect(() => {
        if (isMobile) {
            if (size.width > media.tab) {
                dispatch(appActions.toggleMobile(false))
            }

            return
        }
        if (!isMobile) {
            if (size.width <= media.tab) {
                dispatch(appActions.toggleMobile(true))
            }

            return
        }
    })

    const renderPageContent = () => {
        return (
            <>
                <div></div>

                <AppMenu
                    user={user}
                    isShowMobMenu={showMobMenu}
                    minimize={minimizeMenu}
                    active={pathname.split('/')[0]}
                    pathname={pathname}
                    query={query}
                    menu={menu}
                    activeMenuItem={activeMenuItem}
                    lang={lang}
                />

                <AppPage pathname={pathname}>{children}</AppPage>

                <VideoChatModal />

                <UserWindow
                    className={styles.page__userWindow}
                    open={isUserPanelOpen}
                    user={user}
                    mainUser={mainUser}
                    onClose={onClose}
                    onEditAccount={onEditAccount}
                />

                <ProfileDialog
                    open={isUserEditPanelOpen}
                    onClose={onProfileClose}
                    user={user}
                    owner
                    isActiveDirectory={user.authType === 'activeDirectory'}
                />

                <AppNotifications />
                <LogService data={monitorWindow} isMobile={isMobile} />
                <CreateDirectoryModal data={createDirectoryWindow} />
                <DownloadService />
                {isActiveRemoteSupport && <RemoteSupportPanel />}
                <UserInactivityService
                    onInactivityTimeout={handleInactivityTimeout}
                    onShowInactivityNotification={handleShowInactivityNotification}
                    onHideInactivityNotification={handleHideInactivityNotification}
                    inactivityTimeoutS={getInactivityTimeout()}
                    isEnable={!!tokens && isInactivityTimeoutExist()}
                />
            </>
        )
    }

    return (
        <div className={styles.page}>
            {isMobile ? renderHeader() : null}
            {renderPageFeatures()}
            {renderPageContent()}
        </div>
    )
}

import PropTypes from 'prop-types'
import React from 'react'
import Item from '../__item/editor__item'
import DndDroppable from 'blocks.app/dnd/_droppable/dnd_droppable'
import LoaderLazy from 'blocks.app/loader/_lazy/loader__lazy'
import { cn } from 'ethcss'
import styles from './editor__area.jcss'
import item from 'blocks.simple/item/item.jcss'
import { EditorAreaControlPanel } from './EditorAreaControlPanel'
import { isExist } from 'core/utils/coreUtil'
import { editor } from '../editor.local.methods'
import Icon from '../../../blocks.simple/icon/icon'
import 'animate.css'

const ACTIVE_AREA_ZINDEX = 1000

class EditorArea extends React.Component {
    constructor(p_) {
        super(p_)

        this.state = {
            isHovered: false,
            mouseOver: false,
            pause: false,
        }
        this.onDrop = this.onDrop.bind(this)
        this.onDragOver = this.onDragOver.bind(this)
        this.onDragLeave = this.onDragLeave.bind(this)
    }

    onMouseEvent = (value) => {
        this.setState({ mouseOver: value })
    }

    // Парсинг данных при бросании файла в зону
    onDrop(data, e) {
        const p_ = this.props
        const file = JSON.parse(data.dnd_item)

        p_.onAddContent(file)
        this.setState({ isHovered: false })
        e.stopPropagation()
    }

    // Подсветка зоны при наведении файла над зоной
    onDragOver() {
        this.setState({ isHovered: true })
    }

    // Отключение подсветки зоны при скрытии файла над зоной
    onDragLeave() {
        this.setState({ isHovered: false })
    }

    isVisibleContent(fileItem) {
        const { area } = this.props
        const isLoopEnable = area.loop

        return isLoopEnable ? this.isVisibleLoopedContent(fileItem) : this.isVisibleDefaultContent(fileItem)
    }

    isVisibleLoopedContent(fileItem) {
        const { time, duration: pageDuration, area } = this.props
        const { startTime, duration } = fileItem

        if (this.isAreaPlayingTimeEnd(time, pageDuration)) return false

        const areaContentDuration = this.getAreaContentDuration(area.content)
        const currentTimelineLoopChunk = this.getCurrentTimelineLoopChunk(time, areaContentDuration)
        const isVisible = currentTimelineLoopChunk >= startTime && currentTimelineLoopChunk < startTime + duration

        return isVisible
    }

    isVisibleDefaultContent(fileItem) {
        const { time } = this.props
        const { startTime, duration } = fileItem
        const isVisible = time >= startTime && time < startTime + duration

        return isVisible
    }

    getCurrentTimelineLoopChunk(time, areaContentDuration) {
        if (time <= areaContentDuration) return time

        return time % areaContentDuration
    }

    getAreaContentDuration(content) {
        return [...content]
            .sort((a, b) => a.startTime - b.startTime)
            .reduce((endTime, content) => {
                const { startTime, duration } = content
                const contentEndTime = startTime + duration
                return contentEndTime > endTime ? contentEndTime : endTime
            }, 0)
    }

    isAreaPlayingTimeEnd(time, duration) {
        return time === duration
    }

    isHasVisibleContent() {
        const p_ = this.props

        return p_.content.files.filter((fileItem) => this.isVisibleContent(fileItem, p_)).length > 0
    }

    isRenderLoader() {
        const { fileLoading, index } = this.props

        if (!isExist(fileLoading) || !isExist(index)) return false

        return fileLoading.areaId === index
    }

    renderLoader() {
        return (
            <div className={styles.loader}>
                <LoaderLazy key="editor__screen_file_loader" active={true} />
            </div>
        )
    }

    renderContent() {
        const p_ = this.props

        if (this.isRenderLoader()) {
            return this.renderLoader()
        }

        return (
            <>
                {!p_.isMobile && this.isHasVisibleContent() ? (
                    <EditorAreaControlPanel
                        selected={p_.selected}
                        isSelectedArea={p_.isSelectedArea}
                        selectedContent={p_.selectedContent}
                        onControlPanerClick={p_.onControlPanerClick}
                        time={p_.time}
                        files={p_.content.files}
                        mouseOver={this.state.mouseOver}
                    />
                ) : null}
                {!p_.isSimple && p_.isMobile && p_.selected && editor.isSingleArea() && (
                    <div className={styles.areaFullScreenPanel}>
                        <div
                            onClick={() => {
                                editor.setFullScreenArea()
                            }}
                        >
                            <Icon type={'maximize_hollow'} color={'white'} size={18} />
                        </div>
                    </div>
                )}
                {p_.isMobile && p_.selected && (
                    <div className={styles.areaSettingsPanel}>
                        <div
                            onClick={() => {
                                editor.showSettings('content')
                                editor.showToolbar(true)
                            }}
                        >
                            <Icon type={'gear'} color={'white'} size={18} />
                        </div>
                    </div>
                )}
                {p_.content.files.map((fileItem, index) => {
                    const itemWidth = fileItem.width
                    const itemHeight = fileItem.height
                    const itemX = fileItem.x
                    const itemY = fileItem.y
                    const selected = p_.selected && p_.selectedContent === fileItem.index
                    const visible = this.isVisibleContent(fileItem)
                    let isStateAnimation = false

                    if (!visible) {
                        return null
                    }
                    const relTime = p_.time - fileItem.startTime
                    let animation = {}

                    if (fileItem.animation && fileItem.animation.useAnimation) {
                        if (relTime > 0 && fileItem.animation.in) {
                            animation = fileItem.animation.in
                        }
                        if (fileItem.animation.state) {
                            if (fileItem.animation.in) {
                                if (relTime > fileItem.animation.in.duration) {
                                    animation = fileItem.animation.state
                                }
                            } else {
                                animation = fileItem.animation.state
                            }
                            isStateAnimation = true
                        }
                        if (fileItem.animation.out && fileItem.duration - relTime <= fileItem.animation.out.duration) {
                            isStateAnimation = false
                            animation = fileItem.animation.out
                        }
                    }

                    const zIndex = selected && !p_.playing ? ACTIVE_AREA_ZINDEX : 20 * (index + 1)

                    return (
                        <Item
                            key={`fileItem_${index}`}
                            id={fileItem.id}
                            name={fileItem.name}
                            area={p_.area}
                            resolutionWidth={p_.resolutionWidth}
                            resolutionHeight={p_.resolutionHeight}
                            width={itemWidth}
                            height={itemHeight}
                            left={itemX}
                            top={itemY}
                            type={fileItem.type}
                            isScreenItem={true}
                            fileType={fileItem.fileType}
                            selected={selected}
                            disableResize={!selected && p_.isSelectedArea}
                            src={fileItem.src}
                            style={fileItem.style}
                            data={fileItem.data}
                            thumbnail={fileItem.thumbnail}
                            zIndex={zIndex}
                            onSelect={function () {
                                p_.onSelectContent(fileItem.index)
                            }}
                            onDragEnd={function (pos) {
                                p_.onUpdatePosition(fileItem.index, pos, 'drag')
                                return true
                            }}
                            onResizeEnd={function (pos) {
                                p_.onUpdatePosition(fileItem.index, pos, 'resize')
                                return true
                            }}
                            showDeleteIcon={false}
                            fillMode={fileItem.fillMode}
                            showLayersIcon={fileItem.layersCount > 1}
                            layersCount={fileItem.layersCount}
                            animation={fileItem.animation}
                            volume={fileItem.volume}
                            additionalData={{
                                iFrameAvailable: fileItem.iFrameAvailable,
                            }}
                            scale={p_.scale}
                            playing={p_.playing}
                            time={p_.time}
                            startTime={fileItem.startTime}
                            seekTo={p_.seekTo}
                            className={'animate__' + animation.name}
                            wrapperStyle={{ animationDuration: `${animation.duration}ms` }}
                            isStateAnimation={isStateAnimation}
                            selectedPage={editor.state.data.selectedPage}
                            selectedArea={editor.state.data.selectedArea}
                            contentIndex={index}
                            selectedContentIndex={p_.selectedContent}
                            isSelectedArea={p_.isSelectedArea}
                            areaIndex={p_.index}
                        />
                    )
                })}
            </>
        )
    }

    render() {
        const p_ = this.props

        return (
            <div className={cn(styles.wrapper)}>
                <DndDroppable
                    id={`editorDroppableArea_${p_.index}`}
                    className={cn(styles.dropWrapper)}
                    onDrop={this.onDrop}
                    onDragOver={this.onDragOver}
                    onDragLeave={this.onDragLeave}
                    onMouseOver={() => {
                        this.onMouseEvent(true)
                    }}
                    onMouseLeave={() => {
                        this.onMouseEvent(false)
                    }}
                    onMouseDown={function (e) {
                        if (e.target.id !== `editorDroppableArea_${p_.index}`) {
                            e.stopPropagation()
                        }
                    }}
                >
                    {this.renderContent()}
                </DndDroppable>
            </div>
        )
    }
}

EditorArea.defaultProps = {
    content: [],
    onControlPanerClick: (type, index) => {},
}
EditorArea.propTypes = {
    content: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    selected: PropTypes.bool,
    index: PropTypes.number,
    selectedContent: PropTypes.number,
    scale: PropTypes.number,
    onAddContent: PropTypes.func,
    onSelectContent: PropTypes.func,
    sources: PropTypes.object,
    onUpdatePosition: PropTypes.func,
    time: PropTypes.number,
    isSelectedArea: PropTypes.bool,
    onControlPanerClick: PropTypes.func,
    fileLoading: PropTypes.object,
}

export default EditorArea

import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
import { layers, indents } from 'blocks.app/config'
import { defaultThemeStyles } from '../editor.theme'
import { textSizes } from 'theme/media'
import { mediaCSS } from 'theme'

const editorListItemStyles = {
    editorListWrapper: {
        ...grid.row,
        ...grid.fullHeight,
        outline: 'none',
        borderBottom: `1px solid var(--var-module-editor-component-timeline-style-border, ${defaultThemeStyles['timeline-border']})`,
        cursor: 'pointer',
    },
    yWrapper: {
        ...grid.w100,
        height: 24,
    },
    selectCheckbox: {
        ...grid.pr_micro,
        ...grid.ml_micro,
        ...grid.fullHeight,
        ...grid.normalCenter,
        ...grid.row,
    },
    selectCheckboxY: {
        paddingTop: 4,
        zoom: 0.6,
        paddingRight: '2px !important',
    },
    selectCheckboxX: {
        position: 'absolute',
        left: 4,
        top: 4,
        width: 24,
        height: 24,
        marginLeft: '0 !important',
        zoom: 0.8,
    },
    xSortableHandle: {
        position: 'absolute',
        left: 4,
        bottom: 4,
        opacity: 0,
        transition: 'opacity 0.3s',
        pointerEvents: 'none',
    },
    xContainerHover: {
        '&:hover': {
            div: {
                opacity: 1,
            },
        },
    },
    listWrapper() {
        return {
            ...grid.w100,
            height: 60,

            [`& .${this.wrapper}`]: {
                backgroundColor: `var(--var-module-editor-component-timeline-style-list-background, ${defaultThemeStyles['timeline-list-background']})`,
                outline: 'none',
                borderBottom: `1px solid var(--var-module-editor-component-timeline-style-list-border, ${defaultThemeStyles['timeline-list-border']})`,
            },
            [`& .${this.selected}`]: {
                backgroundColor: `var(--var-module-editor-component-timeline-style-list-background, ${defaultThemeStyles['timeline-list-background']})`,
            },
        }
    },
    listWrapperSelected: {
        backgroundColor: `var(--var-module-app-component-editor-style-backgroundLight, ${defaultThemeStyles['backgroundLight']})`,
    },
    preview: {
        border: `1px solid var(--var-module-editor-component-timeline-style-border, ${defaultThemeStyles['timeline-border']})`,
        backgroundColor: `var(--var-module-editor-component-timeline-style-preview-background, ${defaultThemeStyles['timeline-preview-background']})`,
        cursor: 'move',
    },
    listPreview() {
        return {
            ...grid.row,
            ...grid.noWrap,
            ...grid.w20,
            ...grid.fullHeight,
            [`& .${this.preview}`]: {
                cursor: 'pointer',
            },
            [mediaCSS.tab]: {
                width: 140,
            },
        }
    },
    previewTip: {
        ...item.shadowButton,
        position: 'absolute',
        ...item.transitionErase,
        width: 100,
        height: 50,
        top: -40,
        right: -90,
        zIndex: layers.sticky,
        padding: 2,
        backgroundColor: `var(--var-module-editor-component-timeline-style-list-background, ${defaultThemeStyles['timeline-list-background']})`,
    },
    previewWrapper: {
        ...grid.w45,
        position: 'relative',

        '&:hover': {
            div: {
                opacity: 1,
            },
        },
    },
    time: {
        ...grid.w95,
        ...grid.pl_mini,
        ...grid.pr_mini,
        ...grid.fullHeight,

        [mediaCSS.tab]: {
            width: 'calc(100% - 30px)',
        },
    },
    timeList: {
        ...grid.w80,
        ...grid.fullHeight,
        padding: `${indents.micro}px 0`,

        [mediaCSS.tab]: {
            width: 'calc(100% - 140px)',
        },
    },
    timeInner: {
        ...grid.w100,
        ...grid.fullHeight,
        position: 'relative',
        '& .react-draggable': {
            height: '100% !important',
        },
    },
    duration: {
        ...grid.rowCenter,
        ...grid.fullHeight,
        position: 'relative',
    },
    duration_type_viewY: {
        backgroundColor: `var(--var-module-editor-component-timeline-style-timeBlock, ${defaultThemeStyles['timeline-timeBlock']})`,
    },
    iconWrapper: {
        //...grid.w5,
        ...grid.rowCenter,
        position: 'relative',
        backgroundColor: `var(--var-module-editor-component-timeline-style-preview-background, ${defaultThemeStyles['timeline-preview-background']})`,

        [mediaCSS.tab]: {
            width: 30,
        },
    },
    selected: {
        backgroundColor: `var(--var-module-editor-component-timeline-style-selected, ${defaultThemeStyles['timeline-selected']}) !important`,
    },
    name: {
        ...grid.flex,
        ...grid.normalCenter,
        color: `var(--var-module-editor-component-timeline-style-text, ${defaultThemeStyles['timeline-text']})`,
        ...item.ellipsis,
        fontSize: textSizes.normal,
    },
    nameIcon: {
        display: 'inline-block',
        paddingLeft: 5,
    },
    delete: {
        ...grid.colCenter,
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: indents.micro,
    },
    deleteSmall: {
        right: -4,
    },
    deleteList: {
        right: indents.md,
        [mediaCSS.desktop]: {
            right: indents.microPlus,
        },
        [mediaCSS.desktopXS]: {
            right: 5,
        },
    },
    buttons: {
        ...item.shadowButton,
        position: 'relative',
        left: -45,
        width: 80,
        ...grid.p_mini,
        ...grid.rowCenter,
        backgroundColor: `var(--var-module-editor-component-timeline-style-preview-background, ${defaultThemeStyles['timeline-preview-background']})`,
    },
    buttonsSmall: {
        // width: 30,
        // left: -5,
        width: 40,
        left: -3,
    },
    text: {
        ...grid.full,
        ...grid.row,
        ...grid.normalCenter,
        ...grid.center,
        textAlign: 'center',
        overflow: 'hidden',
        color: `var(--var-module-editor-component-timeline-style-textViewText, ${defaultThemeStyles['timeline-textViewText']})`,
    },
    backLayer: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'absolute',
        backgroundColor: `var(--var-module-editor-component-timeline-style-backLayer, ${defaultThemeStyles['timeline-backLayer']})`,
    },
    button: {
        position: 'absolute',
        bottom: indents.md,
        left: 'calc(50% - 55px)',
    },
    sortableHandle: {
        ...grid.rowCenter,
        width: '43%',
        position: 'absolute',
        height: '100%',
        backgroundColor: `var(--var-module-app-component-editor-style-backgroundMain, ${defaultThemeStyles.backgroundMain})`,
        borderRadius: '0 3px 3px 0',
        opacity: 0,
        transition: 'opacity 0.3s',

        [mediaCSS.tab]: {
            width: 30,
        },
    },
    audioInfoLine: {
        ...grid.w100,
        ...grid.row,
        textAlign: 'center',
        color: `var(--var-module-editor-component-timeline-style-textViewText, ${defaultThemeStyles['timeline-textViewText']})`,

        [mediaCSS.tab]: {
            width: 'calc(100% - 40px)',
        },
    },
    openWidgetIcon: {
        paddingRight: 6,
        [mediaCSS.desktopL]: {
            paddingRight: 0,
        },
    },
    hideHandle: {
        opacity: 0,
        pointerEvents: 'none',
    },
    editSoundtrack: {
        position: 'absolute',
        left: 10,
        top: '50%',
        transform: 'translateY(-50%)',
    },
    listPreviewMob: {
        width: '72px !important',
    },
    timeListMob: {
        width: 'calc(100% - 72px)',
    },
    audioInfoLineMob: {
        width: 'calc(100% - 20px)',
    },
    xViewToolbar: {
        display: 'flex',
    },
    yItemPreviewBlock: {
        backgroundColor: `var(--var-module-editor-component-timeline-style-preview-background, ${defaultThemeStyles['timeline-preview-background']})`,
    },
}

addStyles(editorListItemStyles)
export default editorListItemStyles

import React from 'react'
import CatalogHeaderMethods from './catalog__header.local.methods'
import Searchbar from 'blocks.app/searchbar/searchbar'
import { cn } from 'ethcss'
import styles from './catalog__header.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import translate from 'core/translate'
import helpers from 'core/helpers'
import { history } from 'features/routes'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from 'blocks.simple/icon/icon.theme'
import { CatalogContext, ALLOW_ADVANCED_SEARCH } from '../catalog'
import { isExist } from 'core/utils/coreUtil'
import { changeFilter, changeNewFilter } from 'features/routes'
import { updateUserSettings } from 'blocks.app/user/user.state'
import { connect } from 'react-redux'
import { isHorizontalMenu } from 'core/helpers/menu'

class CatalogHeader extends CatalogHeaderMethods {
    isAllowAdvancedSearch() {
        const { template, type } = this.props

        // @ts-ignore
        return template.allowAdvancedSearch && ALLOW_ADVANCED_SEARCH[type]
    }

    render() {
        const s_ = this.state
        const p_ = this.props
        const { sort, disposition, dateFilter } = p_.viewParams
        const useNewFilter = p_.template.useNewFilter
        const HeaderButton = p_.template.headerButton
        const isButtonUpload = p_.showHeaderButton && HeaderButton
        const isAllowMultipleSelection = isExist(p_.template.isAllowMultipleSelection)
            ? p_.template.isAllowMultipleSelection
            : true

        return (
            <CatalogContext.Consumer>
                {(catalogContext: { get: () => void }) => {
                    const get = catalogContext ? catalogContext.get : () => {}

                    return (
                        <React.Fragment>
                            <div
                                className={cn({
                                    [styles.mapWrapper]: p_.isMap,
                                    [styles.mapWrapperMob]: p_.isMap && p_.isMobilePage && !isHorizontalMenu(),
                                    [styles.wrapperWithButton]: isButtonUpload,
                                    [styles.marginLeft]:
                                        isHorizontalMenu() &&
                                        p_.pathName !== '/addContentToDevice' &&
                                        p_.pathName !== '/schedule' &&
                                        p_.pathName !== '/broadcasts/edit' &&
                                        p_.pathName !== '/advertisingCampaigns/add' &&
                                        p_.pathName !== '/broadcasts/addVideowall' &&
                                        p_.pathName !== '/adBlocks/add' &&
                                        p_.pathName !== '/displays/updateDevices' &&
                                        p_.pathName !== '/broadcasts/addAdvanced' &&
                                        p_.pathName !== '/files/videoEditor' &&
                                        !p_.isMap,
                                })}
                            >
                                <Searchbar
                                    type={p_.type}
                                    getMethod={get}
                                    isAllowAdvancedSearch={this.isAllowAdvancedSearch()}
                                    small={p_.small}
                                    placeholder={p_.placeholder}
                                    className={cn(p_.className, { [cn(grid.space, grid.pr_md)]: isButtonUpload })}
                                    mod={'withLightBorder'}
                                    value={p_.query.query}
                                    searchable={p_.searchable}
                                    onChange={(value: string) => {
                                        changeFilter({
                                            [helpers.prefixQuery({ name: p_.type, field: 'query' })]: value,
                                        })
                                    }}
                                    sort={
                                        p_.isMap || p_.small || !p_.template.sortOptions
                                            ? null
                                            : {
                                                  icon: 'sort',
                                                  iconColor:
                                                      getThemeStyleValue('ui', 'icon', 'disable') ||
                                                      defaultThemeStyles.disable,
                                                  iconActiveColor:
                                                      getThemeStyleValue('ui', 'icon', 'active') ||
                                                      defaultThemeStyles.active,
                                                  value: sort,
                                                  options: p_.template.sortOptions(),
                                              }
                                    }
                                    onSortChange={(selected: { id: number }) => {
                                        p_.updateUserSettings({
                                            data: { sort: { [p_.template.settingsPrefixes]: selected.id } },
                                            isSaveSettings: !p_.template.disableUpdateUserSettings,
                                        })
                                    }}
                                    dateFilter={
                                        p_.small || !p_.template.dateFilterOptions
                                            ? null
                                            : {
                                                  icon: 'new_filter_icon',
                                                  iconColor:
                                                      getThemeStyleValue('ui', 'icon', 'disable') ||
                                                      defaultThemeStyles.disable,
                                                  iconActiveColor:
                                                      getThemeStyleValue('ui', 'icon', 'active') ||
                                                      defaultThemeStyles.active,
                                                  value: dateFilter,
                                                  options: p_.template.dateFilterOptions(),
                                              }
                                    }
                                    onDateFilterChange={(selected: { id: number }) => {
                                        p_.updateUserSettings({
                                            data: { dateFilter: { [p_.template.settingsPrefixes]: selected.id } },
                                            isSaveSettings: !p_.template.disableUpdateUserSettings,
                                        })
                                        changeFilter({
                                            [helpers.prefixQuery({ name: p_.type, field: 'dateFilter' })]: selected.id,
                                        })
                                    }}
                                    selection={
                                        p_.isMap ||
                                        p_.small ||
                                        !isAllowMultipleSelection ||
                                        (!p_.onSelect && !p_.onCancelAllSelect)
                                            ? null
                                            : {
                                                  icon: 'choice',
                                                  iconColor:
                                                      getThemeStyleValue('ui', 'icon', 'disable') ||
                                                      defaultThemeStyles.disable,
                                                  iconActiveColor:
                                                      getThemeStyleValue('ui', 'icon', 'active') ||
                                                      defaultThemeStyles.active,
                                                  value: null,
                                                  options: [
                                                      { id: 'selectAll', name: translate('selectAll') },
                                                      { id: 'cancelAll', name: translate('cancelAll') },
                                                  ],
                                                  placeholder: translate('choice'),
                                              }
                                    }
                                    onSelectionChange={this.onSelectionChange}
                                    view={
                                        !p_.small && p_.template.viewOptions
                                            ? {
                                                  icon: 'view',
                                                  iconColor:
                                                      getThemeStyleValue('ui', 'icon', 'disable') ||
                                                      defaultThemeStyles.disable,
                                                  iconActiveColor:
                                                      getThemeStyleValue('ui', 'icon', 'active') ||
                                                      defaultThemeStyles.active,
                                                  value: disposition,
                                                  options: p_.template.viewOptions(history.location.pathname),
                                              }
                                            : null
                                    }
                                    viewLocal={p_.viewLocal}
                                    changeDisposition={p_.changeDisposition}
                                    onViewChange={(selected: { id: number }) => {
                                        p_.updateUserSettings({
                                            data: { view: { [p_.template.settingsPrefixes]: selected.id } },
                                            isSaveSettings: !p_.template.disableUpdateUserSettings,
                                        })
                                    }}
                                    filter={
                                        p_.filterOptions
                                            ? {
                                                  placeholder: translate('filter'),
                                                  icon: 'filter',
                                                  iconColor:
                                                      Object.keys(s_.selected).length > 0
                                                          ? getThemeStyleValue('ui', 'icon', 'active') ||
                                                            defaultThemeStyles.active
                                                          : getThemeStyleValue('ui', 'icon', 'disable') ||
                                                            defaultThemeStyles.disable,
                                                  iconActiveColor:
                                                      getThemeStyleValue('ui', 'icon', 'active') ||
                                                      defaultThemeStyles.active,
                                                  type: 'DigitalSignage',
                                                  options: p_.filterOptions,
                                                  selected: s_.selected,
                                                  groupId: p_.groupId,
                                              }
                                            : null
                                    }
                                    onChangeFilterStatus={p_.onChangeFilterStatus}
                                    onChangeADSearch={p_.onChangeADSearch}
                                    getAdvancedSearch={p_.getAdvancedSearch}
                                    advancedSearchState={p_.advancedSearchState}
                                    useNewFilter={useNewFilter}
                                    filterIsActive={p_.filterIsActive}
                                    availableFilters={s_.availableFilters}
                                    setAvailableFilters={this.setAvailableFilters}
                                    selectedFilters={s_.selectedFilters}
                                    setSelectedFilters={this.setSelectedFilters}
                                    onFilterChange={(dataFilter: { [index: string]: string }) => {
                                        const filter: { [index: string]: string } = {}
                                        for (let key in dataFilter) {
                                            if (dataFilter.hasOwnProperty(key)) {
                                                const prefixKey = helpers.prefixQuery({ name: p_.type, field: key })
                                                filter[prefixKey] = dataFilter[key]
                                            }
                                        }

                                        useNewFilter ? changeNewFilter(filter) : changeFilter(filter)
                                    }}
                                    search={
                                        !p_.isMap
                                            ? null
                                            : {
                                                  onSelect: this.onSelectAutocomplete,
                                                  props: {
                                                      ...p_,
                                                      displays: [...s_.mapqueryResult],
                                                      places: [...s_.geocoderResult],
                                                      searchMap: true,
                                                  },
                                              }
                                    }
                                    getDisposition={p_.getDisposition}
                                    isMobile={p_.isMobile}
                                />
                                {isButtonUpload && <HeaderButton />}
                            </div>
                            <div>{isButtonUpload && <div></div>}</div>
                        </React.Fragment>
                    )
                }}
            </CatalogContext.Consumer>
        )
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateUserSettings: (data: any) => dispatch(updateUserSettings(data)),
    }
}

const mapStateToProps = (state: { app: { isMobile: boolean } }) => ({
    isMobilePage: state.app.isMobile,
})

export default connect(mapStateToProps, mapDispatchToProps)(CatalogHeader)

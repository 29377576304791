import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import item from 'blocks.simple/item/item.jcss'
// @ts-ignore
import { defaultThemeStyles } from './Sidebar-theme.ts'
import { indents, IStyles } from 'theme'
import { textSizes } from 'theme/media'
import { colors } from '../../theme'

const buttonsHeight = 40

const sidebarStyles: IStyles = {
    customScrollBlock: {
        overflowX: 'hidden',
        overflowY: 'auto',
        height: '100%',
        // paddingBottom: 36,
        paddingBottom: 46,
        backgroundColor: colors.white,
    },
    sidebarWrapper: {
        width: '100%',
    },
    sidebarHeader: {
        ...grid.row,
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: colors.white,
        padding: '5px',
    },
    contentWrapper: {
        ...grid.space,
        ...grid.col,
        padding: '0 10px',
    },
    toggleButton: {
        width: '48%',
        ...grid.rowCenter,
        ...item.pointer,
        height: buttonsHeight,
        backgroundColor: `var(--var-module-sidebar-component-sidebar-style-toggleButton, ${defaultThemeStyles['toggleButton']})`,
        borderRadius: '5px',
    },
    buttonWithBorder: {
        border: `1px solid ${colors.greyLight}`,
    },
    toolbarListWrapper: {
        overflowY: 'auto',
    },
    selectedSide: {
        border: 'none',
        backgroundColor: `var(--var-module-sidebar-component-sidebar-style-buttonUploads, ${defaultThemeStyles['buttonUploads']})!important`,
    },
    info: {
        ...grid.p_md,
        ...grid.rowCenter,
    },
    empty: {
        ...grid.space,
        ...grid.col,
        ...grid.p_micro,
        ...grid.mt_md,
        textAlign: 'center',
    },
    description: {
        ...grid.mt_mdPlus,
        ...grid.mb_md,
        ...grid.w75,
        '&>p': {
            fontSize: textSizes.normal,
            lineHeight: '22px',
        },
    },
    itemDescription: {
        ...grid.p_md,
        ...grid.pt_none,
        backgroundColor: `var(--var-module-sidebar-component-sidebar-style-description, ${defaultThemeStyles['description']})`,
    },
    wrapperBack: {
        ...grid.space,
        ...grid.col,
        height: `calc(100% - ${buttonsHeight}px)`,
    },
    content: {
        ...grid.w100,
        ...grid.col,
        minHeight: `calc(100% - ${buttonsHeight}px)`,
        maxHeight: `calc(100% - ${buttonsHeight}px)`,
        backgroundColor: `var(--var-module-sidebar-component-sidebar-style-contentBackground, ${defaultThemeStyles['contentBackground']})`,
    },
    full: {
        width: '100%!important',
    },
    buttons: {
        ...grid.pb_md,
        borderBottom: `1px solid var(--var-module-sidebar-component-sidebar-style-border, ${defaultThemeStyles['border']})`,
    },
    some: {
        ...item.borderedBottomGreyLight,
    },
    additionContent__title: {
        paddingBottom: indents.miniPlus,
    },
    additionContent__item: {
        ...grid.flex,
        ...grid.col,
        paddingBottom: indents.miniPlus,
    },
    additionContent__checkbox: {
        ...grid.row,
        fontSize: textSizes.miniPlus,
        textAlign: 'left',
    },
    additionContent__radio: {
        ...grid.row,
        fontSize: textSizes.miniPlus,
        textAlign: 'left',
        paddingBottom: indents.miniPlus,
    },
    additionContent__label: {
        ...grid.space,
        fontSize: textSizes.miniPlus,
        paddingLeft: indents.miniPlus,
    },
    sidebarTopListItem: {
        ...grid.space,
        width: 0,
        overflow: 'hidden',
        cursor: 'pointer',
    },
    formWrapper: {
        ...grid.colCenter,
        ...grid.p_md,
        ...item.darkCloud,
    },
    formWrapperDNone: {
        ...item.none,
    },
    formTitle: {
        ...grid.w100,
        ...grid.mb_md,
        textAlign: 'center',
    },
    formButtons: {
        ...grid.row,
        ...grid.justify,
        ...grid.mb_mini,
    },
}

addStyles(sidebarStyles)
export default sidebarStyles

import { addStyles } from 'ethcss'
import grid from 'blocks.simple/grid/grid.jcss'
import { defaultThemeStyles as defaultThemeAppStyles } from 'blocks.app/app/app.theme'
import { defaultThemeStyles as defaultThemeSidebarStyles } from 'blocks.app/sidebar/Sidebar-theme'
import { indents } from 'theme'

const editorAreaSettings = {
    inputWrapper: {
        ...grid.w45,
    },
    row: {
        ...grid.row,
        ...grid.normalCenter,
        ...grid.justify,
        ...grid.p_md,
        ...grid.pb_none,
    },
    buttons: {
        ...grid.row,
        ...grid.center,
    },
    heightAndWidthRow: {
        ...grid.row,
        ...grid.justify,
        ...grid.p_md,
        ...grid.pb_none,
    },
    title: {
        ...grid.p_md,
        ...grid.mb_mdPlus,
        textAlign: 'center',
        color: `var(--var-module-sidebar-component-sidebar-style-title, ${defaultThemeSidebarStyles.title})`,
    },
    collapse: {
        borderRadius: `0!important`,
    },
    collapseText: {
        ...grid.p_md,
        textAlign: 'center',
        color: `var(--var-module-sidebar-component-sidebar-style-title, ${defaultThemeSidebarStyles.title})`,
    },
    collapseTitle: {
        padding: '0!important',
        ...grid.centerImportant,
        borderBottom: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeAppStyles.border})`,
    },
    collapseActive: {
        borderBottom: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeAppStyles.border})`,
    },
    borderTop: {
        borderTop: `1px solid var(--var-module-app-component-app-style-border, ${defaultThemeAppStyles.border})`,
    },
    checkBox: {
        ...grid.pl_md,
        ...grid.pt_md,
    },
    label: {
        textAlign: 'center',
        marginBottom: indents.mini,
    },
    autoPosSelected: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        margin: '24px 0 0 0',
    },
    autoPosSelectedTitle: {
        marginBottom: 10,
    },
}

addStyles(editorAreaSettings)
export default editorAreaSettings

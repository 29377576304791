import React from 'react'
import CheckboxMethods from './checkbox.local.methods'
import Icon from 'blocks.simple/icon/icon'
import styles from './checkbox.jcss'
import { item } from '../../theme'
import { cn } from 'ethcss'
import { getThemeStyleValue } from 'theme/colors'
import { defaultThemeStyles } from './checkbox.theme'

export interface ICheckboxProps {
    wrapperClassName?: string
    className?: string
    style: {}
    mod: string
    disabled: boolean
    labelClassName?: string
    label?: any
    onClick: any
    onDoubleClick: any
    checked: boolean
    animate: boolean
}

const Checkbox = (p_: ICheckboxProps) => {
    const { checked, onClick, onDoubleClick, getClassNames } = CheckboxMethods(p_)
    const mods = ['circle', 'circleInv', 'circleDis', 'white']
    const { areaClassNames, wrapperClassNames } = getClassNames()
    const labelClassName = p_.labelClassName ? p_.labelClassName : ''

    return (
        <div className={cn(p_.wrapperClassName)}>
            <div
                className={cn(...wrapperClassNames)}
                onClick={onClick}
                onDoubleClick={onDoubleClick}
                onMouseDown={(e: React.MouseEvent) => e.stopPropagation()}
            >
                <div className={cn(...areaClassNames)} style={p_.style}>
                    {p_.mod && mods.includes(p_.mod) && checked && (
                        <Icon
                            className={cn({ [item.disabled]: p_.disabled })}
                            type="checked"
                            size="12"
                            color={
                                getThemeStyleValue('ui', 'checkbox', `${p_.mod}-icon`) ||
                                defaultThemeStyles[`${p_.mod}-icon`]
                            }
                        />
                    )}
                </div>
                {p_.label && (
                    <div
                        className={cn({
                            [labelClassName]: p_.labelClassName,
                            [styles.label]: !p_.labelClassName,
                        })}
                    >
                        {p_.label}
                    </div>
                )}
            </div>
        </div>
    )
}

Checkbox.defaultProps = {
    checked: false,
    mod: 'circle',
    disabled: false,
    animate: true,
    style: {},
    onClick: () => {},
    onDoubleClick: () => {},
}

export default Checkbox

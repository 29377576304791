import React from 'react'
import { cn } from 'ethcss'
import item from 'blocks.simple/item/item.jcss'
import { text as textStyles } from 'theme'
import helpersDigitalSignages from 'core/helpers/_digitalSignages'
import helpers from 'core/helpers'
import styles from './styles'
import { getThemeIconValue } from 'theme/colors'
import { defaultFilesIconsTheme } from 'core/helpers/FilesIcons-theme'

const getPlatformLogoUrl = (platform: string, active?: boolean) => {
    let imageName = helpersDigitalSignages.getPlatformName(platform)

    if (active) {
        imageName += '_active'
    }
    if (!imageName) {
        imageName = ''
    }

    return getThemeIconValue('displays', 'platform', imageName) || defaultFilesIconsTheme.getPlatformIcon(imageName)
}

const DeviceSettingsTabs = (p_: {
    isMultipleSettings: boolean
    platform: string
    availablePlatforms: { id: any; name: string }[]
    selectedPlatform: { id: any; name: string } | null
    onClick: (platform: { id: number; name: string }) => void
}) => {
    if (!p_.isMultipleSettings) {
        let platform = p_.platform
        if (p_.platform === 'SSSP 2' || p_.platform === 'SSSP 3') {
            platform = 'sssp_old'
        }
        if (
            p_.platform === 'SSSP 4' ||
            p_.platform === 'SSSP 5' ||
            p_.platform === 'SSSP 6' ||
            p_.platform === 'SSSP 7' ||
            p_.platform === 'SSSP 10'
        ) {
            platform = 'sssp_new'
        }
        if (p_.platform === 'TIZEN 7') {
            platform = 'tizen_7'
        }
        if (
            p_.platform === 'RASPBERRY_V3 x32' ||
            p_.platform === 'RASPBERRY_V3 x64' ||
            p_.platform === 'RASPBERRY_V4 x32' ||
            p_.platform === 'RASPBERRY_V4 x64'
        ) {
            platform = 'raspberry'
        }

        const url = getPlatformLogoUrl(platform)

        return (
            <div className={textStyles.center}>
                <img src={url} style={{ maxWidth: 67 }} width="100%" height="auto" alt={`${p_.platform} logo`} />
            </div>
        )
    }
    if (p_.isMultipleSettings) {
        const platforms = helpers.removeDuplicates(p_.availablePlatforms, 'name')
        return (
            <div className={styles.platformsWrapper}>
                {platforms.map((platform) => {
                    const url = getPlatformLogoUrl(platform.name, p_.selectedPlatform!.id === platform.id)

                    return (
                        <div
                            key={`${platform.name}logo_key`}
                            className={cn(styles.tab, {
                                [item.pointer]: p_.selectedPlatform!.id !== platform.id,
                            })}
                        >
                            <img
                                onClick={
                                    p_.selectedPlatform!.id !== platform.id
                                        ? () => {
                                              p_.onClick(platform)
                                          }
                                        : () => {}
                                }
                                src={url}
                                style={{ maxWidth: 67, margin: '0 auto' }}
                                height="auto"
                                alt={`${platform.name} logo`}
                            />
                        </div>
                    )
                })}
            </div>
        )
    }

    return null
}

export default DeviceSettingsTabs

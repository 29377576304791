import React, { useState } from 'react'
import { Content } from 'organisms/Content'
import Catalog from 'organisms/Catalog'
import { Dialog } from 'molecules/Dialog'
import { isHorizontalMenu } from 'core/helpers/menu'
import { useModal, useSelected } from 'core/hooks'
import styles from './styles'
import translate from 'core/translate'
import Sidebar from 'blocks.app/sidebar/sidebar'
import { api } from 'core/api/ConnectionManager'
import { routes, changeLocation } from 'features/routes'
import { getURLSearchParamsString } from 'features/routes/utils'
import { getSelectedItemsId } from 'core/helpers/user'
import { checkAvailableModule } from '../../../core/helpers/routes/routes'
import { cn } from 'ethcss'
import { colors, grid, text } from '../../../theme'
import Icon from 'blocks.simple/icon/icon'
import Button from 'blocks.simple/button/button'
import { CircleButton } from 'atoms/CircleButton'
import { Permissions } from './create'
import EditableText from 'blocks.simple/editableText/editableText'

interface SelectedItems {
    id: number
    name: string
    label: string
    companyId: number
    actions: string[]
    createdAt: string
    ellipsis?: string
    __view: {
        selected: boolean
        nestedKey: string
    }
}

interface ISelectedInfo {
    id: number
    name: string
    label: string
    companyId: number
    actions: string[]
    createdAt: string
    __view: {
        selected: boolean
        nestedKey: string
    }
    permissions: Permissions[]
    updatedAt: string
}

const Roles = (p_: any) => {
    const {
        selectedInfo,
        selectedItems,
        onSetSelectedInfo,
        onSetSelectedItems,
        isSelectedInstanceOrCollection,
    } = useSelected()
    const horizontalMenu = isHorizontalMenu()
    const agreeForDeleteModal = useModal()

    const validateClonedRole = (number: string | number) => {
        return number !== '' && number >= 1 && number <= 30
    }
    const [activeCloneModal, setActiveCloneModal] = useState<boolean>(false)
    const [cloneNumber, setCloneNumber] = useState<number>(1)

    const CloneModal = ({ id }: { id: number }) => (
        <div className={styles.content}>
            <form
                onSubmit={(e) => {
                    e.preventDefault()
                    api.send('cloneRole', { id, cloneNumber: cloneNumber }).then(() => setActiveCloneModal(false))
                }}
                className={cn(grid.colCenter, grid.p_md)}
            >
                <div className={cn(grid.w80, grid.mb_md)}>{translate('copiesNumber')}:</div>
                <div className={cn(grid.row, grid.justify, grid.colCenter)}>
                    <EditableText
                        type={'number'}
                        mod={'withBorder'}
                        text={cloneNumber}
                        onChange={(cloneNumber: number) => {
                            setCloneNumber(cloneNumber)
                        }}
                        placeholder={translate('emptyFieldError')}
                    />
                    <div className={cn(grid.row, grid.justify, grid.mt_micro, text.redError)}>
                        {!validateClonedRole(cloneNumber) && <p>{translate('cloneError')}</p>}
                    </div>
                    <div className={cn(grid.row, grid.justify, grid.mt_md)}>
                        <Button type="submit" mod="fill" className={cn(grid.mr_mini)}>
                            {translate('yes')}
                        </Button>
                        <Button mod={'withBorder'} onClick={() => setActiveCloneModal(false)}>
                            {translate('no')}
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    )
    const handleOnEditRole = (selectedInfo: ISelectedInfo) => {
        openRoleEdit(selectedInfo)
    }

    const openRoleEdit = (selected: ISelectedInfo) => {
        const search = getURLSearchParamsString({
            roleId: selected.id,
        })

        changeLocation({
            pathname: `/${routes.editRole.path}`,
            search,
        })
    }

    const onCloseInfo = () => {
        if (selectedItems && selectedItems.length) {
            onSetSelectedItems([])

            return
        }

        onSetSelectedInfo(null)
    }

    const onAccept = () => {
        const id = getSelectedItemsId({ selectedInfo, selectedItems })
        if (id) {
            api.send('deleteRole', { roleId: id }).then(() => {})
        }
        agreeForDeleteModal.closeModal()
    }

    const onReject = () => {
        agreeForDeleteModal.closeModal()
    }

    const deleteRoleText = `${
        selectedItems && selectedItems.length > 1
            ? translate('doYouWantToDeleteRoles')
            : translate('doYouWantToDeleteRole')
    }?`

    const rolesList = (selectedItems: SelectedItems[]) => {
        return (
            <div className={styles.listItemBlock}>
                {selectedItems.map((item: SelectedItems) => {
                    return (
                        <div
                            key={item.id}
                            className={cn(grid.p_md, grid.rowCenter, grid.noWrap, styles.sidebarTopListItemWrapper)}
                        >
                            <div className={grid.pr_mini}>
                                <Icon size={'19'} type={'users'} color={colors.blackLight} />
                            </div>
                            <div className={styles.sidebarTopListItem}>
                                <div className={item.ellipsis}>{item.label}</div>
                            </div>
                            <div className={grid.pl_nano}>
                                <CircleButton
                                    onClick={() => {
                                        onSetSelectedItems(
                                            selectedItems.filter(
                                                (deleteElement: SelectedItems) => deleteElement.id !== item.id
                                            )
                                        )
                                    }}
                                    type={'delete'}
                                />
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    const renderToolbarContent = (selectedInfo: ISelectedInfo) => {
        if (!selectedInfo && selectedItems && !selectedItems.length) return null
        return (
            <div className={styles.toolbarInner}>
                <Icon size={'23'} type={'info'} color={colors.blackLight} />
                {selectedItems && selectedItems.length ? (
                    <>
                        {rolesList(selectedItems)}
                        <div
                            onClick={() => {
                                agreeForDeleteModal.openModal()
                            }}
                            className={cn(styles.menuItem, styles.divider)}
                        >
                            {translate('delete')}
                        </div>
                        <div className={styles.space} />
                    </>
                ) : (
                    <>
                        <div className={styles.toolbarRoleName}>{selectedInfo && selectedInfo.label}</div>
                        <div className={styles.toolbarRoleIcon}>
                            {selectedInfo && selectedInfo.label[0].toUpperCase()}
                        </div>
                        <div onClick={() => openRoleEdit(selectedInfo)} className={styles.menuItem}>
                            {translate('edit')}
                        </div>
                        <div onClick={() => setActiveCloneModal(true)} className={styles.menuItem}>
                            {translate('clone')}
                        </div>
                        {activeCloneModal && <CloneModal id={selectedInfo.id} />}
                        <div
                            onClick={() => {
                                agreeForDeleteModal.openModal()
                            }}
                            className={styles.menuItem}
                        >
                            {translate('delete')}
                        </div>
                    </>
                )}
                <div className={cn(grid.p_md, grid.rowCenter, horizontalMenu ? styles.closeBtn_x : styles.closeBtn)}>
                    <Button mod={'withBorder'} onClick={onCloseInfo} indentSize={'mini'} rounded={'full_normal'}>
                        {translate('close')}
                    </Button>
                </div>
            </div>
        )
    }

    return (
        <Content title={translate('listRoles')} contentClassName={styles.createRoleContentWrapper} withToolbar>
            <Catalog
                type="roles"
                onSelectInfo={onSetSelectedInfo}
                selectedInfo={selectedInfo}
                selectedItems={selectedItems}
                onDoubleClickObject={handleOnEditRole}
                onSelect={onSetSelectedItems}
                disableMultiSelect={(list: SelectedItems) => list.id === p_.roleId}
                treeView={false}
                useWindow={false}
                classNameContent={styles.catalogStyles}
            />
            <div
                className={cn(
                    styles.toolbar,
                    selectedInfo ? styles.showToolbar : '',
                    horizontalMenu && styles.toolbarMargin
                )}
            >
                <Sidebar
                    selectedSide={'right'}
                    customContent={{
                        //@ts-ignore
                        right: renderToolbarContent(selectedInfo),
                    }}
                    onSelectInfo={onSetSelectedInfo}
                    selectedInfo={selectedInfo}
                    isEmpty={{
                        right: !selectedInfo,
                    }}
                    emptyText={{
                        right:
                            selectedItems && !selectedItems.length
                                ? {
                                      title: translate('noSelectedRoles'),
                                      description: translate('selectRoleForAction'),
                                  }
                                : null,
                    }}
                    side={'right'}
                    isShowActions={true}
                    showAlwaysCustomContent={true}
                />
            </div>
            <Dialog
                open={agreeForDeleteModal.value}
                onReject={onReject}
                onAccept={onAccept}
                rejectLabel={translate('cancel')}
                acceptLabel={translate('delete')}
            >
                <div className={styles.rolesAcceptDialogText}>{deleteRoleText}</div>
            </Dialog>
        </Content>
    )
}

const exportRoles = checkAvailableModule('users/roles') ? Roles : null

export default exportRoles

import React from 'react'
import PropTypes from 'prop-types'
import EditorFilesMethods from './editor__files.local.methods'
import Catalog from 'blocks.app/catalog/catalog'
import PreviewWebApp from 'blocks.app/preview/__webApp/preview__webApp'
import Button from 'blocks.simple/button/button'
import Modal from 'blocks.simple/modal/modal'
import filesHelpers from 'core/helpers/_files'
import { editor } from '../editor.local.methods'
import { mediaJS, transitions } from 'blocks.app/config'
import translate from 'core/translate'
import { cn } from 'ethcss'
import styles from './editor__files.jcss'
import item from 'blocks.simple/item/item.jcss'
import deepEqual from 'fast-deep-equal'
import { connect } from 'react-redux'
import { activateEditLink, activateEditTable, activateEditText } from 'pages/files/files.state'

class EditorFiles extends EditorFilesMethods {
    static propTypes = {
        minimized: PropTypes.bool,
        isAddText: PropTypes.bool,
        isAddTable: PropTypes.bool,
        query: PropTypes.object,
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !deepEqual(nextProps, this.props) || !deepEqual(nextState, this.state)
    }

    closeContent() {
        this.setState({ selectedItems: [] })

        if (this.props.isMobile) {
            editor.showToolbar(false)
        }
    }

    showAddContentButtons() {
        if (this.props.isMobile) {
            return true
        }
        return !!(this.state.selectedItems && this.state.selectedItems.length)
    }

    render() {
        const p_ = this.props
        const s_ = this.state

        return (
            <React.Fragment>
                <Catalog
                    type="files"
                    searchbarMod={'withShadow'}
                    selectedInfo={s_.selectedInfo}
                    onSelectInfo={this.onSelectInfo}
                    createFolder={true}
                    widgetsGallery={true}
                    classNameContent={cn({ [item.none]: p_.minimized })}
                    useWindow={false}
                    cols={4}
                    onDoubleClickObject={this.editContent}
                    showHeaderButton={true}
                    disposition={s_.disposition}
                    changeDisposition={this.setDisposition}
                    filterItems={[
                        { name: translate('all') },
                        { name: translate('images'), fileType: 'image', type: 'fs' },
                        { name: translate('video'), fileType: 'video', type: 'fs' },
                        { name: translate('music'), fileType: 'audio', type: 'fs' },
                        { name: translate('office'), fileType: 'office', type: 'fs' },
                        { name: translate('links'), type: 'link', fileType: 'web_page', iconName: 'link' },
                        { name: translate('streamMedia'), type: 'link', fileType: 'stream', iconName: 'stream' },
                        { name: translate('text'), type: 'text' },
                        { name: translate('tables'), type: 'table' },
                        { name: translate('web_app'), fileType: 'web_app', type: 'fs' },
                        { name: translate('input'), type: 'input' },
                    ]}
                    hideHeader={p_.minimized}
                    isHideList={filesHelpers.isShowEditor(p_, p_.query)}
                    toggleControls={filesHelpers.isShowEditor(p_, p_.query)}
                    onChange={(list, type) => {
                        if (type === 'update') {
                            editor.updateSources(list)
                        }
                    }}
                    onDragStart={() => {
                        if (mediaJS.tabMini.matches) {
                            clearTimeout(this.dragTimer)
                            this.dragTimer = setTimeout(() => {
                                editor.toggleMinimized()
                            }, transitions.normal)
                        }
                    }}
                    onDragEnd={() => {
                        if (mediaJS.tabMini.matches) {
                            clearTimeout(this.dragTimer)
                        }
                    }}
                    isMobileDragEnabled
                    small
                    viewLocal
                    isMobile={p_.isMobile}
                    onMultipleFilesSelection={p_.onMultipleFilesSelection}
                    onCancelMultipleFilesSelection={p_.onCancelMultipleFilesSelection}
                    selectedItems={s_.selectedItems}
                    onSelect={(selectedItems) => {
                        this.setState({ selectedItems })
                    }}
                />
                {this.showAddContentButtons() && (
                    <div className={styles.multiSelectionPanel}>
                        <Button
                            mod={'fill'}
                            className={styles.contentBtn}
                            onClick={() => {
                                this.closeContent()
                            }}
                        >
                            {translate('cancel')}
                        </Button>
                        <Button
                            mod={'withBorder'}
                            className={styles.contentBtn}
                            disabled={!s_.selectedItems.length}
                            onClick={() => {
                                p_.onMultipleFilesSelection(s_.selectedItems)
                                this.closeContent()
                            }}
                        >
                            {translate('add')}
                        </Button>
                    </div>
                )}
                {p_.location.pathname === '/broadcasts/edit' ||
                p_.location.pathname === '/addContentToDevice' ||
                p_.location.pathname === '/broadcasts/addAdvanced' ? (
                    <Modal open={s_.editWebApp} onClose={this.onCloseEditingWidget}>
                        <PreviewWebApp
                            ref={(previewWebApp) => {
                                this.previewWebApp = previewWebApp
                            }}
                            item={s_.selectedInfo}
                            isEditable={true}
                            indents={'small'}
                            onClose={this.onCloseEditingWidget}
                        />

                        <Button
                            className={styles.button}
                            onClick={() => {
                                this.previewWebApp.save(() => {
                                    this.onCloseEditingWidget()
                                })
                            }}
                        >
                            {translate('save')}
                        </Button>
                    </Modal>
                ) : (
                    <Modal open={s_.isWebAppEdit} onClose={this.onCloseEditingWidget}>
                        <PreviewWebApp
                            ref={(previewWebApp) => {
                                this.previewWebApp = previewWebApp
                            }}
                            onClose={this.onCloseEditingWidget}
                            item={s_.selectedContent}
                            isEditable={true}
                            indents={'small'}
                        />

                        <Button
                            className={cn(styles.button)}
                            onClick={() => {
                                this.previewWebApp.save(() => {
                                    this.onCloseEditingWidget()
                                })
                            }}
                        >
                            {translate('save')}
                        </Button>
                    </Modal>
                )}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isAddLink: state.files.isAddLink,
        isAddTable: state.files.isAddTable,
        isAddText: state.files.isAddText,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        activateEditTable: (id) => dispatch(activateEditTable(id)),
        activateEditLink: (id) => dispatch(activateEditLink(id)),
        activateEditText: (id) => dispatch(activateEditText(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditorFiles)

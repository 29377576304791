import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import DeviceSettingsMethods from './deviceSettings.local.methods'
import DeviceSettingsTabs from './__tabs/deviceSettings__tabs'
import DeviceSettingsProxy from './__proxy/deviceSettings__proxy'
import DeviceSettingsAccept from './__accept/deviceSettings__accept'
import DeviceSettingsPinCode from './__pinCode/deviceSettings__pinCode'
import DeviceSettingsSyncContent from './__syncContent/deviceSettings__syncContent'
import DeviceSettingsBrowserMode from './__browserMode/deviceSettings__browserMode'
import DeviceSettingsTimer from './__timer/deviceSettings__timer'
import DeviceSettingsVideoPlayer from './__videoPlayer/deviceSettings__videoPlayer'
import DeviceSettingsAudioPlayer from './__audioPlayer/deviceSettings__audioPlayer'
import DeviceSettingsWebEngine from './__webEngine/deviceSettings__webEngine'
import DeviceSettingsBrightnessLegacy from './__brightness_legacy/deviceSettings__brightness'
import DeviceSettingsBrightness from './__brightness/deviceSettings__brightness'
import DeviceSettingsCover from './__cover/deviceSettings__cover'
import DeviceSettingsMegafon from './__megafon/deviceSettings__megafon'
import DeviceSettingsAudioOutput from './__audioOutput/deviceSettings__audioOutput'
import DeviceSettingsKioskMode from './__kioskMode/deviceSettings__kioskMode'
import DeviceSettingsRemoteControl from './__remoteControl/deviceSettings__remoteControl'
import DeviceSettingsLogLevel from './__logLevel/deviceSettings__logLevel'
import DeviceSettingsSystemNotifications from './__systemNotifications/deviceSettings__systemNotifications'
import DeviceSettingsContentStorage from './__contentStorage/deviceSettings__contentStorage'
import DeviceSettingsNoSettings from './__noSettings/deviceSettings__noSettings'
import DeviceSettingsPictureMode from './__pictureMode/deviceSettings__pictureMode'
import DeviceSettingsExhibitionMode from './__exhibitionMode/deviceSettings__exhibitionMode'
import { DeviceSettingsFilePath } from './__filePath/deviceSettings__filePath'
import Measure from 'react-measure'
import Collapse from 'blocks.simple/collapse/collapse'
import Colorpicker from 'blocks.simple/colorpicker/colorpicker'
import EditText from 'blocks.simple/editText/editText'
import Button from 'blocks.simple/button/button'
import Checkbox from 'blocks.simple/checkbox/checkbox'
import { Radio } from 'atoms/Radio'
import { Scrollbars } from 'react-custom-scrollbars'
import { cn } from 'ethcss'
import translate from 'core/translate'
import { text as textStyles } from 'theme'
import item from 'blocks.simple/item/item.jcss'
import grid from 'blocks.simple/grid/grid.jcss'
import styles from './deviceSettings.jcss'
import { Typography } from 'atoms/Typography'
import { isExist, isNotEmptyArray } from '../../core/utils/coreUtil'
import { DeviceSettingsDownloadMode } from './DeviceSettingsDownloadMode'
import { emitError } from 'features/appNotifications/AppNotifications.state'
import { DeviceSettingsOnOffTimers } from './DeviceSettingsOnOffTimers'
import { DeviceSettingsNTPServer } from './DeviceSettingsNTPServer'
import { DigitalSignageExhibitionIndex } from './__digitalSignageExhibitionIndex'
import Dropdown from 'blocks.simple/dropdown/dropdown'
import { ComPortsSettings } from './__comPortsSettings/ComPortsSettings'
import { IpTv } from './__ipTv/IpTv'
import { ModBusSettings } from './__modBusSettings/ModBusSettings'
import helpers from '../../core/helpers'

const getTimers = (timers: any, platform: string | undefined) => {
    let data: any = {}
    const availableMultipleTimersPlatforms = [
        'sssp',
        'sssp_new',
        'tizen_7',
        'sssp_old',
        'webos',
        'webos_new',
        'webos_old',
        'android',
        'linux',
    ]

    if (platform && !availableMultipleTimersPlatforms.includes(platform)) {
        data.on = [timers.on[0]]
        data.off = [timers.off[0]]
    } else {
        data = timers
    }

    return data
}

const getAvailableSettingsForPlatform = (platform: any) => {
    const settingsFor = {
        pinCode: ['sssp_old', 'webos_new', 'webos_old'],
        cover: ['windows', 'linux', 'sssp_old', 'sssp_new', 'tizen_7', 'raspberry'],
        brightness_legacy: ['sssp_new', 'tizen_7', 'sssp_old'],
        brightness: ['windows', 'linux', 'webos_new', 'webos_old', 'android', 'raspberry'],
        remoteControlEnabled: ['sssp_new', 'tizen_7', 'sssp_old', 'webos_new', 'webos_old', 'android'],
        kioskMode: ['windows', 'android', 'linux', 'raspberry', 'sssp_new', 'tizen_7', 'webos_new', 'webos_old'],
        audioOutput: ['sssp_old', 'sssp_new', 'tizen_7', 'webos_new', 'webos_old', 'raspberry'],
        systemNotifications: ['sssp_old', 'sssp_new', 'tizen_7'],
        syncContent: [
            'sssp_old',
            'sssp_new',
            'tizen_7',
            'webos_new',
            'webos_old',
            'android',
            'windows',
            'linux',
            'brightsign',
            'raspberry',
        ],
        browserMode: ['windows', 'android', 'linux'],
        exhibitionModeEnabledQ: ['webos_new'],
        digitalSignageExhibitionIndex: ['webos_new'],
        megafon: ['windows', 'linux', 'android', 'raspberry'],
        logLevel: [
            'windows',
            'linux',
            'sssp_new',
            'tizen_7',
            'sssp_old',
            'webos_new',
            'webos_old',
            'android',
            'brightsign',
            'raspberry',
        ],
        pictureMode: ['webos_new', 'webos_old'],
        contentStorage: ['windows'],
        chequeFilePath: ['windows'],
        webEngine: ['windows', 'linux', 'raspberry'],
        contentDownloadType: ['webos_new', 'webos_old'],
        onOffTimers: [
            'sssp_old',
            'sssp_new',
            'tizen_7',
            'webos_new',
            'webos_old',
            'android',
            'windows',
            'linux',
            'raspberry',
        ],
        onOffDisplayTimers: [
            'linux',
            'windows',
            'sssp_old',
            'sssp_new',
            'tizen_7',
            'webos_new',
            'webos_old',
            'android',
        ],
        ntpSettings: ['webos_new', 'sssp_new', 'tizen_7'],
        enableIPTV: [
            'android',
            'sssp_new',
            'tizen_7',
            'sssp_old',
            'webos_new',
            'webos_old',
            'windows',
            'linux',
            'raspberry',
        ],
    }
    const available: any = {}

    for (let key in settingsFor) {
        if (settingsFor.hasOwnProperty(key)) {
            // @ts-ignore
            available[key] = settingsFor[key].includes(platform)
        }
    }

    return available
}

const getPictureModeListForPlatform = (platform: any) => {
    const platformPictureMode = {
        webos: [
            { id: 'normal', name: translate('pictureMode_WebosNormal') },
            { id: 'vivid', name: translate('pictureMode_WebosVivid') },
            { id: 'eco', name: translate('pictureMode_WebosEco') },
            { id: 'cinema', name: translate('pictureMode_WebosCinema') },
            { id: 'game', name: translate('pictureMode_WebosGame') },
            { id: 'sports', name: translate('pictureMode_WebosSports') },
            { id: 'expert1', name: translate('pictureMode_WebosExpert1') },
            { id: 'expert2', name: translate('pictureMode_WebosExpert2') },
        ],
    }

    let availablePictureModes = []

    for (let key in platformPictureMode) {
        if (isExist(platform) && platform.includes(key)) {
            // @ts-ignore
            availablePictureModes = platformPictureMode[key]
        }
    }

    return availablePictureModes
}

class DeviceSettings extends DeviceSettingsMethods {
    static propTypes = {
        isConfirm: PropTypes.bool,
        withoutScroll: PropTypes.bool,
    }
    static defaultProps = {
        isConfirm: false,
        withoutScroll: false,
    }
    renderSidebar = () => {
        if (!this.isAvailablePlatform()) {
            return null
        }

        const p_ = this.props
        const s_ = this.state
        const isMultipleSettings = p_.type === 'group' || s_.isMultipleDevicesSettings

        return (
            <div className={styles.sidebar}>
                <Typography className={cn(styles.sidebarTitle)} type="title">
                    {translate('platform')}
                </Typography>

                <DeviceSettingsTabs
                    isMultipleSettings={isMultipleSettings}
                    platform={p_.platform}
                    availablePlatforms={s_.availablePlatforms}
                    selectedPlatform={s_.selectedPlatform}
                    onClick={this.onSelectPlatform}
                />
                <div />
            </div>
        )
    }

    getTitle = () => {
        const s_ = this.state
        const p_ = this.props
        let title: string | {} = translate('deviceSettings')

        if (p_.type === 'group') {
            title = translate('groupSettings')
        }

        title = `${title} ${p_.name}`

        if (s_.isMultipleDevicesSettings) {
            title = <span dangerouslySetInnerHTML={{ __html: translate('settingsForSelectedDevicesDescription') }} />
        }

        return (
            <Typography className={styles.title} type="title">
                {title}
            </Typography>
        )
    }

    getActivePictureMode = (pictureModeList: { id: string }[]) => {
        if (this.state.settings.pictureMode) {
            return this.state.settings.pictureMode
        }

        if (isNotEmptyArray(pictureModeList)) {
            return pictureModeList[0].id
        }

        return null
    }

    getActiveDownloadMode = (pictureModeList?: string[]) => {
        return this.state.settings.contentDownloadType || 'server'
    }

    render() {
        const p_ = this.props
        const s_ = this.state
        const platform = this.getPlatform()
        const isAvailablePlatform = this.isAvailablePlatform()
        const availableSettings = getAvailableSettingsForPlatform(platform)
        const pictureModeList = getPictureModeListForPlatform(platform)
        const imageParamsModes = [
            { id: 'inside', name: translate('inside') },
            { id: 'outside', name: translate('outside') },
        ]

        return (
            <div className={cn(styles.wrapper, p_.className)}>
                {this.renderSidebar()}
                <Measure bounds onResize={({ bounds }: any) => this.setState({ contentHeight: bounds.height })}>
                    {({ measureRef }) => (
                        <div ref={measureRef} className={styles.content}>
                            <Scrollbars autoHeight autoHeightMax={p_.withoutScroll ? '100%' : s_.contentHeight}>
                                {isAvailablePlatform && (
                                    <div className={styles.deviceSettings__inner}>
                                        {this.getTitle()}
                                        {helpers.isAvailable({ key: 'modBus', action: 'update' }) &&
                                            (platform === 'linux' || platform === 'windows') && (
                                                <Collapse
                                                    title={() => translate('modBusSettings')}
                                                    className={cn(grid.mb_md, grid.fullWidth, styles.collapseBorder)}
                                                >
                                                    <ModBusSettings
                                                        modBus={s_.settings.modBus}
                                                        enabled={true}
                                                        onChange={(modBus: any) => {
                                                            this.setState({
                                                                settings: {
                                                                    ...s_.settings,
                                                                    modBus,
                                                                },
                                                            })
                                                        }}
                                                    />
                                                </Collapse>
                                            )}
                                        {(platform === 'windows' ||
                                            platform === 'linux' ||
                                            platform === 'sssp_new' ||
                                            platform === 'tizen_7' ||
                                            platform === 'webos_old' ||
                                            platform === 'webos_new' ||
                                            platform === 'raspberry') && (
                                            <Collapse
                                                title={() => translate('network')}
                                                className={cn(grid.mb_md, grid.fullWidth, styles.collapseBorder)}
                                            >
                                                {(platform === 'windows' ||
                                                    platform === 'linux' ||
                                                    platform === 'sssp_new' ||
                                                    platform === 'tizen_7' ||
                                                    platform === 'webos_old' ||
                                                    platform === 'webos_new' ||
                                                    platform === 'raspberry') && (
                                                    <DeviceSettingsProxy
                                                        settings={s_.settings.proxySettings}
                                                        platform={platform}
                                                        onChange={(proxySettings) => {
                                                            this.setState({
                                                                settings: {
                                                                    ...s_.settings,
                                                                    proxySettings,
                                                                },
                                                            })
                                                        }}
                                                    />
                                                )}
                                                <DeviceSettingsNTPServer
                                                    title={translate('ntpServer')}
                                                    platform={platform}
                                                    ntpSettings={s_.settings.ntpSettings}
                                                    onChange={this.onChangeSettings}
                                                    enabled={availableSettings.ntpSettings}
                                                />
                                            </Collapse>
                                        )}
                                        <Collapse
                                            title={() => translate('automation')}
                                            className={cn(grid.mb_md, grid.fullWidth, styles.collapseBorder)}
                                        >
                                            <div className={cn(grid.row, grid.fullWidth)}>
                                                <Typography className={cn(textStyles.normal, grid.mb_md)} type="title">
                                                    {translate('rebootTimers')}
                                                </Typography>
                                                <div className={cn(grid.row, grid.fullWidth)}>
                                                    <DeviceSettingsTimer
                                                        timers={{
                                                            on: s_.settings.rebootTimers,
                                                            off: [],
                                                        }}
                                                        type="reboot_timers"
                                                        onChange={({ on }) =>
                                                            this.setState({
                                                                settings: {
                                                                    ...s_.settings,
                                                                    rebootTimers: on,
                                                                },
                                                            })
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <DeviceSettingsOnOffTimers
                                                title={translate('general')}
                                                platform={platform}
                                                timers={getTimers(s_.settings.onOffTimers, platform)}
                                                onChange={this.onChangeSettings}
                                                enabled={availableSettings.onOffTimers}
                                                settingName={'onOffTimers'}
                                            />
                                            {platform !== 'android' && (
                                                <DeviceSettingsOnOffTimers
                                                    platform={platform}
                                                    timers={getTimers(s_.settings.onOffDisplayTimers, platform)}
                                                    onChange={this.onChangeSettings}
                                                    enabled={availableSettings.onOffDisplayTimers}
                                                    settingName={'onOffDisplayTimers'}
                                                    type={'display'}
                                                />
                                            )}
                                            <DeviceSettingsBrightnessLegacy
                                                settings={s_.settings.brightnessTimers}
                                                onChange={this.onChangeSettings}
                                                enabled={availableSettings.brightness_legacy}
                                            />
                                            <DeviceSettingsBrightness
                                                settings={s_.settings.brightness}
                                                onChange={this.onChangeSettings}
                                                enabled={availableSettings.brightness}
                                            />
                                        </Collapse>
                                        {(platform === 'linux' ||
                                            platform === 'raspberry' ||
                                            platform === 'windows') && (
                                            <Collapse
                                                title={() => translate('comPortsTechnicalParameters')}
                                                className={cn(grid.mb_md, grid.fullWidth, styles.collapseBorder)}
                                            >
                                                <ComPortsSettings
                                                    onChange={this.onChangeSettings}
                                                    ports={s_.settings.comPorts}
                                                />
                                            </Collapse>
                                        )}
                                        {(platform === 'windows' ||
                                            platform === 'linux' ||
                                            platform === 'android' ||
                                            platform === 'raspberry') && (
                                            <Collapse
                                                title={() => translate('shop')}
                                                className={cn(grid.mb_md, grid.fullWidth, styles.collapseBorder)}
                                            >
                                                <DeviceSettingsMegafon
                                                    className={styles.row}
                                                    settings={s_.settings.megafon}
                                                    enabled={availableSettings.megafon}
                                                    onChange={this.onChangeSettings}
                                                    platform={platform}
                                                />
                                            </Collapse>
                                        )}
                                        {(platform === 'windows' ||
                                            platform === 'linux' ||
                                            // platform === 'android' ||
                                            platform === 'sssp_old' ||
                                            platform === 'sssp_new' ||
                                            platform === 'tizen_7' ||
                                            platform === 'webos_old' ||
                                            platform === 'webos_new' ||
                                            platform === 'raspberry') && (
                                            <Collapse
                                                title={() => translate('deviceManagement')}
                                                className={cn(grid.mb_md, grid.fullWidth, styles.collapseBorder)}
                                            >
                                                <DeviceSettingsPictureMode
                                                    title={translate('pictureMode')}
                                                    list={pictureModeList}
                                                    active={this.getActivePictureMode(pictureModeList)}
                                                    onChange={this.onChangeSettings}
                                                    enabled={availableSettings.pictureMode}
                                                />
                                                <DeviceSettingsAudioOutput
                                                    className={styles.row}
                                                    value={s_.settings.audioOutput}
                                                    enabled={availableSettings.audioOutput}
                                                    onChange={this.onChangeSettings}
                                                    platform={platform}
                                                />
                                                <DeviceSettingsDownloadMode
                                                    title={translate('downloadMode')}
                                                    active={this.getActiveDownloadMode(pictureModeList)}
                                                    onChange={this.onChangeSettings}
                                                    enabled={availableSettings.contentDownloadType}
                                                />
                                                <DeviceSettingsMegafon
                                                    className={styles.row}
                                                    settings={s_.settings.megafon}
                                                    enabled={availableSettings.megafon}
                                                    onChange={this.onChangeSettings}
                                                    platform={platform}
                                                />
                                                {(platform === 'windows' ||
                                                    platform === 'linux' ||
                                                    platform === 'raspberry') && (
                                                    <div className={cn(grid.row, grid.fullWidth, grid.mt_mdPlus)}>
                                                        <Typography
                                                            className={cn(textStyles.normal, grid.mb_md)}
                                                            type="title"
                                                        >
                                                            {translate('positionInDeviceChain')}
                                                        </Typography>
                                                        <div className={styles.positionWrapper}>
                                                            <div className={styles.positionContent}>
                                                                <div
                                                                    className={cn(grid.row, grid.fullWidth, grid.mb_md)}
                                                                >
                                                                    <EditText
                                                                        mod={'withBorder'}
                                                                        className={cn(textStyles.center)}
                                                                        label={translate('topIndent')}
                                                                        type="number"
                                                                        errorBorder={s_.error === 'top'}
                                                                        value={s_.settings.displaySettings.top}
                                                                        onChange={(top: string) =>
                                                                            this.setState({
                                                                                settings: {
                                                                                    ...s_.settings,
                                                                                    displaySettings: {
                                                                                        ...s_.settings.displaySettings,
                                                                                        top,
                                                                                    },
                                                                                },
                                                                                error: null,
                                                                            })
                                                                        }
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <EditText
                                                                        mod={'withBorder'}
                                                                        className={cn(textStyles.center)}
                                                                        label={translate('width')}
                                                                        type="number"
                                                                        errorBorder={s_.error === 'width'}
                                                                        value={s_.settings.displaySettings.width}
                                                                        onChange={(width: number) =>
                                                                            this.setState({
                                                                                settings: {
                                                                                    ...s_.settings,
                                                                                    displaySettings: {
                                                                                        ...s_.settings.displaySettings,
                                                                                        width,
                                                                                    },
                                                                                },
                                                                                error: null,
                                                                            })
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className={styles.positionContent}>
                                                                <div
                                                                    className={cn(grid.row, grid.fullWidth, grid.mb_md)}
                                                                >
                                                                    <EditText
                                                                        mod={'withBorder'}
                                                                        className={cn(textStyles.center)}
                                                                        label={translate('leftIndent')}
                                                                        type="number"
                                                                        errorBorder={s_.error === 'left'}
                                                                        value={s_.settings.displaySettings.left}
                                                                        onChange={(left: string) =>
                                                                            this.setState({
                                                                                settings: {
                                                                                    ...s_.settings,
                                                                                    displaySettings: {
                                                                                        ...s_.settings.displaySettings,
                                                                                        left,
                                                                                    },
                                                                                },
                                                                                error: null,
                                                                            })
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className={cn(grid.row, grid.fullWidth)}>
                                                                    <EditText
                                                                        mod={'withBorder'}
                                                                        className={cn(textStyles.center)}
                                                                        label={translate('height')}
                                                                        type="number"
                                                                        errorBorder={s_.error === 'height'}
                                                                        value={s_.settings.displaySettings.height}
                                                                        onChange={(height: number) =>
                                                                            this.setState({
                                                                                settings: {
                                                                                    ...s_.settings,
                                                                                    displaySettings: {
                                                                                        ...s_.settings.displaySettings,
                                                                                        height,
                                                                                    },
                                                                                },
                                                                                error: null,
                                                                            })
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                <DeviceSettingsCover
                                                    value={s_.settings.broadcastAutoScreenshot}
                                                    onChange={this.onChangeSettings}
                                                    enabled={availableSettings.cover}
                                                />
                                                {platform === 'windows' && (
                                                    <DeviceSettingsFilePath
                                                        className={styles.row}
                                                        value={s_.settings.chequeFilePath}
                                                        onChange={this.onChangeSettings}
                                                        editableField={'chequeFilePath'}
                                                        enabled={availableSettings.chequeFilePath}
                                                    />
                                                )}
                                                <DeviceSettingsSystemNotifications
                                                    className={styles.row}
                                                    value={s_.settings.hideSystemNotifications}
                                                    enabled={availableSettings.systemNotifications}
                                                    onChange={this.onChangeSettings}
                                                />
                                            </Collapse>
                                        )}

                                        {platform === 'android' && (
                                            <Collapse
                                                title={() => translate('deviceManagement')}
                                                className={cn(grid.mb_md, grid.fullWidth, styles.collapseBorder)}
                                            >
                                                {
                                                    <div className={cn(grid.row, grid.fullWidth, grid.mt_mdPlus)}>
                                                        <Typography
                                                            className={cn(textStyles.normal, grid.mb_md)}
                                                            type="title"
                                                        >
                                                            {translate('renderWindowSizes')}
                                                        </Typography>
                                                        <div className={styles.positionWrapper}>
                                                            <div className={cn(grid.mr_md)}>
                                                                <EditText
                                                                    min={0}
                                                                    mod={'withBorder'}
                                                                    className={cn(textStyles.center)}
                                                                    label={translate('width')}
                                                                    type="number"
                                                                    errorBorder={s_.error === 'width'}
                                                                    value={s_.settings.displaySettings.width}
                                                                    onChange={(width: number) =>
                                                                        this.setState({
                                                                            settings: {
                                                                                ...s_.settings,
                                                                                displaySettings: {
                                                                                    ...s_.settings.displaySettings,
                                                                                    width,
                                                                                    top: 0,
                                                                                    left: 0,
                                                                                },
                                                                            },
                                                                            error: null,
                                                                        })
                                                                    }
                                                                />
                                                            </div>
                                                            <div>
                                                                <EditText
                                                                    min={0}
                                                                    mod={'withBorder'}
                                                                    className={cn(textStyles.center)}
                                                                    label={translate('height')}
                                                                    type="number"
                                                                    errorBorder={s_.error === 'height'}
                                                                    value={s_.settings.displaySettings.height}
                                                                    onChange={(height: number) =>
                                                                        this.setState({
                                                                            settings: {
                                                                                ...s_.settings,
                                                                                displaySettings: {
                                                                                    ...s_.settings.displaySettings,
                                                                                    height,
                                                                                    top: 0,
                                                                                    left: 0,
                                                                                },
                                                                            },
                                                                            error: null,
                                                                        })
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </Collapse>
                                        )}

                                        {(platform === 'windows' ||
                                            platform === 'linux' ||
                                            platform === 'android' ||
                                            platform === 'sssp_old' ||
                                            platform === 'sssp_new' ||
                                            platform === 'tizen_7' ||
                                            platform === 'webos_old' ||
                                            platform === 'webos_new' ||
                                            platform === 'raspberry') && (
                                            <Collapse
                                                title={() => translate('security')}
                                                className={cn(grid.mb_md, grid.fullWidth, styles.collapseBorder)}
                                            >
                                                <DeviceSettingsRemoteControl
                                                    className={styles.row}
                                                    enabled={availableSettings.remoteControlEnabled}
                                                    value={s_.settings.remoteControlEnabled}
                                                    onChange={this.onChangeSettings}
                                                />
                                                <DeviceSettingsPinCode
                                                    className={styles.pinCodeWrapper}
                                                    value={s_.settings.pinCode}
                                                    enabled={availableSettings.pinCode}
                                                    onChange={this.onChangeSettings}
                                                />
                                                <DeviceSettingsKioskMode
                                                    className={styles.row}
                                                    enabled={availableSettings.kioskMode}
                                                    value={s_.settings.controlsMode}
                                                    onChange={this.onChangeSettings}
                                                />
                                            </Collapse>
                                        )}
                                        {(platform === 'windows' ||
                                            platform === 'linux' ||
                                            platform === 'android' ||
                                            platform === 'sssp_old' ||
                                            platform === 'sssp_new' ||
                                            platform === 'tizen_7' ||
                                            platform === 'webos_old' ||
                                            platform === 'webos_new' ||
                                            platform === 'raspberry') && (
                                            <Collapse
                                                title={() => translate('content')}
                                                className={cn(grid.mb_md, grid.fullWidth, styles.collapseBorder)}
                                            >
                                                <DeviceSettingsSyncContent
                                                    enabled={availableSettings.syncContent}
                                                    className={styles.syncContentWrapper}
                                                    value={s_.settings.syncContent}
                                                    onChange={this.onChangeSettings}
                                                    type={p_.type}
                                                />
                                                {platform === 'windows' && (
                                                    <div className={cn(grid.fullWidth, grid.mt_mdPlus)}>
                                                        <Typography className={cn(textStyles.normal)} type="title">
                                                            {translate('speedLimitDownloadingContent')}
                                                        </Typography>
                                                        <div className={cn(grid.row, grid.mt_md, grid.mb_md)}>
                                                            <Checkbox
                                                                label={translate('on')}
                                                                checked={!!s_.settings.speedLimit}
                                                                className={cn(grid.mr_md)}
                                                                onClick={() =>
                                                                    this.setState({
                                                                        settings: {
                                                                            ...s_.settings,
                                                                            speedLimit: 64,
                                                                        },
                                                                    })
                                                                }
                                                            />
                                                            <Checkbox
                                                                label={translate('off')}
                                                                checked={!s_.settings.speedLimit}
                                                                onClick={() =>
                                                                    this.setState({
                                                                        settings: {
                                                                            ...s_.settings,
                                                                            speedLimit: null,
                                                                        },
                                                                    })
                                                                }
                                                            />
                                                        </div>
                                                        <div
                                                            className={cn(
                                                                styles.deviceSettings__inputWrapper_type_speedLimit,
                                                                {
                                                                    [item.disableWrapper]: !s_.settings.speedLimit,
                                                                }
                                                            )}
                                                        >
                                                            <EditText
                                                                mod={'withBorder'}
                                                                className={cn(textStyles.center)}
                                                                type="number"
                                                                value={s_.settings.speedLimit || ''}
                                                                onChange={(speedLimit: number) =>
                                                                    this.setState({
                                                                        settings: {
                                                                            ...s_.settings,
                                                                            speedLimit,
                                                                        },
                                                                    })
                                                                }
                                                                onBlur={(speedLimit: number) => {
                                                                    const settings = {
                                                                        ...s_.settings,
                                                                        speedLimit: 64,
                                                                    }

                                                                    if (speedLimit >= 64) {
                                                                        settings.speedLimit = speedLimit
                                                                    }

                                                                    this.setState({
                                                                        settings,
                                                                    })
                                                                }}
                                                            />
                                                            <div
                                                                className={cn(
                                                                    textStyles.mini,
                                                                    textStyles.darkCloud,
                                                                    grid.mt_mini,
                                                                    textStyles.center
                                                                )}
                                                            >
                                                                {translate('speedLimitDescription')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                <DeviceSettingsContentStorage
                                                    className={styles.row}
                                                    value={s_.settings.contentStoragePath}
                                                    onChange={this.onChangeSettings}
                                                    enabled={availableSettings.contentStorage}
                                                />
                                                {(platform === 'windows' ||
                                                    platform === 'linux' ||
                                                    platform === 'raspberry') &&
                                                    this.getDeviceSettingsFromCompany() && (
                                                        <div className={cn(grid.row, grid.fullWidth, grid.mt_mdPlus)}>
                                                            <Typography
                                                                className={cn(textStyles.normal, grid.mb_md)}
                                                                type="title"
                                                            >
                                                                {translate('downloadFiles')}
                                                            </Typography>
                                                            <div className={cn(grid.row, grid.fullWidth)}>
                                                                {this.getDeviceSettingsFromCompany().map(
                                                                    (type: string) => (
                                                                        <div key={`content_download_${type}`}>
                                                                            <Radio
                                                                                className={
                                                                                    styles.deviceSettings__input_type_contentDownloadType
                                                                                }
                                                                                onClick={() =>
                                                                                    this.setState({
                                                                                        settings: {
                                                                                            ...s_.settings,
                                                                                            contentDownloadType: type,
                                                                                        },
                                                                                    })
                                                                                }
                                                                                selected={
                                                                                    s_.settings.contentDownloadType ===
                                                                                    type
                                                                                }
                                                                                label={translate(type)}
                                                                            />
                                                                        </div>
                                                                    )
                                                                )}
                                                            </div>
                                                            {s_.settings.contentDownloadType === 'netDisk' && (
                                                                <div
                                                                    className={
                                                                        styles.deviceSettings__inputWrapper_type_netDiskAddress
                                                                    }
                                                                >
                                                                    <EditText
                                                                        mod={'withBorder'}
                                                                        label={translate('netDiskAddress')}
                                                                        value={s_.settings.netDiskAddress}
                                                                        placeholder={'\\\\127.0.0.1\\example'}
                                                                        onChange={(netDiskAddress: string) => {
                                                                            this.setState({
                                                                                settings: {
                                                                                    ...s_.settings,
                                                                                    netDiskAddress,
                                                                                },
                                                                            })
                                                                        }}
                                                                    />
                                                                    {p_.type !== 'group' && (
                                                                        <div className={grid.mt_mdPlus}>
                                                                            <Typography
                                                                                className={cn(
                                                                                    textStyles.normal,
                                                                                    grid.mb_md
                                                                                )}
                                                                                type="title"
                                                                            >
                                                                                {translate('netDiskDefaultMaster')}
                                                                            </Typography>
                                                                            <div
                                                                                className={cn(grid.row, grid.fullWidth)}
                                                                            >
                                                                                <Radio
                                                                                    className={cn(grid.mr_md)}
                                                                                    onClick={() =>
                                                                                        this.setState({
                                                                                            settings: {
                                                                                                ...s_.settings,
                                                                                                netDiskDefaultMaster: true,
                                                                                            },
                                                                                        })
                                                                                    }
                                                                                    selected={
                                                                                        s_.settings.netDiskDefaultMaster
                                                                                    }
                                                                                    label={translate('yes')}
                                                                                />
                                                                                <Radio
                                                                                    onClick={() =>
                                                                                        this.setState({
                                                                                            settings: {
                                                                                                ...s_.settings,
                                                                                                netDiskDefaultMaster: false,
                                                                                            },
                                                                                        })
                                                                                    }
                                                                                    selected={
                                                                                        !s_.settings
                                                                                            .netDiskDefaultMaster
                                                                                    }
                                                                                    label={translate('no')}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                {(platform === 'windows' ||
                                                    platform === 'linux' ||
                                                    platform === 'raspberry') && (
                                                    <div className={cn(grid.row, grid.fullWidth, grid.mt_mdPlus)}>
                                                        <Typography
                                                            className={cn(textStyles.normal, grid.mb_md)}
                                                            type="title"
                                                        >
                                                            {translate('videoPlayerSettings')}
                                                        </Typography>
                                                        <div className={cn(grid.row, grid.fullWidth)}>
                                                            <DeviceSettingsVideoPlayer
                                                                settings={s_.settings.videoPlayerSettings}
                                                                onChange={(videoPlayerSettings) => {
                                                                    this.setState({
                                                                        settings: {
                                                                            ...s_.settings,
                                                                            videoPlayerSettings,
                                                                        },
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                                <DeviceSettingsWebEngine
                                                    settings={s_.settings.webEngineSettings}
                                                    onChange={this.onChangeSettings}
                                                    enabled={availableSettings.webEngine}
                                                />
                                                <IpTv
                                                    enableIPTV={s_.settings.enableIPTV}
                                                    onChange={(value) => {
                                                        this.setState({
                                                            settings: {
                                                                ...s_.settings,
                                                                enableIPTV: value,
                                                            },
                                                        })
                                                    }}
                                                    enabled={availableSettings.enableIPTV}
                                                />
                                            </Collapse>
                                        )}
                                        {platform === 'android' && (
                                            <Collapse
                                                title={() => translate('audioPlayer')}
                                                className={cn(grid.mb_md, grid.fullWidth, styles.collapseBorder)}
                                            >
                                                <div className={cn(grid.row, grid.fullWidth, grid.mt_mdPlus)}>
                                                    <div className={cn(grid.row, grid.fullWidth)}>
                                                        <DeviceSettingsAudioPlayer
                                                            settings={s_.settings.audioPlayerSettings}
                                                            onChange={(audioPlayerSettings) => {
                                                                this.setState({
                                                                    settings: {
                                                                        ...s_.settings,
                                                                        audioPlayerSettings,
                                                                    },
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </Collapse>
                                        )}
                                        {(platform === 'sssp_new' ||
                                            platform === 'tizen_7' ||
                                            platform === 'sssp_old' ||
                                            platform === 'brightsign' ||
                                            platform === 'android' ||
                                            platform === 'webos_old' ||
                                            platform === 'webos_new') && (
                                            <Collapse
                                                title={() => translate('imageResizing')}
                                                className={cn(grid.mb_md, grid.fullWidth, styles.collapseBorder)}
                                            >
                                                <div className={cn(grid.mb_md)}>
                                                    <Typography
                                                        className={cn(textStyles.normal, grid.mb_md)}
                                                        type="title"
                                                    >
                                                        {translate('resizeAnImage')}
                                                    </Typography>
                                                    <div className={cn(grid.row, grid.fullWidth)}>
                                                        <Radio
                                                            className={cn(grid.mr_md)}
                                                            onClick={() =>
                                                                this.setState({
                                                                    settings: {
                                                                        ...s_.settings,
                                                                        resizeImageQ: true,
                                                                    },
                                                                })
                                                            }
                                                            selected={s_.settings.resizeImageQ}
                                                            label={translate('yes')}
                                                        />
                                                        <Radio
                                                            onClick={() =>
                                                                this.setState({
                                                                    settings: {
                                                                        ...s_.settings,
                                                                        resizeImageQ: false,
                                                                    },
                                                                })
                                                            }
                                                            selected={!s_.settings.resizeImageQ}
                                                            label={translate('no')}
                                                        />
                                                    </div>
                                                </div>

                                                <div className={cn(grid.mb_md)}>
                                                    <Typography
                                                        className={cn(textStyles.normal, grid.mb_md)}
                                                        type="title"
                                                    >
                                                        {translate('maintainProportions')}
                                                    </Typography>
                                                    <div className={cn(grid.row, grid.fullWidth)}>
                                                        <Radio
                                                            disabled={!s_.settings.resizeImageQ && true}
                                                            className={cn(grid.mr_md)}
                                                            onClick={() =>
                                                                this.setState({
                                                                    settings: {
                                                                        ...s_.settings,
                                                                        resizeImageParams: {
                                                                            ...s_.settings.resizeImageParams,
                                                                            preserveAspectRatioQ: true,
                                                                            mode: 'inside',
                                                                            backgroundColor: '#000000',
                                                                        },
                                                                    },
                                                                })
                                                            }
                                                            selected={
                                                                s_.settings.resizeImageParams &&
                                                                s_.settings.resizeImageParams.preserveAspectRatioQ
                                                            }
                                                            label={translate('yes')}
                                                        />
                                                        <Radio
                                                            disabled={!s_.settings.resizeImageQ && true}
                                                            onClick={() =>
                                                                this.setState({
                                                                    settings: {
                                                                        ...s_.settings,
                                                                        resizeImageParams: {
                                                                            preserveAspectRatioQ: false,
                                                                        },
                                                                    },
                                                                })
                                                            }
                                                            selected={
                                                                s_.settings.resizeImageParams &&
                                                                !s_.settings.resizeImageParams.preserveAspectRatioQ
                                                            }
                                                            label={translate('no')}
                                                        />
                                                    </div>
                                                </div>

                                                <div className={cn(grid.mb_md)}>
                                                    <Typography
                                                        className={cn(textStyles.normal, grid.mb_md)}
                                                        type="title"
                                                    >
                                                        {translate('fitCut')}
                                                    </Typography>

                                                    <div className={styles.dropdownWrapper}>
                                                        <Dropdown
                                                            disabled={
                                                                !(
                                                                    s_.settings.resizeImageQ &&
                                                                    s_.settings.resizeImageParams &&
                                                                    s_.settings.resizeImageParams.preserveAspectRatioQ
                                                                )
                                                            }
                                                            placeholder={translate('inside')}
                                                            value={
                                                                s_.settings.resizeImageParams &&
                                                                s_.settings.resizeImageParams.preserveAspectRatioQ
                                                                    ? s_.settings.resizeImageParams.mode
                                                                    : s_.settings.resizeImageParams &&
                                                                      s_.settings.resizeImageParams.mode &&
                                                                      s_.settings.resizeImageParams.mode
                                                            }
                                                            options={imageParamsModes}
                                                            onChange={(e) =>
                                                                s_.settings.resizeImageParams.mode !== 'inside'
                                                                    ? this.setState({
                                                                          settings: {
                                                                              ...s_.settings,
                                                                              resizeImageParams: {
                                                                                  ...s_.settings.resizeImageParams,
                                                                                  mode: e.id,
                                                                              },
                                                                          },
                                                                      })
                                                                    : this.setState({
                                                                          settings: {
                                                                              ...s_.settings,
                                                                              resizeImageParams: {
                                                                                  preserveAspectRatioQ: true,
                                                                                  mode: e.id,
                                                                              },
                                                                          },
                                                                      })
                                                            }
                                                        />
                                                    </div>
                                                </div>

                                                <div className={cn(grid.mb_md)}>
                                                    <Typography
                                                        className={cn(textStyles.normal, grid.mb_md)}
                                                        type="title"
                                                    >
                                                        {translate('resizingBackgroundColor')}
                                                    </Typography>
                                                    <div className={cn(grid.row, grid.fullWidth)}>
                                                        <Colorpicker
                                                            disableAlpha
                                                            disabled={
                                                                !(
                                                                    s_.settings.resizeImageQ &&
                                                                    s_.settings.resizeImageParams &&
                                                                    s_.settings.resizeImageParams.mode === 'inside'
                                                                )
                                                            }
                                                            style={{ borderRight: 'none' }}
                                                            onChange={(value) =>
                                                                this.setState({
                                                                    settings: {
                                                                        ...s_.settings,
                                                                        resizeImageParams: {
                                                                            ...s_.settings.resizeImageParams,
                                                                            backgroundColor: value,
                                                                        },
                                                                    },
                                                                })
                                                            }
                                                            color={
                                                                s_.settings.resizeImageParams &&
                                                                s_.settings.resizeImageParams.mode === 'inside' &&
                                                                s_.settings.resizeImageParams.backgroundColor
                                                            }
                                                            side={p_.small ? 'right' : false}
                                                            title={translate('generalBackground')}
                                                        />
                                                    </div>
                                                </div>
                                            </Collapse>
                                        )}
                                        {platform === 'webos_new' && (
                                            <Collapse
                                                title={() => translate('exhibitionMode')}
                                                className={cn(grid.mb_md, grid.fullWidth, styles.collapseBorder)}
                                            >
                                                <DeviceSettingsExhibitionMode
                                                    enabled={availableSettings.exhibitionModeEnabledQ}
                                                    className={styles.exhibitionModeContentWrapper}
                                                    value={s_.settings.exhibitionModeEnabledQ}
                                                    onChange={this.onChangeSettings}
                                                    type={p_.type}
                                                />
                                                <DigitalSignageExhibitionIndex
                                                    title={translate('digitalSignageExhibitionIndex')}
                                                    platform={platform}
                                                    digitalSignageExhibitionIndex={
                                                        s_.settings.digitalSignageExhibitionIndex
                                                    }
                                                    onChange={this.onChangeSettings}
                                                    enabled={availableSettings.digitalSignageExhibitionIndex}
                                                />
                                            </Collapse>
                                        )}
                                        {(platform === 'linux' || platform === 'windows' || platform === 'android') && (
                                            <Collapse
                                                title={() => translate('browser')}
                                                className={cn(grid.mb_md, grid.fullWidth, styles.collapseBorder)}
                                            >
                                                <DeviceSettingsBrowserMode
                                                    enabled={availableSettings.browserMode}
                                                    className={styles.syncContentWrapper}
                                                    value={s_.settings.browserSettings.openBrowser}
                                                    onChange={this.onChangeSettings}
                                                />
                                            </Collapse>
                                        )}
                                        <Collapse
                                            title={() => translate('development')}
                                            className={cn(grid.mb_md, grid.fullWidth, styles.collapseBorder)}
                                        >
                                            <DeviceSettingsLogLevel
                                                className={styles.row}
                                                value={s_.settings.logLevel}
                                                enabled={availableSettings.logLevel}
                                                onChange={this.onChangeSettings}
                                            />
                                        </Collapse>
                                        <div className={styles.buttons}>
                                            {isAvailablePlatform && (
                                                <div className={cn(grid.row, grid.w100)}>
                                                    {p_.type === 'group' && (
                                                        <Checkbox
                                                            wrapperClassName={cn(grid.mt_md)}
                                                            checked={s_.applyRecursively}
                                                            label={this.getApplyRecursivelyLabel()}
                                                            onClick={(checked: boolean) =>
                                                                this.setState({
                                                                    applyRecursively: checked,
                                                                })
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            )}
                                            {!s_.isMultipleDevicesSettings && (
                                                <div className={cn(grid.row, grid.justify)}>
                                                    <Button
                                                        mod={'withBorder'}
                                                        className={cn(grid.mt_md, grid.ml_md, grid.mr_md)}
                                                        disabled={p_.settings && Object.keys(p_.settings).length === 0}
                                                        onClick={() =>
                                                            this.getSettings(
                                                                p_.settings,
                                                                p_.platformId || s_.selectedPlatform!.id
                                                            )
                                                        }
                                                    >
                                                        {translate('restoreSettings')}
                                                    </Button>
                                                </div>
                                            )}
                                            <Button
                                                className={cn(grid.mt_md, grid.ml_md, grid.mr_md)}
                                                mod="fill"
                                                onClick={
                                                    p_.isConfirm
                                                        ? () =>
                                                              this.setState({
                                                                  modalState: true,
                                                              })
                                                        : this.saveSettings
                                                }
                                            >
                                                {translate('editDisplay')}
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </Scrollbars>
                            {!isAvailablePlatform && <DeviceSettingsNoSettings />}
                        </div>
                    )}
                </Measure>
                <DeviceSettingsAccept
                    open={s_.modalState}
                    onCancel={this.onCloseAcceptModal}
                    onAccept={this.saveSettings}
                />
            </div>
        )
    }
}

const mapStateToProps = (state: { user: { company: { settings: any } } }) => ({
    deviceSettings: state.user.company.settings,
})

const mapDispatchToProps = (dispatch: any) => {
    return {
        emitError: (err: string) => dispatch(emitError(err)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceSettings)

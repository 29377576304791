export enum ApiPostEvents {
    login = 'login',
    registration = 'user/registration',
    recovery = 'send_reset_password_link',
    setNewPassword = 'reset_password',
    signup = 'user',
    uploadFile = 'v2/upload/file',
    uploadMultiplyFiles = 'source/v1/multiple-upload',
    uploadBroadcast = 'v1/broadcast/import',
    uploadFont = 'v2/upload/font',
    uploadAvatar = 'user/photo',
    createUser = 'user/create',
    updateUser = 'user/update',
    parseCsv = 'v2/upload/parse-csv',
    validateEmail = 'user/validate',
    uploadVideoEditor = 'v2/upload/video-editor',
    uploadDigitalSignageAndGroupMedia = 'v2/upload/digital-signage-and-group-media',
    newTestDownloads = 'test/download-progress/start',
    newTestStopDownlaods = 'test/download-progress/stop',
    catalogizatorUploadCsvFile = 'v1/cataloguer/art/upload-csv',
    uploadFirmware = 'v2/upload/digital-signage-firmware',
    uploadClientApp = 'v2/upload/digital-signage-distributive',
    uploadSchoolSchedule = 'v1/school-schedule/upload',
    requestLicense = 'root/v1/companies/licenses/request',
    uploadLicenseFile = 'root/v1/licenses/registerEncrypted',
    uploadChannelsAndCategoryCsvFile = 'root/v1/iptv/csv/upload',
}

export enum ApiEvents {
    refreshJwt = 'core_v1_frontend_refresh_jwt',
    getDiskUsageStats = 'core_v1_frontend_get_disk_usage_stats',
    getBroadcastRestrictionsForSchedule = 'core_v1_frontend_get_broadcast_restrictions_for_schedule',
    join = 'join',
    approveDisplay = 'approve_digital_signage_with_token',
    createDigitalSignage = 'core_v1_frontend_create_digital_signage',
    getAvailableParentBroadcastFolders = 'core_v2_frontend_get_available_parent_broadcast_folders',
    getAvailableFoldersFiles = 'core_v2_frontend_get_available_parent_source_folders',
    getAvailableToCreateParentSourceFolders = 'core_v2_frontend_get_available_to_create_parent_source_folders',
    getDsTags = 'core_v1_frontend_get_ds_tags',
    createDsTag = 'core_v1_frontend_create_ds_tag',
    updateDsTag = 'core_v1_frontend_update_ds_tag',
    deleteDsTag = 'core_v1_frontend_delete_ds_tag',
    editDisplay = 'update_digital_signage_from_frontend',
    moveDsAndGroups = 'core_v1_frontend_move_ds_and_groups',
    getDisplays = 'send_digital_signages_event',
    getDisplay = 'core_v1_get_digital_signage_by_id',
    getGroup = 'core_v1_frontend_get_group_by_id',
    getDisplaysAndGroups = 'core_v1_frontend_get_groups_and_digital_signages',
    getDisplaysAndGroupsByParentId = 'core_v1_frontend_get_ds_and_group_by_parent',
    getDisplaysAndGroupsByFilters = 'core_v1_frontend_get_ds_and_group_by_filters',
    getDraftDisplays = 'core_v1_frontend_get_draft_digital_signage',
    requestDisplayAndroidPermissions = 'core_v1_frontend_request_digital_signage_android_permissions',
    getWebAppPreviewUrl = 'core_v1_frontend_get_web_app_preview_url',
    updateWebApp = 'core_v1_frontend_update_web_app',
    createGroup = 'core_v1_frontend_create_group',
    editGroup = 'core_v1_frontend_update_group',
    updateScreenshotCb = 'core_v1_frontend_update_screenshot',
    deleteDisplay = 'delete_digital_signage',
    deleteGroup = 'core_v1_frontend_delete_group',
    shutdownDisplay = 'core_v1_frontend_ds_shutdown',
    rebootDisplay = 'ds:reboot',
    updatePlayer = 'core_v1_frontend_update_player',
    getGroupBreadcrumbs = 'core_v2_frontend_get_group_breadcrumbs',
    changeVolume = 'core_v1_frontend_change_volume',
    getMenu = 'core_v1_frontend_get_menu',
    getFilesAndFolders = 'core_v1_frontend_get_files',
    getFolderBreadcrumbs = 'core_v1_frontend_get_folder_breadcrumbs',
    getFile = 'core_v1_frontend_get_source_by_id',
    getFolder = 'core_v1_frontend_get_folder_by_id',
    updateFile = 'core_v1_frontend_update_source',
    updateFolder = 'core_v1_frontend_update_folder',
    deleteFile = 'core_v1_frontend_delete_source',
    createFolder = 'core_v1_frontend_create_folder',
    deleteFolder = 'core_v1_frontend_delete_folder',
    tipViewed = 'core_v1_frontend_tip_viewed',
    deleteBroadcast = 'core_v1_frontend_delete_broadcast',
    validateBroadcastTitle = 'core_v1_frontend_validate_broadcast_title',
    getSources = 'core_v1_frontend_get_sources',
    getDisplaysFromGroups = 'core_v1_frontend_get_ds_by_ds_id_and_group_id',
    createSchedule = 'core_v1_frontend_create_schedule',
    updateSchedule = 'core_v1_frontend_update_schedule',
    getSchedule = 'core_v1_frontend_get_schedule_by_id',
    getBroadcast = 'core_v1_frontend_get_broadcast_by_id',
    updateBroadcast = 'core_v1_frontend_update_broadcast',
    search = 'core_v1_frontend_search',
    getSchedules = 'core_v1_frontend_get_schedules',
    deleteSchedule = 'core_v1_delete_schedule',
    cloneBroadcast = 'core_v1_frontend_clone_broadcast',
    cloneFile = 'core_v1_frontend_clone_source',
    cloneRole = 'core_v1_frontend_clone_role',
    createSource = 'core_v1_frontend_create_source',
    createBroadcast = 'core_v1_frontend_create_broadcast',
    getCustomListView = 'core_v1_frontend_get_custom_list_view',
    updateCustomListView = 'core_v1_frontend_update_custom_list_view',
    resetCustomListView = 'core_v1_frontend_reset_custom_list_view',
    getFilterValues = 'core_v1_frontend_get_ds_and_groups_filter_values',
    moveFilesAndFolders = 'core_v2_frontend_move_files_and_folders',
    validateAddContentToDisplay = 'core_v1_frontend_validate_quick_add_content_to_broadcast',
    addContentToDisplay = 'core_v1_frontend_quick_add_content_to_broadcast',
    getCompany = 'core_v1_frontend_get_company',
    updateCompany = 'core_v1_frontend_update_company',
    //updateUserSettings = 'core_v1_frontend_update_user_setting', //deprecated
    updateUserSettings = 'core_v1_frontend_update_user',
    cancelBroadcastDownload = 'core_v1_frontend_cancel_broadcast_download',
    getUsers = 'core_v1_frontend_get_users',
    getRoles = 'core_v3_frontend_rbac_get_roles',
    getRoleById = 'core_v1_frontend_rbac_get_role_by_id',
    updateRole = 'core_v2_frontend_update_role',
    deleteRole = 'core_v2_frontend_rbac_delete_role',
    roleCreated = 'undefined',
    updateUser_legacy = 'core_v1_frontend_update_user',
    getUserById = 'core_v1_frontend_get_user_by_id',
    getUserConnections = 'core_v1_frontend_get_user_connections',
    deleteUser = 'core_v1_frontend_delete_user',
    createRole = 'core_v2_frontend_rbac_create_role',
    saveGroupSettings = 'core_v1_frontend_save_group_settings',
    getCompanyAdBlocks = 'advertisers_v1_frontend_get_company_ad_blocks',
    deleteCompanyAdBlock = 'advertisers_v1_frontend_delete_company_ad_block',
    applyAdBlockToAllDigitalSignage = 'advertisers_v1_apply_ad_block_to_all_digital_signage',
    hasCompanyCreatedFillersAdBlock = 'advertising_v1_frontend_has_company_created_fillers_ad_block',
    getAdBlocks = 'advertisers_v2_frontend_get_ad_blocks',
    deleteAdBlock = 'advertisers_v1_frontend_delete_ad_block',
    getAdvertisements = 'advertisers_v1_frontend_get_advertisements',
    createAdvertisingCampaign = 'advertisers_v3_frontend_create_advertising_campaign',
    createAdvertisement = 'advertisers_v1_frontend_create_advertisement',
    updateAdvertisingCampaign = 'advertisers_v3_frontend_update_advertising_campaign',
    updateAdvertisement = 'advertisers_v1_frontend_update_advertisement',
    getAdvertisingCampaignsConflicts = 'advertisers_v1_frontend_get_advertising_campaign_conflicts',
    getAdvertisementConflicts = 'advertisers_v1_frontend_get_advertisement_conflicts',
    getFillersAdBlockConflicts = 'advertisers_v1_frontend_get_fillers_ad_block_conflicts',
    getAdvertisingCampaigns = 'advertisers_v1_frontend_get_advertising_campaigns',
    getBroadcastStats = 'core_v1_frontend_source_play_stats',
    getContentProperties = 'core_v1_frontend_get_source_properties',
    getAdvertisingCampaign = 'advertisers_v1_frontend_get_advertising_campaign_by_id',
    getAdvertisement = 'advertisers_v1_frontend_get_advertisement_by_id',
    deleteAdvertisingCampaign = 'advertisers_v1_frontend_delete_advertising_campaign',
    deleteAdvertisement = 'advertisers_v1_frontend_delete_advertisement',
    digitalSignageSendRestartApp = 'core_v1_frontend_digital_signage_send_restart_app',
    getPlatforms = 'core_v1_frontend_get_platforms',
    getNotificationsSettingsForDsOrGroup = 'core_v1_frontend_get_notifications_settings_for_ds_or_group',
    updateNotificationsSettingsForDsOrGroup = 'core_v1_frontend_update_notifications_settings_for_ds_or_group',
    getNotifications = 'core_v1_frontend_get_notifications',
    getNotificationsCount = 'core_v1_frontend_get_notification_count',
    deleteNotifications = 'core_v1_frontend_delete_notification',
    readNotifications = 'core_v1_frontend_read_notification',
    createTimetable = 'school_v1_frontend_create_timetable',
    updateTimetable = 'school_v1_frontend_update_timetable',
    deleteTimetable = 'school_v1_frontend_delete_timetable',
    getTimetable = 'school_v1_frontend_get_timetable',
    getLessonsInDateRange = 'school_v1_frontend_get_lessons_in_date_range',
    getCourses = 'school_v1_frontend_get_courses',
    getCourse = 'school_v1_frontend_get_course_by_id',
    createCourse = 'school_v1_frontend_create_course',
    updateCourse = 'school_v1_frontend_update_course',
    deleteCourse = 'school_v1_frontend_delete_course',
    getReportTasks = 'core_v1_frontend_get_report_tasks',
    getReportTask = 'core_v1_frontend_get_report_task_by_id',
    createReportTask = 'core_v1_frontend_create_report_task',
    deleteReportTask = 'core_v1_frontend_delete_report_task',
    getTeachers = 'school_v1_frontend_get_teachers',
    getTeacher = 'school_v1_frontend_get_teacher_by_id',
    createTeacher = 'school_v1_frontend_create_teacher',
    updateTeacher = 'school_v1_frontend_update_teacher',
    deleteTeacher = 'school_v1_frontend_delete_teacher',
    getGrades = 'school_v1_frontend_get_grades',
    getGrade = 'school_v1_frontend_get_grade_by_id',
    createGrade = 'school_v1_frontend_create_grade',
    updateGrade = 'school_v1_frontend_update_grade',
    deleteGrade = 'school_v1_frontend_delete_grade',
    getSubjects = 'school_v1_frontend_get_subjects',
    getSubject = 'school_v1_frontend_get_subject_by_id',
    createSubject = 'school_v1_frontend_create_subject',
    updateSubject = 'school_v1_frontend_update_subject',
    deleteSubject = 'school_v1_frontend_delete_subject',
    getWines = 'price_list_v1_frontend_get_wine_info',
    deleteWines = 'price_list_v1_frontend_delete_wine_info',
    getProductMenus = 'price_list_v1_frontend_get_product_menu',
    createProductMenu = 'price_list_v1_frontend_create_product_menu',
    updateProductMenu = 'price_list_v1_frontend_update_product_menu',
    deleteProductMenus = 'price_list_v1_frontend_delete_product_menu',
    getMenuProducts = 'price_list_v1_frontend_get_menu_product',
    createMenuProduct = 'price_list_v1_frontend_create_product_menu',
    updateMenuProduct = 'price_list_v1_frontend_update_menu_product',
    deleteMenuProducts = 'price_list_v1_frontend_delete_menu_product',
    getProducts = 'price_list_v2_frontend_get_price_list',
    getProductsUpdateInfo = 'price_list_v1_frontend_get_update_info',
    getClassrooms = 'school_v1_frontend_get_classrooms',
    getClassroom = 'school_v1_frontend_get_classroom_by_id',
    createClassroom = 'school_v1_frontend_create_classroom',
    updateClassroom = 'school_v1_frontend_update_classroom',
    deleteClassroom = 'school_v1_frontend_delete_classroom',
    cancelClientDownload = 'core_v1_frontend_cancel_client_download',
    getDownloads = 'core_v1_frontend_get_download_progress',
    createTemplate = 'core_v1_frontend_create_template',
    setDigitalSignageDate = 'core_v1_frontend_set_digital_signage_date',
    registerDraftDevice = 'core_v1_frontend_register_draft_digital_signage',
    getTemplates = 'core_v1_frontend_get_templates',
    getTemplate = 'core_v1_frontend_get_template_by_id',
    deleteTemplate = 'core_v1_frontend_delete_template',
    getFonts = 'core_v1_frontend_get_fonts',
    deleteFont = 'core_v1_frontend_delete_font',
    getRssContent = 'core_v1_frontend_get_rss_content',
    clearDigitalSignageDiskSpace = 'core_v1_frontend_clear_digital_signage_disk_space',
    retryRotateVideo = 'core_v1_frontend_retry_rotate_video',
    getBurgersCatalogByType = 'burgers_v1_frontend_get_burgers_catalog_by_type',
    createBurgersProduct = 'burgers_v1_frontend_create_burgers_product',
    updateBurgersProduct = 'burgers_v1_frontend_update_burgers_product',
    deleteBurgersProduct = 'burgers_v1_frontend_delete_burgers_product',
    updateBurgersWebApp = 'burgers_frontend_v1_update_burgers_web_app',
    deleteMultipleAccounts = 'core_v1_frontend_delete_multiple_accounts',
    newFeatureViewed = 'core_v1_frontend_new_feature_viewed',
    getInstancesCatalogList = 'core_v1_frontend_get_instances_catalog_list',
    getUsersActionsHistory = 'core_v1_frontend_get_users_actions_history',
    deleteDigitalSignageScreenshot = 'core_v1_frontend_delete_digital_signage_screenshot',
    getDigitalSignageScreenshots = 'core_v1_frontend_get_digital_signage_screenshots',
    getPlaybackState = 'core_v1_frontend_get_playback_state',
    samsoniteGetCatalog = 'samsonite_frontend_v1_get_catalog',
    samsoniteCreateCatalogItem = 'samsonite_frontend_v1_create_catalog_item',
    samsoniteUpdateCatalog = 'samsonite_frontend_v1_update_catalog',
    samsoniteUpdateCatalogItem = 'samsonite_frontend_v1_update_catalog_item',
    samsoniteDeleteCatalogItem = 'samsonite_frontend_v1_delete_catalog_item',
    samsoniteUpdateWebApp = 'samsonite_frontend_v1_update_web_app',
    askonaGetCatalog = 'askona_frontend_v1_get_catalog',
    askonaCreateCatalogItem = 'askona_frontend_v1_create_catalog_item',
    askonaUpdateCatalog = 'askona_frontend_v1_update_catalog',
    askonaUpdateCatalogItem = 'askona_frontend_v1_update_catalog_item',
    askonaDeleteCatalogItem = 'askona_frontend_v1_delete_catalog_item',
    askonaUpdateWebApp = 'askona_frontend_v1_update_web_app',
    pitamixGetCatalog = 'pitamix_frontend_v1_get_catalog',
    pitamixUpdateCatalog = 'pitamix_frontend_v1_update_catalog',
    pitamixUpdateWebApp = 'pitamix_frontend_v1_update_web_app',
    pitamixCreateCatalogCategory = 'pitamix_frontend_v1_create_catalog_category',
    pitamixUpdateCatalogCategory = 'pitamix_frontend_v1_update_catalog_category',
    pitamixDeleteCatalogCategory = 'pitamix_frontend_v1_delete_catalog_category',
    getGroupVolume = 'core_v1_frontend_get_group_volume',
    testDownloads = 'testDownloads',
    getGroupSchedules = 'core_v1_frontend_get_group_schedules',
    getOverlappingSchedules = 'core_v1_frontend_get_overlapping_schedules_and_solutions',
    hasBroadcastSchedules = 'core_v1_frontend_has_broadcast_an_actual_schedule',
    getBroadcastsAndFolders = 'core_v1_frontend_get_broadcasts_and_folders',
    getBroadcastFolder = 'core_v1_frontend_get_broadcast_folder_by_id',
    createBroadcastFolder = 'core_v1_frontend_create_broadcast_folder',
    updateBroadcastFolder = 'core_v1_frontend_update_broadcast_folder',
    deleteBroadcastFolder = 'core_v1_frontend_delete_broadcast_folder',
    moveToBroadcastFolder = 'core_v2_frontend_move_to_broadcast_folder',
    getBroadcastFolderBreadcrumbs = 'core_v1_frontend_get_broadcast_folder_breadcrumbs',
    getTimezonesList = 'core_v1_frontend_get_timezones_list',
    getServerInfo = 'core_v1_frontend_get_server_info',
    getDsUpdateTasks = 'core_v1_frontend_get_ds_update_tasks',
    createDsUpdateTask = 'core_v1_frontend_create_ds_update_task',
    updateDsUpdateTask = 'core_v1_frontend_update_ds_update_task',
    deleteDsUpdateTask = 'core_v1_frontend_delete_ds_update_task',
    cancelDsUpdateTask = 'core_v1_frontend_cancel_ds_update_task',
    getUsersActionsFilterValues = 'core_v1_frontend_get_users_actions_filter_values',
    getCompanyPlatforms = 'core_v1_frontend_get_company_platforms',
    getVideoEditorTasks = 'core_v1_frontend_get_video_editor_tasks',
    getVideoEditorTaskById = 'core_v1_frontend_get_video_editor_task_by_id',
    deleteVideoEditorTask = 'core_v1_frontend_delete_video_editor_task',
    createVideoEditorTaskFromSource = 'core_v1_frontend_create_video_editor_task_from_source',
    saveSourceFromVideoEditorTask = 'core_v2_frontend_save_source_from_video_editor_task',
    executeVideoEditorTask = 'core_v1_frontend_execute_video_editor_task',
    getSourceTags = 'core_v1_frontend_get_source_tags',
    createSourceTag = 'core_v1_frontend_create_source_tag',
    updateSourceTag = 'core_v1_frontend_update_source_tag',
    deleteSourceTag = 'core_v1_frontend_delete_source_tag',
    getTodayNetStat = 'core_v1_frontend_get_today_net_stat',
    deleteDigitalSignageAndGroupMedia = 'core_v1_frontend_delete_digital_signage_and_group_media',
    getDigitalSignageAndGroupMedia = 'core_v1_frontend_get_digital_signage_and_group_media',
    createSourceFromUrl = 'core_v1_frontend_create_source_from_url',
    dashboardGetUserStats = 'core_v1_frontend_dashboard_get_user_stats',
    dashboardGetDigitalSignageStats = 'core_v1_frontend_dashboard_get_digital_signage_stats',
    dashboardGetScheduleStats = 'core_v1_frontend_dashboard_get_schedule_stats',
    getUnusedSources = 'core_v1_frontend_get_unused_sources',
    activeDirectoryFindUserByUsername = 'core_v1_frontend_active_directory_find_user_by_username',
    getFillersAdBlock = 'advertisers_v1_frontend_get_fillers_ad_blocks',
    getFillersAdBlockById = 'advertisers_v1_frontend_get_fillers_ad_block_by_id',
    createFillersAdBlock = 'advertisers_v1_frontend_create_fillers_ad_block',
    updateFillersAdBlock = 'advertisers_v1_frontend_update_fillers_ad_block',
    deleteFillersAdBlock = 'advertisers_v1_frontend_delete_fillers_ad_block',
    editScoreboard = 'neftm_v1_frontend_update_scoreboard',
    updateScoreboardFolder = 'neftm_v1_frontend_update_scoreboard_folder',
    getScoreboards = 'neftm_v1_frontend_get_scoreboards',
    getScoreboardsCreateAvailableParentFolders = 'neftm_v1_frontend_get_available_to_create_parent_scoreboard_folders',
    getScoreboardsMoveAvailableParentFolders = 'neftm_v1_frontend_get_available_to_move_parent_scoreboard_folders',
    getScoreboardById = 'neftm_v1_frontend_get_scoreboard_by_id',
    getScoreboardFolderById = 'neftm_v1_frontend_get_scoreboard_folder_by_id',
    createScoreboardFolder = 'neftm_v1_frontend_create_scoreboard_folder',
    createScoreboard = 'neftm_v1_frontend_create_scoreboard',
    updateScoreboard = 'neftm_v1_frontend_update_scoreboard',
    deleteScoreboard = 'neftm_v1_frontend_delete_scoreboard',
    deleteScoreboardFolder = 'neftm_v1_frontend_delete_scoreboard_folder',
    updateScoreboardsInfoByFolderId = 'neftm_v1_frontend_update_scoreboard_by_folder_id',
    getScoreboardsBreadcrumbs = 'neftm_v1_frontend_get_scoreboard_folder_breadcrumbs',
    getScoreboardsAndFolders = 'neftm_v1_frontend_get_scoreboards_and_folders',
    getScoreboardsAndFoldersWithoutPagination = 'neftm_v1_frontend_get_scoreboards_and_folders_without_pagination',
    getScoreboardInfo = 'neftm_v1_frontend_get_scoreboard_info',
    getNetworkConverterInfo = 'neftm_v1_frontend_get_scoreboard_s2e_info',
    saveNetworkConverterInfo = 'neftm_v1_frontend_save_scoreboard_s2e_settings',
    restartScoreboard = 'neftm_v1_frontend_reboot_scoreboard_s2e',
    getSdLabels = 'neftm_v1_frontend_get_sd_labels',
    setTagsToSource = 'core_v1_frontend_set_tags_to_source',
    getAvailableParentGroups = 'core_v2_frontend_get_available_parent_groups',
    getAvailableToCreateParentGroups = 'core_v2_frontend_get_available_to_create_parent_groups',
    getAvailableToCreateParentBroadcastFolders = 'core_v2_frontend_get_available_to_create_parent_broadcast_folders',
    sendConfirmationEmail = 'core_v1_frontend_send_confirmation_email',
    getConfirmationEmailInfo = 'core_v1_frontend_get_confirmation_email_info',
    sendConfirmationEmailCode = 'core_v1_frontend_confirm_email',
    getStunServers = 'core_v1_frontend_video_chat_get_stun_servers',
    createWebRTCConnection = 'core_v1_frontend_video_chat_create_connection',
    resetWebRTCConnection = 'core_v1_frontend_video_chat_reset_connection',
    sendWebRTCPayload = 'core_v1_frontend_video_chat_send_payload',
    sendIdentification = 'core_v1_frontend_ds_identification',
    dsTagCreated = 'core_v1_frontend_ds_tag_created',
    dsTagUpdated = 'core_v1_frontend_ds_tag_updated',
    dsTagDeleted = 'core_v1_frontend_ds_tag_deleted',
    getDisplayAndroidPermissions = 'core_v1_frontend_get_digital_signage_android_permissions',
    webAppPreviewUrlCreated = 'core_v1_frontend_web_app_preview_url_created',
    webAppBuildingStatusChanged = 'core_v1_frontend_web_app_building_status_changed',
    updateScreenshot = 'core_v1_frontend_update_screenshot',
    displayCreated = 'core_v1_frontend_digital_signage_created',
    displayUpdated = 'core_v1_frontend_digital_signage_updated',
    displayDeleted = 'core_v1_frontend_digital_signage_deleted',
    groupCreated = 'core_v1_frontend_group_created',
    groupUpdated = 'core_v1_frontend_group_updated',
    groupDeleted = 'core_v1_frontend_group_deleted',
    broadcastCreated = 'core_v1_frontend_broadcast_created',
    broadcastUpdated = 'core_v1_frontend_broadcast_updated',
    broadcastDeleted = 'core_v1_frontend_broadcast_deleted',
    scheduleCreated = 'core_v1_frontend_schedule_created',
    scheduleUpdated = 'core_v1_frontend_schedule_updated',
    scheduleDeleted = 'core_v1_frontend_schedule_deleted',
    fileUpdated = 'core_v1_frontend_source_updated',
    fileCreated = 'core_v1_frontend_source_created',
    fileDeleted = 'core_v1_frontend_source_deleted',
    folderUpdated = 'core_v1_frontend_source_folder_updated',
    folderCreated = 'core_v1_frontend_source_folder_created',
    folderDeleted = 'core_v1_frontend_source_folder_deleted',
    forceLogout = 'core_v1_frontend_force_logout',
    roleDeleted = 'core_v2_frontend_role_deleted',
    roleUpdated = 'core_v2_frontend_role_updated',
    userCreated = 'core_v1_frontend_user_created',
    userUpdated = 'core_v1_frontend_user_updated',
    userDeleted = 'core_v1_frontend_user_deleted',
    getClientLogs = 'core_v1_frontend_get_client_logs',
    advertisingCampaignCreated = 'advertisers_v1_frontend_advertising_campaign_created',
    advertisingCampaignUpdated = 'advertisers_v1_frontend_advertising_campaign_updated',
    advertisingCampaignDeleted = 'advertisers_v1_frontend_advertising_campaign_deleted',
    advertisementCreated = 'advertisers_v1_frontend_advertisement_created',
    advertisementUpdated = 'advertisers_v1_frontend_advertisement_updated',
    advertisementDeleted = 'advertisers_v1_frontend_advertisement_deleted',
    fDsGetState = 'f_ds_get_state',
    courseCreated = 'school_v1_frontend_course_created',
    courseUpdated = 'school_v1_frontend_course_updated',
    courseDeleted = 'school_v1_frontend_course_deleted',
    reportTaskCreated = 'core_v1_frontend_report_task_created',
    reportTaskUpdated = 'core_v1_frontend_report_task_updated',
    reportTaskDeleted = 'core_v1_frontend_report_task_deleted',
    teacherCreated = 'school_v1_frontend_teacher_created',
    teacherUpdated = 'school_v1_frontend_teacher_updated',
    teacherDeleted = 'school_v1_frontend_teacher_deleted',
    gradeCreated = 'school_v1_frontend_grade_created',
    gradeUpdated = 'school_v1_frontend_grade_updated',
    gradeDeleted = 'school_v1_frontend_grade_deleted',
    subjectCreated = 'school_v1_frontend_subject_created',
    subjectUpdated = 'school_v1_frontend_subject_updated',
    subjectDeleted = 'school_v1_frontend_subject_deleted',
    winesDeleted = 'price_list_v1_frontend_wine_info_deleted',
    winesCreated = 'price_list_v1_frontend_wine_info_created',
    winesUpdated = 'price_list_v1_frontend_wine_info_updated',
    productMenuCreated = 'price_list_v1_frontend_product_menu_created',
    productMenuUpdated = 'price_list_v1_frontend_product_menu_updated',
    productMenusDeleted = 'price_list_v1_frontend_product_menu_deleted',
    menuProductCreated = 'price_list_v1_frontend_menu_product_created',
    menuProductUpdated = 'price_list_v1_frontend_menu_product_updated',
    menuProductsDeleted = 'price_list_v1_frontend_menu_product_deleted',
    classroomCreated = 'school_v1_frontend_classroom_created',
    classroomUpdated = 'school_v1_frontend_classroom_updated',
    classroomDeleted = 'school_v1_frontend_classroom_deleted',
    downloadStarted = 'core_v1_frontend_download_progress_started',
    downloadUpdated = 'core_v1_frontend_download_progress_updated',
    digitalSignageScreenshotCreated = 'core_v1_frontend_digital_signage_screenshot_created',
    burgersProductCreated = 'burgers_v1_frontend_burgers_product_created',
    burgersProductUpdated = 'burgers_v1_frontend_burgers_product_updated',
    burgersProductDeleted = 'burgers_v1_frontend_burgers_product_deleted',
    burgersCatalogUpdated = 'burgers_v1_frontend_burgers_catalog_updated',
    userActionsHistoryCreated = 'core_v1_frontend_user_actions_history_created',
    digitalSignageScreenshotDeleted = 'core_v1_frontend_digital_signage_screenshot_deleted',
    onPlaybackStateChange = 'core_v1_frontend_get_playback_state',
    samsoniteItemCreated = 'samsonite_frontend_v1_item_created',
    samsoniteItemUpdated = 'samsonite_frontend_v1_item_updated',
    samsoniteItemDeleted = 'samsonite_frontend_v1_item_deleted',
    samsoniteCatalogUpdated = 'samsonite_frontend_v1_catalog_updated',
    askonaItemCreated = 'askona_frontend_v1_item_created',
    askonaItemUpdated = 'askona_frontend_v1_item_updated',
    askonaItemDeleted = 'askona_frontend_v1_item_deleted',
    askonaCatalogUpdated = 'askona_frontend_v1_catalog_updated',
    pitamixCatalogUpdated = 'pitamix_frontend_v1_catalog_updated',
    pitamixCatalogCategoryCreated = 'pitamix_frontend_v1_catalog_category_created',
    pitamixCatalogCategoryUpdated = 'pitamix_frontend_v1_catalog_category_updated',
    pitamixCatalogCategoryDeleted = 'pitamix_frontend_v1_catalog_category_deleted',
    broadcastFolderCreated = 'core_v1_frontend_broadcast_folder_created',
    broadcastFolderUpdated = 'core_v1_frontend_broadcast_folder_updated',
    broadcastFolderDeleted = 'core_v1_frontend_broadcast_folder_deleted',
    dsUpdateTaskCreated = 'core_v1_frontend_ds_update_task_created',
    dsUpdateTaskUpdated = 'core_v1_frontend_ds_update_task_updated',
    dsUpdateTaskDeleted = 'core_v1_frontend_ds_update_task_deleted',
    videoEditorTaskDeleted = 'core_v1_frontend_video_editor_task_deleted',
    videoEditorTaskCreated = 'core_v1_frontend_video_editor_task_created',
    videoEditorTaskUpdated = 'core_v1_frontend_video_editor_task_updated',
    videoEditorTaskProgressUpdated = 'core_v1_frontend_video_editor_task_progress_updated',
    sourceTagCreated = 'core_v1_frontend_source_tag_created',
    sourceTagUpdated = 'core_v1_frontend_source_tag_updated',
    sourceTagDeleted = 'core_v1_frontend_source_tag_deleted',
    digitalSignageAndGroupMediaDeleted = 'core_v1_frontend_digital_signage_and_group_media_deleted',
    digitalSignageAndGroupMediaCreated = 'core_v1_frontend_digital_signage_and_group_media_created',
    fillersAdBlockCreated = 'advertisers_v1_frontend_fillers_ad_block_created',
    fillersAdBlockUpdated = 'advertisers_v1_frontend_fillers_ad_block_updated',
    fillersAdBlockDeleted = 'advertisers_v1_frontend_fillers_ad_block_deleted',
    scoreboardCreated = 'neftm_v1_frontend_scoreboard_created',
    scoreboardUpdated = 'neftm_v1_frontend_scoreboard_updated',
    scoreboardDeleted = 'neftm_v1_frontend_scoreboard_deleted',
    onResetWebRTCConnection = 'core_v1_frontend_video_chat_connection_reset',
    onAcceptWebRTCConnection = 'core_v1_frontend_video_chat_connection_accepted',
    onVideoChatPayload = 'core_v1_frontend_video_chat_payload_received',
    getPlatformsDistributives = 'core_v1_frontend_get_last_client_applications',
    getInstalledApplicationList = 'core_v1_frontend_request_installed_application_list',
    getAuthorsList = 'cataloguer_frontend_v1_get_authors',
    updateAuthor = 'cataloguer_frontend_v1_update_author',
    createAuthor = 'cataloguer_frontend_v1_create_author',
    deleteAuthors = 'cataloguer_frontend_v1_delete_authors',
    authorCreated = 'cataloguer_frontend_v1_author_created',
    authorUpdated = 'cataloguer_frontend_v1_author_updated',
    authorsDeleted = 'cataloguer_frontend_v1_authors_deleted',
    getArtsList = 'cataloguer_frontend_v1_get_arts',
    updateArt = 'cataloguer_frontend_v1_update_art',
    createArt = 'cataloguer_frontend_v1_create_art',
    deleteArts = 'cataloguer_frontend_v1_delete_arts',
    artCreated = 'cataloguer_frontend_v1_art_created',
    artUpdated = 'cataloguer_frontend_v1_art_updated',
    artsDeleted = 'cataloguer_frontend_v1_arts_deleted',
    getAuthorsNames = 'cataloguer_frontend_v1_get_authors_names',
    getHistoryPage = 'chronology_v1_frontend_get_history_page',
    createHistoryPage = 'chronology_v1_frontend_create_history_page',
    updateHistoryPage = 'chronology_v1_frontend_update_history_page',
    deleteHistoryPage = 'chronology_v1_frontend_delete_history_page',
    historyPageCreated = 'chronology_v1_frontend_history_page_created',
    historyPageUpdated = 'chronology_v1_frontend_history_page_updated',
    historyPageDeleted = 'chronology_v1_frontend_history_page_deleted',
    getLensData = 'tree_catalog_v1_frontend_get_catalog',
    createLensData = 'tree_catalog_v1_frontend_create_catalog',
    updateLensData = 'tree_catalog_v1_frontend_update_catalog',
    deleteLensData = 'tree_catalog_v1_frontend_delete_catalog',
    lensDataCreated = 'tree_catalog_v1_frontend_catalog_created',
    lensDataUpdated = 'tree_catalog_v1_frontend_catalog_updated',
    lensDataDeleted = 'tree_catalog_v1_frontend_catalog_deleted',
    getExhibition = 'exhibition_v1_frontend_get_exhibition',
    createExhibition = 'exhibition_v1_frontend_create_exhibition',
    updateExhibition = 'exhibition_v1_frontend_update_exhibition',
    deleteExhibition = 'exhibition_v1_frontend_delete_exhibition',
    exhibitionCreated = 'exhibition_v1_frontend_exhibition_created',
    exhibitionUpdated = 'exhibition_v1_frontend_exhibition_updated',
    exhibitionDeleted = 'exhibition_v1_frontend_exhibition_deleted',
    getExhibitionPages = 'exhibition_v1_frontend_get_exhibition_page',
    createExhibitionPage = 'exhibition_v1_frontend_create_exhibition_page',
    updateExhibitionPage = 'exhibition_v1_frontend_update_exhibition_page',
    deleteExhibitionPage = 'exhibition_v1_frontend_delete_exhibition_page',
    exhibitionPageCreated = 'exhibition_v1_frontend_exhibition_page_created',
    exhibitionPageUpdated = 'exhibition_v1_frontend_exhibition_page_updated',
    exhibitionPageDeleted = 'exhibition_v1_frontend_exhibition_page_deleted',
    catalogizatorGetCsvParsingStatus = 'cataloguer_frontend_v1_arts_get_csv_parsing_state',
    catalogizatorCsvUploadStateChanged = 'cataloguer_frontend_v1_arts_csv_parsing_state_changed',
    requestBroadcastInfo = 'core_v1_frontend_request_broadcast_info',
    requestBroadcastInfoWithFiles = 'core_v1_frontend_request_broadcast_info_with_files',
    broadcastInfoSent = 'core_v1_frontend_broadcast_info_sent',
    requestBroadcastInfoWithFilesSent = 'core_v1_frontend_broadcast_info_with_files_sent',
    getScoreboardsStats = 'core_v1_frontend_get_scoreboards_stats',
    getSurveyList = 'photo_booth_frontend_v1_list_surveys',
    moveHistoryPage = 'chronology_v1_frontend_move_history_page',
    wakeOnLanDigitalSignage = 'core_v1_frontend_try_to_wake_digital_signage',
    requestUpdateTaskInfo = 'core_v1_frontend_request_update_task_info',
    playContent = 'core_v1_frontend_play_content',
    getTriggerButton = 'core_v1_frontend_get_trigger_button',
    updateTriggerButton = 'core_v1_frontend_update_trigger_button',
    getQuizQuestions = 'quiz_v1_frontend_get_question',
    updateQuizQuestion = 'quiz_v1_frontend_update_question',
    createQuizQuestion = 'quiz_v1_frontend_create_question',
    deleteQuizQuestions = 'quiz_v1_frontend_delete_question',
    getDsAndGroupFilters = 'core_v1_frontend_get_ds_and_group_filters',
    getExternalStoragesInfo = 'core_v1_frontend_request_external_storages_info',
    deleteScreenshots = 'core_v1_frontend_delete_screenshots',
    deleteWebAppPreviews = 'core_v1_frontend_delete_web_app_previews',
    createBroadcastExportTask = 'core_v1_frontend_create_broadcast_export_task',
    getBroadcastExportState = 'core_v1_frontend_get_broadcast_export_state',
    startOnlineMonitoring = 'core_v1_frontend_start_online_monitoring',
    stopOnlineMonitoring = 'core_v1_frontend_stop_online_monitoring',
    getSynchronizedDigitalSignages = 'core_v1_frontend_get_ds_sync_group',
    updateDSSyncGroup = 'core_v1_frontend_update_ds_sync_group',
    getLicenseByFilters = 'core_v1_frontend_get_license_by_filters',
    getLicenseDSStats = 'core_v1_frontend_get_license_ds_stats',
    getFirmwares = 'core_v1_frontend_get_ds_firmwares',
    updateFirmware = 'core_v1_frontend_update_ds_firmware',
    deleteFirmware = 'core_v1_frontend_delete_ds_firmware',
    getDsBuildTypes = 'core_v1_frontend_get_ds_build_types',
    getWidgets = 'widget_gallery_v1_get_widgets_list',
    installWidget = 'widget_gallery_v1_install_widget',
    getSchoolSchedules = 'school_schedule_v1_frontend_get_schedules',
    getSchoolClasses = 'school_schedule_v1_frontend_get_classes',
    schoolScheduleGetCSVParsingStatus = 'school_schedule_v1_frontend_get_csv_parsing_status',
    displayOn = 'core_v1_frontend_ds_display_on',
    displayOff = 'core_v1_frontend_ds_display_off',
    webEngineInfo = 'core_v1_frontend_get_web_engine_info',
    activateGiftLicense = 'core_v1_frontend_activate_gift_license',
    disconnectSupport = 'core_v1_frontend_disconnect_support',
    getChannelList = 'iptv_frontend_v1_channel_list',
    createChannel = 'iptv_frontend_v1_channel_create',
    updateChannel = 'iptv_frontend_v1_channel_update',
    deleteChannel = 'iptv_frontend_v1_channel_delete',
    getCategoryList = 'iptv_frontend_v1_category_list',
    createCategory = 'iptv_frontend_v1_category_create',
    updateCategory = 'iptv_frontend_v1_category_update',
    deleteCategory = 'iptv_frontend_v1_category_delete',
    iptvGetCsv = 'iptv_v1_frontend_csv_get',
    getDSWithAdvertisements = 'core_v1_frontend_get_ds_online_with_advertisement',
    getAdvertisementGridByDSId = 'core_v1_frontend_get_broadcast_grid',
    getLicensesPdf = 'core_v1_frontend_get_licenses_pdf',
    stopRemoteDeviceStream = 'core_v1_frontend_remote_device_stream_stop',
    sendRemoteDeviceEvent = 'core_v1_frontend_remote_device_event',
    getPictogram = 'core_v1_frontend_get_pictogram',
    getEmailNotifications = 'core_v1_frontend_get_email_notifications',
    deleteEmailNotifications = 'core_v1_frontend_delete_email_notifications',
    webrtcHandshake = 'core_v1_frontend_webrtc_handshake',
    webrtcIceCandidate = 'core_v1_frontend_webrtc_ice_candidate',
    checkNewServerAccess = 'core_v1_frontend_check_new_server_access',
    getStorageInfo = 'core_v1_frontend_get_storage_info',
    moveToNewServer = 'core_v1_frontend_move_to_new_server',
    createAiContent = 'core_v1_frontend_create_ai_content',
    getAiContent = 'core_v1_frontend_get_ai_content',
    saveAiContent = 'core_v1_frontend_save_ai_content',
    cancelAiContent = 'core_v1_frontend_cancel_ai_content',
}

export enum ApiListenEvents {
    dsTagCreated = 'core_v1_frontend_ds_tag_created',
    dsTagUpdated = 'core_v1_frontend_ds_tag_updated',
    dsTagDeleted = 'core_v1_frontend_ds_tag_deleted',
    getDisplayAndroidPermissions = 'core_v1_frontend_get_digital_signage_android_permissions',
    webAppPreviewUrlCreated = 'core_v1_frontend_web_app_preview_url_created',
    webAppBuildingStatusChanged = 'core_v1_frontend_web_app_building_status_changed',
    updateScreenshot = 'core_v1_frontend_update_screenshot',
    displayCreated = 'core_v1_frontend_digital_signage_created',
    displayUpdated = 'core_v1_frontend_digital_signage_updated',
    displayDeleted = 'core_v1_frontend_digital_signage_deleted',
    groupCreated = 'core_v1_frontend_group_created',
    groupUpdated = 'core_v1_frontend_group_updated',
    groupDeleted = 'core_v1_frontend_group_deleted',
    broadcastCreated = 'core_v1_frontend_broadcast_created',
    broadcastUpdated = 'core_v1_frontend_broadcast_updated',
    broadcastDeleted = 'core_v1_frontend_broadcast_deleted',
    scheduleCreated = 'core_v1_frontend_schedule_created',
    scheduleUpdated = 'core_v1_frontend_schedule_updated',
    scheduleDeleted = 'core_v1_frontend_schedule_deleted',
    fileUpdated = 'core_v1_frontend_source_updated',
    fileCreated = 'core_v1_frontend_source_created',
    fileDeleted = 'core_v1_frontend_source_deleted',
    folderUpdated = 'core_v1_frontend_source_folder_updated',
    folderCreated = 'core_v1_frontend_source_folder_created',
    folderDeleted = 'core_v1_frontend_source_folder_deleted',
    forceLogout = 'core_v1_frontend_force_logout',
    roleDeleted = 'core_v2_frontend_role_deleted',
    roleUpdated = 'core_v2_frontend_role_updated',
    userCreated = 'core_v1_frontend_user_created',
    userUpdated = 'core_v1_frontend_user_updated',
    userDeleted = 'core_v1_frontend_user_deleted',
    getClientLogs = 'core_v1_frontend_get_client_logs',
    advertisingCampaignCreated = 'advertisers_v1_frontend_advertising_campaign_created',
    advertisingCampaignUpdated = 'advertisers_v1_frontend_advertising_campaign_updated',
    advertisingCampaignDeleted = 'advertisers_v1_frontend_advertising_campaign_deleted',
    advertisementCreated = 'advertisers_v1_frontend_advertisement_created',
    advertisementUpdated = 'advertisers_v1_frontend_advertisement_updated',
    advertisementDeleted = 'advertisers_v1_frontend_advertisement_deleted',
    fDsGetState = 'f_ds_get_state',
    courseCreated = 'school_v1_frontend_course_created',
    courseUpdated = 'school_v1_frontend_course_updated',
    courseDeleted = 'school_v1_frontend_course_deleted',
    reportTaskCreated = 'core_v1_frontend_report_task_created',
    reportTaskUpdated = 'core_v1_frontend_report_task_updated',
    reportTaskDeleted = 'core_v1_frontend_report_task_deleted',
    teacherCreated = 'school_v1_frontend_teacher_created',
    teacherUpdated = 'school_v1_frontend_teacher_updated',
    teacherDeleted = 'school_v1_frontend_teacher_deleted',
    gradeCreated = 'school_v1_frontend_grade_created',
    gradeUpdated = 'school_v1_frontend_grade_updated',
    gradeDeleted = 'school_v1_frontend_grade_deleted',
    roleCreated = 'core_v2_frontend_role_created',
    subjectCreated = 'school_v1_frontend_subject_created',
    subjectUpdated = 'school_v1_frontend_subject_updated',
    subjectDeleted = 'school_v1_frontend_subject_deleted',
    winesDeleted = 'price_list_v1_frontend_wine_info_deleted',
    winesCreated = 'price_list_v1_frontend_wine_info_created',
    winesUpdated = 'price_list_v1_frontend_wine_info_updated',
    productMenuCreated = 'price_list_v1_frontend_product_menu_created',
    productMenuUpdated = 'price_list_v1_frontend_product_menu_updated',
    productMenusDeleted = 'price_list_v1_frontend_product_menu_deleted',
    menuProductCreated = 'price_list_v1_frontend_menu_product_created',
    menuProductUpdated = 'price_list_v1_frontend_menu_product_updated',
    menuProductsDeleted = 'price_list_v1_frontend_menu_product_deleted',
    classroomCreated = 'school_v1_frontend_classroom_created',
    classroomUpdated = 'school_v1_frontend_classroom_updated',
    classroomDeleted = 'school_v1_frontend_classroom_deleted',
    downloadStarted = 'core_v1_frontend_download_progress_started',
    downloadUpdated = 'core_v1_frontend_download_progress_updated',
    digitalSignageScreenshotCreated = 'core_v1_frontend_digital_signage_screenshot_created',
    burgersProductCreated = 'burgers_v1_frontend_burgers_product_created',
    burgersProductUpdated = 'burgers_v1_frontend_burgers_product_updated',
    burgersProductDeleted = 'burgers_v1_frontend_burgers_product_deleted',
    burgersCatalogUpdated = 'burgers_v1_frontend_burgers_catalog_updated',
    userActionsHistoryCreated = 'core_v1_frontend_user_actions_history_created',
    digitalSignageScreenshotDeleted = 'core_v1_frontend_digital_signage_screenshot_deleted',
    onPlaybackStateChange = 'core_v1_frontend_get_playback_state',
    samsoniteItemCreated = 'samsonite_frontend_v1_item_created',
    samsoniteItemUpdated = 'samsonite_frontend_v1_item_updated',
    samsoniteItemDeleted = 'samsonite_frontend_v1_item_deleted',
    samsoniteCatalogUpdated = 'samsonite_frontend_v1_catalog_updated',
    askonaItemCreated = 'askona_frontend_v1_item_created',
    askonaItemUpdated = 'askona_frontend_v1_item_updated',
    askonaItemDeleted = 'askona_frontend_v1_item_deleted',
    askonaCatalogUpdated = 'askona_frontend_v1_catalog_updated',
    pitamixCatalogUpdated = 'pitamix_frontend_v1_catalog_updated',
    pitamixCatalogCategoryCreated = 'pitamix_frontend_v1_catalog_category_created',
    pitamixCatalogCategoryUpdated = 'pitamix_frontend_v1_catalog_category_updated',
    pitamixCatalogCategoryDeleted = 'pitamix_frontend_v1_catalog_category_deleted',
    broadcastFolderCreated = 'core_v1_frontend_broadcast_folder_created',
    broadcastFolderUpdated = 'core_v1_frontend_broadcast_folder_updated',
    broadcastFolderDeleted = 'core_v1_frontend_broadcast_folder_deleted',
    dsUpdateTaskCreated = 'core_v1_frontend_ds_update_task_created',
    dsUpdateTaskUpdated = 'core_v1_frontend_ds_update_task_updated',
    dsUpdateTaskDeleted = 'core_v1_frontend_ds_update_task_deleted',
    videoEditorTaskDeleted = 'core_v1_frontend_video_editor_task_deleted',
    videoEditorTaskCreated = 'core_v1_frontend_video_editor_task_created',
    videoEditorTaskUpdated = 'core_v1_frontend_video_editor_task_updated',
    videoEditorTaskProgressUpdated = 'core_v1_frontend_video_editor_task_progress_updated',
    sourceTagCreated = 'core_v1_frontend_source_tag_created',
    sourceTagUpdated = 'core_v1_frontend_source_tag_updated',
    sourceTagDeleted = 'core_v1_frontend_source_tag_deleted',
    digitalSignageAndGroupMediaDeleted = 'core_v1_frontend_digital_signage_and_group_media_deleted',
    digitalSignageAndGroupMediaCreated = 'core_v1_frontend_digital_signage_and_group_media_created',
    fillersAdBlockCreated = 'advertisers_v1_frontend_fillers_ad_block_created',
    fillersAdBlockUpdated = 'advertisers_v1_frontend_fillers_ad_block_updated',
    fillersAdBlockDeleted = 'advertisers_v1_frontend_fillers_ad_block_deleted',
    scoreboardCreated = 'neftm_v1_frontend_scoreboard_created',
    scoreboardUpdated = 'neftm_v1_frontend_scoreboard_updated',
    scoreboardDeleted = 'neftm_v1_frontend_scoreboard_deleted',
    scoreboardMoved = 'neftm_v1_frontend_scoreboard_moved',
    onResetWebRTCConnection = 'core_v1_frontend_video_chat_connection_reset',
    onAcceptWebRTCConnection = 'core_v1_frontend_video_chat_connection_accepted',
    onVideoChatPayload = 'core_v1_frontend_video_chat_payload_received',
    onScoreboardFolderCreated = 'neftm_v1_frontend_scoreboard_folder_created',
    onScoreboardFolderUpdated = 'neftm_v1_frontend_scoreboard_folder_updated',
    onScoreboardFolderDeleted = 'neftm_v1_frontend_scoreboard_folder_deleted',
    onScoreboardFolderMoved = 'neftm_v1_frontend_scoreboard_folder_moved',
    onInstalledApplicationListSended = 'core_v1_frontend_installed_application_list_sent',
    getScoreboardInfo = 'neftm_v1_frontend_get_scoreboard_info',
    getNetworkConverterInfo = 'neftm_v1_frontend_get_scoreboard_s2e_info',
    saveNetworkConverterInfo = 'neftm_v1_frontend_save_scoreboard_s2e_settings',
    authorCreated = 'cataloguer_frontend_v1_author_created',
    authorUpdated = 'cataloguer_frontend_v1_author_updated',
    authorsDeleted = 'cataloguer_frontend_v1_authors_deleted',
    artCreated = 'cataloguer_frontend_v1_art_created',
    artUpdated = 'cataloguer_frontend_v1_art_updated',
    artsDeleted = 'cataloguer_frontend_v1_arts_deleted',
    historyPageCreated = 'chronology_v1_frontend_history_page_created',
    historyPageUpdated = 'chronology_v1_frontend_history_page_updated',
    historyPageDeleted = 'chronology_v1_frontend_history_page_deleted',
    lensDataCreated = 'tree_catalog_v1_frontend_catalog_created',
    lensDataUpdated = 'tree_catalog_v1_frontend_catalog_updated',
    lensDataDeleted = 'tree_catalog_v1_frontend_catalog_deleted',
    exhibitionCreated = 'exhibition_v1_frontend_exhibition_created',
    exhibitionUpdated = 'exhibition_v1_frontend_exhibition_updated',
    exhibitionDeleted = 'exhibition_v1_frontend_exhibition_deleted',
    exhibitionPageCreated = 'exhibition_v1_frontend_exhibition_page_created',
    exhibitionPageUpdated = 'exhibition_v1_frontend_exhibition_page_updated',
    exhibitionPageDeleted = 'exhibition_v1_frontend_exhibition_page_deleted',
    catalogizatorCsvUploadStateChanged = 'cataloguer_frontend_v1_arts_csv_parsing_state_changed',
    broadcastInfoSent = 'core_v1_frontend_broadcast_info_sent',
    requestBroadcastInfoWithFilesSent = 'core_v1_frontend_broadcast_info_with_files_sent',
    digitalSignageWokeUp = 'core_v1_frontend_digital_signage_woke_up',
    updateTaskInfoSent = 'core_v1_frontend_update_task_info_sent',
    triggerButtonUpdated = 'core_v1_frontend_trigger_button_updated',
    quizQuestionUpdated = 'quiz_v1_frontend_question_updated',
    quizQuestionCreated = 'quiz_v1_frontend_question_created',
    quizQuestionDeleted = 'quiz_v1_frontend_question_deleted',
    digitalSignageUpdated = 'core_v1_frontend_digital_signage_info_updated',
    sendBroadcastExportState = 'core_v1_frontend_send_broadcast_export_state',
    receiveOnlineMonitoringLogs = 'core_v1_frontend_send_online_monitoring_logs',
    onlineMonitoringStopped = 'core_v1_frontend_online_monitoring_stopped',
    receiveSyncScreenshots = 'core_v1_frontend_send_ds_sync_screenshots',
    sendDsSyncRoles = 'core_v1_frontend_send_ds_sync_roles',
    getSchoolClassesUploadStatusChanged = 'school_schedule_v1_frontend_upload_csv_status_changed',
    notificationsCountUpdated = 'core_v1_frontend_notification_count_updated',
    notificationsUpdated = 'core_v1_frontend_notification_updated',
    notificationsCreated = 'core_v1_frontend_notification_created',
    notificationsDeleted = 'core_v1_frontend_notification_deleted',
    webEngineInfoReceived = 'core_v1_frontend_web_engine_info_received',
    supportOnlineStatus = 'core_v1_frontend_support_online',
    companySettingsUpdated = 'core_v1_frontend_company_setting_updated',
    licenseCreated = 'core_v1_frontend_license_created',
    channelCreated = 'iptv_frontend_v1_channel_created',
    channelUpdated = 'iptv_frontend_v1_channel_updated',
    channelDeleted = 'iptv_frontend_v1_channel_deleted',
    categoryCreated = 'iptv_frontend_v1_category_created',
    categoryUpdated = 'iptv_frontend_v1_category_updated',
    categoryDeleted = 'iptv_frontend_v1_category_deleted',
    iptvUploadStatusChanged = 'iptv_v1_frontend_upload_csv_status_changed',
    iptvDownloadFileReady = 'iptv_v1_frontend_csv_ready',
    broadcastGridReceived = 'core_v1_frontend_ds_broadcast_grid',
    broadcastGridError = 'core_v1_frontend_ds_broadcast_grid_error',
    emailNotificationCreated = 'core_v1_frontend_email_notification_created',
    emailNotificationUpdated = 'core_v1_frontend_email_notification_updated',
    emailNotificationDeleted = 'core_v1_frontend_email_notification_deleted',
    webrtcHandshake = 'core_v1_frontend_webrtc_handshake',
    webrtcIceCandidate = 'core_v1_frontend_webrtc_ice_candidate',
    answerNewServerAccess = 'core_v1_frontend_answer_new_server_access',
}

export enum ConnectionEvents {
    startLoading = 'startLoading',
    endLoading = 'endLoading',
    emitError = 'emitError',
    connect = 'connect',
    onChangeStatus = 'onChangeStatus',
    onChangeLocation = 'onChangeLocation',
    updateDisplayProgress = 'updateDisplayProgress',
    updateBroadcastProgress = 'updateBroadcastProgress',
}

export type ApiPostEventsType = keyof typeof ApiPostEvents
export type ApiListenEventsType = keyof typeof ApiListenEvents
export type ApiEventsType = keyof typeof ApiEvents
export type ConnectionEventsType = keyof typeof ConnectionEvents
export type ListenEventsType = ApiListenEventsType | ConnectionEventsType

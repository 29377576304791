import React, { useState } from 'react'
import Dropdown from 'blocks.simple/dropdown/dropdown'
import Search from './__search/searchbar__search'
import SearchbarFilter from './__filter/searchbar__filter'
import SearchbarFilterList from './_filterList/searchbar_filterList'
import { ViewLocal } from '../../blocks.simple/ViewLocal/ViewLocal'
import { Filter } from './Filter/Filter-view'
import { cn } from 'ethcss'
import styles from './searchbar.jcss'
import { isEmpty } from 'lodash'
import { FilterList } from './NewFilterList/FilterList-view'
import { history } from 'features/routes'
import { isHorizontalMenu } from 'core/helpers/menu'
import { connect } from 'react-redux'

const Searchbar = (p_: {
    type?: string
    getMethod?: () => any
    isAllowAdvancedSearch?: boolean
    className?: string
    value?: string
    changeDisposition?: () => void
    onChangeFilterStatus?: (value: string) => void
    onChangeADSearch?: (params: { [index: string]: any }) => void
    getAdvancedSearch?: () => void
    advancedSearchState?: object
    useNewFilter?: boolean
    filterIsActive?: boolean
    availableFilters?: any
    setAvailableFilters?: (filters: { name: string }[]) => void
    selectedFilters?: any
    setSelectedFilters?: (filters: { name: string }[]) => void
    getDisposition?: () => string
    isMobile?: boolean
    viewLocal?: boolean
    mod: string
    small?: boolean
    searchable?: boolean
    filter?: any
    sort?: any
    dateFilter?: any
    selection?: any
    search?: any
    onSortChange?: (e: any) => void
    onDateFilterChange?: (selected: { id: number }) => void
    onSelectionChange?: (e: any) => void
    view?: any
    onViewChange?: (e: any) => void
    onFilterChange?: (dataFilter: { [index: string]: string }) => void
    onChange: (value: string) => void
    placeholder?: string
    id?: number
    onFocus?: (e: InputEvent) => void
    filterChildren?: any
    onBlur?: (value: string) => void
    searchElements?: any
    onClick?: (e: MouseEvent) => void
    onClickView?: () => void
    isMobilePage?: boolean
}) => {
    const [state, setState] = useState({
        searchIsOpened: false,
    })

    const onSortChange = (e: any) => {
        if (p_.onSortChange) {
            p_.onSortChange(e)
        }
    }
    const onSelectionChange = (e: any) => {
        if (p_.onSelectionChange) {
            p_.onSelectionChange(e)
        }
    }
    const onViewChange = (e: any) => {
        if (p_.onViewChange) {
            p_.onViewChange(e)
        }
    }
    const onFocus = (e: InputEvent) => {
        if (p_.onFocus) {
            p_.onFocus(e)
        }
    }
    const onBlur = (e: InputEvent) => {
        if (p_.onBlur) {
            p_.onBlur((e.target as HTMLInputElement).value)
        }
    }
    const onClick = (e: MouseEvent) => {
        if (p_.onClick) {
            // @ts-ignore
            p_.onClick(e.target.value)
        }
    }
    const onSubmit = (e: { preventDefault: () => void }) => {
        e.preventDefault()
    }

    const getValue = (disposition: string, options: { id: string; name: string }[]) => {
        if (!options || !disposition) return null

        for (let i = 0; i < options.length; i++) {
            if (options[i].id === disposition) {
                return disposition
            }
        }

        if (options.length) {
            onViewChange(options[0])

            return options[0].id
        }
    }

    const searchToggle = () => {
        setState({ searchIsOpened: !state.searchIsOpened })
    }

    const isFilterList = p_.filter && !isEmpty(p_.filter.selected)
    const isMap = p_.getDisposition ? p_.getDisposition() === 'map' : false

    return (
        <div
            className={cn(p_.className, styles.marginLeft, {
                [styles.paddingTop]: history.location.pathname === '/schedule' && isHorizontalMenu(),
            })}
        >
            <form className={styles.wrapper} onSubmit={onSubmit}>
                <div
                    className={
                        (history.location.pathname === '/displays' && !isMap) ||
                        history.location.pathname === '/reports' ||
                        history.location.pathname === '/adBlocks/edit' ||
                        history.location.pathname === '/adBlocks/add' ||
                        history.location.pathname === '/schedule' ||
                        history.location.pathname === '/schedule/add' ||
                        history.location.pathname === '/schedule/edit' ||
                        history.location.pathname === '/addContentToDevice' ||
                        history.location.pathname === '/advertisingAds/edit' ||
                        history.location.pathname === '/advertisingAds/add' ||
                        history.location.pathname === '/advertisingCampaigns/add' ||
                        history.location.pathname === '/advertisingCampaigns/edit' ||
                        history.location.pathname === '/broadcasts/addVideowall' ||
                        (history.location.pathname === '/displays/updateDevices' && p_.isMobilePage)
                            ? styles.extraGap
                            : styles.gap
                    }
                />
                <div
                    className={cn(
                        styles.searchbarContainer,
                        (history.location.pathname === '/displays' && !isMap) ||
                            history.location.pathname === '/adBlocks/edit' ||
                            history.location.pathname === '/adBlocks/add' ||
                            history.location.pathname === '/broadcasts/addVideowall' ||
                            history.location.pathname === '/schedule' ||
                            history.location.pathname === '/schedule/add' ||
                            history.location.pathname === '/schedule/edit' ||
                            history.location.pathname === '/addContentToDevice' ||
                            history.location.pathname === '/advertisingAds/edit' ||
                            history.location.pathname === '/advertisingAds/add' ||
                            history.location.pathname === '/advertisingCampaigns/add' ||
                            history.location.pathname === '/advertisingCampaigns/edit' ||
                            history.location.pathname === '/displays/updateDevices' ||
                            history.location.pathname === '/reports'
                            ? styles.extraWidth
                            : '',
                        state.searchIsOpened ? styles.showSearch : ''
                    )}
                >
                    {p_.searchable && (
                        <Search
                            {...{
                                ...p_,
                                searchToggle: searchToggle,
                                searchIsOpened: state.searchIsOpened,
                                enableMobileSearch: true,
                            }}
                        />
                    )}
                    {p_.filter && !isMap && (
                        <SearchbarFilter mod={p_.mod} filter={p_.filter} onFilterChange={p_.onFilterChange} />
                    )}
                    {p_.useNewFilter && !isMap && (
                        <Filter
                            onChangeFilterStatus={p_.onChangeFilterStatus}
                            availableFilters={p_.availableFilters}
                            setAvailableFilters={p_.setAvailableFilters}
                            selectedFilters={p_.selectedFilters}
                            setSelectedFilters={p_.setSelectedFilters}
                            type={p_.type}
                            isAllowAdvancedSearch={p_.isAllowAdvancedSearch}
                            searchElements={p_.searchElements}
                            onFilterChange={p_.onFilterChange}
                            onChangeADSearch={p_.onChangeADSearch}
                            getAdvancedSearch={p_.getAdvancedSearch}
                            advancedSearchState={p_.advancedSearchState}
                        />
                    )}
                </div>
                {p_.dateFilter && (
                    <div className={styles.sortFilter}>
                        <div className={styles.delimer} />
                        <Dropdown
                            mod={p_.mod}
                            options={p_.dateFilter.options}
                            placeholder={p_.dateFilter.placeholder}
                            value={p_.dateFilter.value}
                            icon={p_.dateFilter.icon}
                            iconColor={p_.dateFilter.iconColor}
                            iconActiveColor={p_.dateFilter.iconActiveColor}
                            iconSize={p_.dateFilter.iconSize}
                            rounded="none"
                            onChange={p_.onDateFilterChange ? p_.onDateFilterChange : () => {}}
                            disableChangeRounded
                            inheritIconColor
                            buttonClassName={styles.dropdownBtn}
                        />
                    </div>
                )}
                {p_.sort && (
                    <div className={styles.sortFilter}>
                        <Dropdown
                            mod={'withBorderVR'}
                            options={p_.sort.options}
                            placeholder={p_.sort.placeholder}
                            value={p_.sort.value}
                            icon={p_.sort.icon}
                            iconColor={p_.sort.iconColor}
                            iconActiveColor={p_.sort.iconActiveColor}
                            inheritIconColor={true}
                            iconSize={p_.sort.iconSize}
                            rounded={p_.selection || p_.view ? 'none' : 'right_mini'}
                            onChange={onSortChange}
                            disableChangeRounded={true}
                            buttonClassName={styles.dropdownBtn}
                        />
                    </div>
                )}
                {p_.selection && (
                    <div className={styles.selectionFilter}>
                        <Dropdown
                            mod={'withBorderV'}
                            options={p_.selection.options}
                            placeholder={p_.selection.placeholder}
                            value={p_.selection.value}
                            icon={p_.selection.icon}
                            iconColor={p_.selection.iconColor}
                            iconActiveColor={p_.selection.iconActiveColor}
                            inheritIconColor={true}
                            iconSize={p_.selection.iconSize}
                            rounded={p_.view ? 'none' : 'right_mini'}
                            onChange={onSelectionChange}
                            disableChangeRounded={true}
                            buttonClassName={styles.dropdownBtn}
                            textMobPadding={true}
                        />
                    </div>
                )}
                {p_.view && (
                    <div className={styles.viewFilter} onClick={p_.onClickView}>
                        <Dropdown
                            mod={p_.mod}
                            small={p_.small}
                            options={p_.view.options}
                            placeholder={p_.view.placeholder}
                            value={getValue(p_.view.value, p_.view.options)}
                            icon={p_.view.icon}
                            iconColor={p_.view.iconColor}
                            iconActiveColor={p_.view.iconActiveColor}
                            inheritIconColor={true}
                            iconSize={p_.view.iconSize}
                            rounded={'right_micro'}
                            onChange={onViewChange}
                            disableChangeRounded={true}
                            buttonClassName={styles.dropdownBtn}
                        />
                    </div>
                )}
                {p_.viewLocal && (
                    <ViewLocal
                        value={p_.getDisposition ? p_.getDisposition() : ''}
                        change={p_.changeDisposition ? p_.changeDisposition : () => {}}
                    />
                )}
            </form>
            {p_.useNewFilter && !isMap ? (
                p_.filterIsActive || p_.value ? (
                    <FilterList
                        selectedFilters={p_.selectedFilters}
                        setSelectedFilters={p_.setSelectedFilters}
                        availableFilters={p_.availableFilters}
                        setAvailableFilters={p_.setAvailableFilters}
                        type={p_.type}
                        onFilterChange={p_.onFilterChange}
                        getAdvancedSearch={p_.getAdvancedSearch}
                    />
                ) : null
            ) : null}
            {isFilterList && <SearchbarFilterList filter={p_.filter} onFilterChange={p_.onFilterChange} />}
        </div>
    )
}

Searchbar.defaultProps = {
    mod: 'withLightBorder',
    searchable: true,
    onDateFilterChange: () => {},
}

const mapStateToProps = (state: { app: { isMobile: boolean } }) => ({
    isMobilePage: state.app.isMobile,
})

export default connect(mapStateToProps, null)(Searchbar)

import { useEffect, useState } from 'react'
import { api } from 'core/api/ConnectionManager'
import merge from 'merge'
import helpersFiles from 'core/helpers/_files'
import translate from 'core/translate'
import { getURLSearchParamsByLocation } from 'features/routes/utils'

export interface ILinkEditorProps {
    location: {
        pathname: string
        search: string
        hash: string
        state: any
    }
    linkEditorRef: { current: any }
    small: boolean
}

const defaultStateDataSettings = {
    refreshInterval: 0,
    inactivityTimeout: 0,
    showBackButton: false,
    showLoadingProgress: false,
    refreshWhenNetStatusIsChanged: false,
    enableTouchScaling: false,
    loadTimeout: 0,
    scroll: 'on',
    allowModal: false,
    allowedDomains: [],
    url: '',
    volume: 0,
    // allowNewTab: false,
    // allowLinks: []
}

type Data = {
    type?: string
    refreshInterval: number
    inactivityTimeout: number
    showBackButton: boolean
    showLoadingProgress: boolean
    refreshWhenNetStatusIsChanged: boolean
    enableTouchScaling: boolean
    loadTimeout: number
    scroll: string
    allowModal: boolean
    allowedDomains: any
    url: string
    volume?: number
}

interface IState {
    data: Data
    name: string
    linkError: null | string
    isEdit: boolean
}

const LinkEditorMethods = (p_: ILinkEditorProps) => {
    const [state, setState] = useState<IState>({
        data: defaultStateDataSettings,
        name: '',
        linkError: null,
        isEdit: false,
    })

    useEffect(() => {
        if (!isEditLinkExist()) return

        getLink(getEditLinkId())
    }, [])

    useEffect(() => {
        if (isEditLinkExist() && !isEditEnable()) {
            getLink(getEditLinkId())
        }
    }, [p_])

    const getLink = (linkId: string) => {
        api.send('getFile', { id: linkId }, { hideLoader: true }).then((source: any) => {
            const data = merge.recursive(true, defaultStateDataSettings, source.data)

            setState((prevState) => {
                return {
                    ...prevState,
                    name: source.data.url,
                    data,
                    isEdit: true,
                }
            })
        })
    }

    const isEditEnable = () => {
        const { isEdit } = state

        return isEdit
    }

    const isEditLinkExist = () => {
        const { location } = p_

        const query = getURLSearchParamsByLocation(location)
        return !!query.linkId
    }

    const getEditLinkId = () => {
        const { location } = p_

        const query = getURLSearchParamsByLocation(location)
        return query.linkId
    }

    const onChangeLink = (name: string) => {
        setState((prevState) => {
            return {
                ...prevState,
                name,
                linkError: null,
            }
        })
    }

    const onChangeData = (value: any, key: string) => {
        const s_ = { ...state }
        const data = {
            ...s_.data,
            [key]: value,
        }

        setState((prevState) => {
            return {
                ...prevState,
                data,
            }
        })
    }

    const getData = () => {
        const s_ = { ...state }
        const validate: any = helpersFiles.validateLink(s_.name)
        const data: any = {
            ...s_.data,
            url: s_.name,
        }
        const query = getURLSearchParamsByLocation(p_.location)
        const timersKeys = ['refreshInterval', 'inactivityTimeout', 'loadTimeout']

        timersKeys.forEach((key) => {
            if (data.hasOwnProperty(key) && isNaN(data[key])) {
                data[key] = 0
            }
        })

        if (!validate.isValid) {
            setState((prevState) => {
                return {
                    ...prevState,
                    linkError: translate(validate.error),
                }
            })

            return {
                error: true,
            }
        }

        data.type = validate.type

        if (isEditLinkExist()) {
            const query = getURLSearchParamsByLocation(p_.location)

            return {
                id: query.linkId,
                data,
            }
        }

        const folderId = query.folderId

        return {
            type: 'link',
            data,
            folderId,
        }
    }

    p_.linkEditorRef.current = {
        getData,
    }

    return {
        onChangeData,
        onChangeLink,
        state,
    }
}

export default LinkEditorMethods
